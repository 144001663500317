import { Component, OnInit } from '@angular/core';
import { SendBrandedEmailModel } from '@core/models/mail-to.model';
import { NonprofitUserApi } from '@core/models/users.model';
import { MailToModalComponent } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory, YCModalComponent } from '@yourcause/common/modals';
import { MailToService } from '../../mailTo-modal/mailTo-modal.service';

@Component({
  selector: 'yc-active-admin-modal',
  templateUrl: './active-admin-modal.component.html',
  styleUrls: ['./active-admin-modal.component.scss']
})
export class ActiveAdminModalComponent extends YCModalComponent<string> implements OnInit {
  activeUsers: NonprofitUserApi[];
  modalLoaded = false;
  title: string;

  emailHistoryText = this.i18n.translate(
    'admin:textSeeEmailHistory',
    {},
    'See email history'
  );
  hideModal = false;

  constructor (
    private i18n: I18nService,
    private modalFactory: ModalFactory,
    private mailToService: MailToService
  ) {
    super();
  }

  ngOnInit () {
    this.title = this.i18n.translate(
      'search:hdrActiveAdministrators',
      {},
      'Active Administrators'
    );
    this.modalLoaded = true;
  }

  async openMailToModal (admin: NonprofitUserApi) {
    this.hideModal = true;
    const modalResponse = await this.modalFactory.open(
      MailToModalComponent,
      {}
    );
    this.hideModal = false;
    if (!!modalResponse) {
      const payload: SendBrandedEmailModel = {
        subject: modalResponse.subject,
        body: modalResponse.body,
        toUserId: admin.userInfo.id
      };
      await this.mailToService.sendBrandedEmail(
        payload
      );
    }
  }

  _onCancel () {
    this.closeModal.emit();
  }

  openNonprofitEmailLogReport (email: string) {
    this.closeModal.emit(email);
  }
}
