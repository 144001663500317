import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'nppShowIfYc'
})
export class ShowIfYc implements PipeTransform {
  constructor (
    private readonly authService: AuthService
  ) {}
  get isYcAdminOrSupport () {
    return this.authService.isYcAdminOrSupport();
  }


  transform (value: any): any {
    if (this.isYcAdminOrSupport) {
      return value;
    } else {
      return 'YourCause';
    }
  }
}
