import { Component, Input, OnInit } from '@angular/core';
import { CodeType, NonprofitIRSCodeInfo, NonprofitIRSCodes } from '@core/models/nonprofit-irs-code.model';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';


@Component({
  selector: 'yc-nonprofit-irs-codes-modal',
  templateUrl: './nonprofit-irs-codes-modal.component.html',
  styleUrls: ['./nonprofit-irs-codes-modal.component.scss']
})

export class NonprofitIRSCodesModalComponent extends YCModalComponent<void> implements OnInit {
  @Input() codes: NonprofitIRSCodes;
  @Input() codeType: CodeType;

  modalLoaded = false;
  rows: NonprofitIRSCodeInfo[] = [];
  modalHeader: string;
  CodeType = CodeType;

  constructor (
    private i18n: I18nService
  ) {
    super();
  }

  ngOnInit () {
    this.rows = this.codes.nonprofitCodes;
    switch (this.codeType) {
      case CodeType.BMFFoundation:
        this.modalHeader = this.i18n.translate(
          'nonprofits:hdrBMFFoundationCodes',
          {},
          'BMF Foundation Codes'
        );
        break;
      case CodeType.BMFSubsection:
        this.modalHeader = this.i18n.translate(
          'nonprofits:hdrBMFSubsectionCodes',
          {},
          'BMF Subsection Codes'
        );
        break;
      case CodeType.Pub78Designation:
        this.modalHeader = this.i18n.translate(
          'nonprofits:hdrPub78DesignationCodes',
          {},
          'Pub78 Designation Codes'
        );
        break;
    }
    this.modalLoaded = true;
  }

  _onCancel () {
    this.closeModal.emit();
  }
}
