import { Injectable } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { CommunicationResources } from './communication.resources';
import { CommunicationState } from './communication.state';


@AttachYCState(CommunicationState)
@Injectable({
  providedIn: 'root'
})
export class CommunicationService extends BaseYCService<CommunicationState> {

  constructor (
    private notifier: NotifierService,
    private communicationResources: CommunicationResources,
    private i18n: I18nService
  ) {
    super();
  }

  get currentUnsubscribedEmailClassIds () {
    return this.get('currentUnsubscribedEmailClassIds');
  }

  get email () {
    return this.get('email');
  }

  setCurrentUnsubscribedEmailClassIds (unsubscribedEmailClassIds: number[]) {
    this.set('currentUnsubscribedEmailClassIds', unsubscribedEmailClassIds);
  }

  setEmail (email: string) {
    this.set('email', email);
  }

  async getCommunicationPreferences (force = false) {
    if (force || !this.currentUnsubscribedEmailClassIds || !this.email) {
      const response = await this.communicationResources.getCommunicationPreferences();
      this.setCurrentUnsubscribedEmailClassIds(response?.unsubscribedEmailClassIds);
      this.setEmail(response?.email);
    }
  }

  async getUserCommunicationPrefsAsYcAdmin (
    userEmailAddress: string
  ) {
    const response = await this.communicationResources
      .getCommunicationPreferences(userEmailAddress);
    this.setCurrentUnsubscribedEmailClassIds(response?.unsubscribedEmailClassIds);
    this.setEmail(userEmailAddress);
  }

  async updateCommunicationPreferences (
    emailIdArray: number[],
    userEmailAddress?: string
  ) {
    userEmailAddress = userEmailAddress || null;
    const payload = {
      unsubscribedEmailClassIds: emailIdArray,
      userEmailAddress
    };
    try {
      await this.communicationResources.updateCommunicationPreferences(
        payload
      );
      if (!!userEmailAddress) {
        this.notifier.success(this.i18n.translate(
          'account:notificationSuccessUpdatingUserCommPrefs',
          {},
          'Successfully updated user communication preferences'
        ));
      } else {
        this.notifier.success(this.i18n.translate(
          'account:notificationSuccessUpdatingCommPrefs',
          {},
          'Successfully updated your communication preferences'
        ));
      }
      if (!userEmailAddress) {
        this.setCurrentUnsubscribedEmailClassIds(emailIdArray);
      }
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'account:notificationErrorUpdatingCommPrefs',
        {},
        'There was an error updating your communication preferences'
      ));
    }
  }
}
