
export const STATUS_MAP = {
  'Not Reviewed': 1,
  Verified: 2,
  Rejected: 3,
  Expired: 4,
  'Approved by Admin': 5,
  'Verified by Company': 6,
  'Rejected by Company': 7
};

export const DONATION_STATUS_REASON_MAP = {
  'Donation cannot be verified': 1,
  'Duplicate Request': 2,
  'Donation amount not tax-deductible': 3,
  'Donor received goods and/or services': 4,
  Other: 5
};

export const VOLUNTEER_STATUS_REASON_MAP = {
  'Hours cannot be verified': 6,
  'Duplicate hours request': 7,
  Other: 8
};

const BASE_REASONS = {
  'Insufficient documentation': 1,
  'Incorrect documentation': 2,
  Other: 4
};

export const ADMIN_REQUEST_STATUS_REASON_MAP = Object.assign({
  'Selected wrong organization': 3,
  Other: 4
}, BASE_REASONS);

export const ADDRESS_REQUEST_STATUS_REASON_MAP = Object.assign({
  'Insufficient documentation': 1,
  'Incorrect documentation': 2,
  Other: 3
});

export const ADMIN_REQUEST_STATUS_REASON_MAP_BY_ID = {
  1: 'Insufficient documentation',
  2: 'Incorrect documentation',
  3: 'Selected wrong organization',
  4: 'Other'
};

export interface VerificationStatus {
  id: number;
  name: string;
}

export interface VerificationStatusReasonInfo {
  id: number;
  name: string;
  description: string;
}

export const BANK_INFO_STATUS_MAP = {
  1: 'Pending',
  2: 'Approved',
  3: 'Declined'
};

export interface Statuses {
  id?: number;
  status: string;
  statusTypeId?: number;
  isActive?: boolean;
  systemStatus?: boolean;
  statusType?: StatusTypes;
}

export interface StatusTypes {
  id: number;
  statusType: string;
  statusTypeDescription: string;
}


//// NEW STATUS MODELS
export interface StatusModel {
  id: number;
  status: string;
  statusTypeId: number;
  isActive: boolean;
  i18nKey: string;
  systemStatus?: boolean;
}

export enum StatusType {
  BankInfoChangeRequest = 1,
  AdHocEmail = 2,
  EligibleForGiving = 3,
  SanctionsScreeningFlag = 4,
  ChapterRequest = 5,
  AdminRequest = 6,
  CheckReissue = 7,
  Job = 8,
  RemittanceAddressRequest = 9,
  NonprofitStory = 10,
  NonprofitVettingRequest = 11,
  NonprofitVettingApplication = 12,
  ProspectiveAdminOutreach = 13,
  NonprofitProgram = 14
}

export interface StatusObjectUI {
  id: number;
  typeId: number;
  value: string;
  i18nKey: string;
}

// list of statuses not used in table filtering
// VR excluded statuses are not included here
export enum StatusSelectExclusionList {
  AdminRequest_Draft = 22,
  ScreeningFlag_Cleared = 16,
  ScreeningFlag_Sanctioned = 17,
  ChapterRequest_Pending = 18,
  ChapterRequest_Cancelled = 21,
  CheckReissue_OnHold = 28,
  CheckReissue_Pending = 29,
  Status_Needs_Vetting = 12
}

// all VR statuses
export enum VettingRequestStatusId {
  // SUBMITTED BUT NO APPLICATION
  REQUEST_SUBMITTED = 41,

  // HAS APPLICATION IN ANY 'OUTSTANDING' STATUS
  IN_PROGRESS = 62,

  // HAS APPLICATION IN CORRESPONDING 'COMPLETED' STATUS
  CANCELLED = 61,
  APPROVED_ED = 44,
  DECLINED_ED = 46,

  // PARTNER VETTING
  ROUTED_TO_VETTING_AUTHORITY = 63,
  ON_HOLD_PENDING_BILLING = 64,

  ///// NOT IN USE
  INVITATION_SENT = 42,
  PENDING = 43,
  APPROVED_STANDARD = 45,
  DECLINED_STANDARD = 47,
  DRAFT = 48,
  INFORMATION_REQUESTED = 49,
  ON_HOLD = 50
}

// all Vetting Application statuses
export enum VettingApplicationStatus {
  // OUTSTANDING
  DRAFT = 51,
  AWAITING_REVIEW = 52,
  IN_PROGRESS = 53,
  ON_HOLD = 54,
  REVISION_SUBMITTED = 60,

  // COMPLETED
  APPROVED = 55,
  DECLINED = 56,
  CANCELLED = 57,

  // NOT IN USE
  INFORMATION_REQUESTED = 58,
  PENDING = 59
}

export enum ActiveApplicationStatusSelectExclusionList {
  APPROVED = 55,
  DECLINED = 56,
  CANCELLED = 57,
  PENDING = 59,
  INFORMATION_REQUESTED = 58
}

export enum CompletedApplicationStatusSelectExclusionList {
  DRAFT = 51,
  AWAITING_REVIEW = 52,
  IN_PROGRESS = 53,
  ON_HOLD = 54,
  INFORMATION_REQUESTED = 58,
  PENDING = 59,
  REVISION_SUBMITTED = 60
}

export enum OldActiveVettingStatusSelectExclusionList {
  REQUEST_SUBMITTED = 41,
  APPROVED_ED = 44,
  APPROVED_STANDARD = 45,
  DECLINED_ED = 46,
  DECLINED_STANDARD = 47,
  DRAFT = 48,
  INFORMATION_REQUESTED = 49,
  ON_HOLD = 50,
  CANCELLED = 61,
  IN_PROGRESS = 62
}

export enum InactiveVettingStatusSelectExclusionList {
  PENDING = 43,
  REQUEST_SUBMITTED = 41,
  INVITATION_SENT = 42,
  DRAFT = 48,
  INFORMATION_REQUESTED = 49,
  ON_HOLD = 50,
  ROUTED_TO_VETTING_AUTHORITY = 63,
  ON_HOLD_PENDING_BILLING = 64
}

export enum OldInactiveVettingRequestStatusSelectExclusionList {
  REQUEST_SUBMITTED = 41,
  INVITATION_SENT = 42,
  PENDING = 43,
  DRAFT = 48,
  INFORMATION_REQUESTED = 49,
  ON_HOLD = 50,
  CANCELLED = 61,
  IN_PROGRESS = 62
}

export enum PartnerVettingStatuses {
  IN_PROGRESS = 62,
  ROUTED_TO_VETTING_AUTHORITY = 63,
  ON_HOLD_PENDING_BILLING = 64
}

export enum AddressChangeRequestStatus {
  PENDING = 35,
  APPROVED = 36,
  REJECTED = 37,
  REQUEST_MADE_WITH_CHECK_REISSUE = 38
}

export enum CheckReissueStatus {
  Approved = 26,
  AwaitingAddressApproval = 27,
  OnHold = 28,
  Pending = 29,
  Rejected = 30
}

export enum AdminRequestStatusId {
  DRAFT = 22,
  PENDING = 23,
  APPROVED = 24,
  DECLINED = 25
}

export enum EligibilityStatus {
  AwaitingReview = 10, // repurposed from 'Unknown' status
  YesEligible = 11,
  MaybeEligible = 12,
  NeverEligible = 13
}

// values taken directly from backend status table
export const EFG_Status_Map_By_Id = {
  10: "Vetting In Progress",
  11: "Eligible",
  12: "Info Required",
  13: "Ineligible"
};

export enum NewChapterRequestStatusId {
  PENDING = 18,
  APPROVED = 19,
  DECLINED = 20
}

export enum NpoStoryStatus {
  Published = 'Published',
  Draft = 'Draft'
}

export enum FlaggedNPOStatus {
  ON_HOLD = 15,
  PENDING = 14,
  CLEARED = 16,
  SANCTIONED = 17
}

export enum FlaggedNPOStatusText {
  ON_HOLD = 'On Hold',
  PENDING = 'Pending',
  CLEARED = 'Cleared',
  SANCTIONED = 'Sanctioned'
}

export const ADMIN_STATUS_MAP_BY_ID = {
  22: 'Draft',
  23: 'Pending',
  24: 'Approved',
  25: 'Declined'
};

export enum ProspectiveAdminStatusId {
  OUTSTANDING = 65,
  REMOVED  = 66,
  CONVERTED = 67,
  OPTED_OUT = 68,
  HARD_BOUNCE = 69,
  NO_RESPONSE = 70
}

export enum ApproveRejectModalStatus {
  APPROVE,
  REJECT
}

export enum GenericizeStatusSpecialCase {
  VERIFIED_DONATION = 'verifiedDonation',
  VERIFIED_HOURS = 'verifiedHours',
  ALL_TRANSACTIONS = 'allTransactions',
  CHARITY_COMPARE = 'charityCompare'
}
