<div class="container-fluid p-0">
  <div *ngIf="!loading"
    class="form"
    role="form"
    [formGroup]="addressFormGroup">
    <fieldset>
      <div class="row">
        <div *ngIf="showName" class="col-md-12">
          <yc-input
            name="name"
            [label]="nameLabel">
          </yc-input>
          <div class="small-text" *ngIf="nameInfoText">
            {{ nameInfoText }}
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <!-- ADDRESS 1 -->
        <div class="col-md-6">
          <yc-input
            name="address1"
            [label]="'accountSetup:lblAddressLineOne' | ycI18n: 'Address line 1'">
          </yc-input>
        </div>
        <!-- ADDRESS 2 -->
        <div class="col-md-6">
          <yc-input
            name="address2"
            [label]="'accountSetup:lblAddressLineTwo' | ycI18n: 'Address line 2 - Optional'">
          </yc-input>
        </div>
      </div>
      <div class="row mt-2">
        <!-- COUNTRY  -->
        <div class="col-md-6">
          <yc-typeahead-select
            name="countryCode"
            [label]="'accountSetup:lblCountry' | ycI18n: 'Country'"
            [items]="countrySelects"
            [showClear]="false"
            (onChange)="setRegionsOnCountryChange($event)">
          </yc-typeahead-select>
        </div>
        <!-- REGION  -->
        <div *ngIf="regionSelects?.length > 0"
          class="col-md-6">
          <yc-typeahead-select
            name="stateProvRegId"
            [label]="'accountSetup:lblState' | ycI18n: 'State, province or region'"
            [items]="regionSelects"
            [showClear]="false">
          </yc-typeahead-select>
        </div>
      </div>
      <div class="row">
        <!-- CITY  -->
        <div class="col-md-6">
          <yc-input
            name="city"
            [label]="'accountSetup:lblCity' | ycI18n: 'City'">
          </yc-input>
        </div>
        <!-- POSTAL CODE  -->
        <div class="col-md-6">
          <yc-input
            name="postalCode"
            [label]="'accountSetup:lblPostalCode' | ycI18n: 'Postal code'">
          </yc-input>
        </div>
      </div>
    </fieldset>
  </div>
</div>