import { Address, Addresses, AddressPayload, ClassificationInfo, NonprofitSDG, RegistrationAuthority, RemittanceInfoModel, SearchFilter, SearchResult, SearchResultObj, StandardAddressNppModel } from '@yourcause/common';
import { ChapterRequestSummaryData, NewChapterRequestDisplayModel } from './chapter-request.model';
import { NonprofitAdminRequestDisplayModel } from './claim.model';
import { NonprofitStoryDisplayModel } from './nonprofit-stories.model';
import { EligibilityStatus, Statuses } from './status.model';
import { User } from './user.model';

export interface BasicNonprofit {
  id: number;
  registrationAuthorityId: number;
  registrationId: string;
  name: string;
  address: Address;
  nonprofitIconImageUrl: string;
  createDate: string;
  parentNonprofitId: number;
  parentName?: string;
  parentRegistrationId?: string;
  isChapterAffiliate: boolean;
  classificationInfo: ClassificationInfo;
  displayAddress: Address;
  isActive?: boolean;
  deactivationReasonId?: number;
  disbursementSuspended?: boolean;
  remittanceInfo?: RemittanceInfoModel;
  eligibleForGivingStatusId?: number;
  isParent?: boolean;
  hasAchInfo?: boolean;
  createdByUser?: User;
  processesOwnFunds?: boolean;
  subsectionCode?: number;
}

export interface NonprofitAPI {
  id?: number;
  ycCharityId?: number;
  registrationId?: string;
  name?: string;
  nonprofitTypeId?: number;
  isManualAdd?: boolean;
  isChapterAffiliate?: boolean;
  isPublished?: boolean;
  NTEECode?: string;
  nonprofitIconImageUrl?: string;
  nonprofitLogoImageUrl?: string;
  nonprofitHeaderImageUrl?: string;
  interfaceificationId?: number;
  interfaceification?: string;
  organizationType?: string;
  subsection?: string;
  rulingDate?: string;
  isOFACFlagged?: boolean;
  foundationType?: string;
  displayName?: string;
  displayPitch?: string;
  displayNumber?: string;
  displayEmail?: string;
  displayUrl?: string;
  tags?: string[];
  organizationHead?: string;
  organizationHeadEmail?: string;
  processesOwnFunds?: boolean;
  SDGClassifications?: string[];
  isClaimed?: boolean;
  supportsSDGGive?: boolean;
  supportsSDGVolunteer?: boolean;
  classificationInfo: ClassificationInfo;
  address?: Address;
  displayAddress?: AddressPayload;
  irsAddress?: Address;
  sdGs?: NonprofitSDG[];
  remittanceInfo?: RemittanceInfoModel;
  registrationAuthorityId?: number;
  registrationAuthority?: RegistrationAuthority;
  subsectionCode?: string;
  parentNonprofit?: ParentNonprofit;
  parentRegistrationId?: string;
  disbursementInfoLastModifiedDate?: string;
  notesCount?: number;
  disbursementSuspended?: boolean;
  nonprofitStoryModel?: NonprofitStoryDisplayModel[];
  eligibleForGivingStatusId?: number;
  vettingExpiringSoon?: boolean;
  isActive: boolean;
  ofacCheck?: string; // date
}

export interface ParentNonprofit {
  id: number;
  registrationId: string;
  name: string;
  registrationAuthorityId: number;
  address: Address;
  isManualAdd: boolean;
  isChapterAffiliate: boolean;
  isPublished: boolean;
  classificationInfo: ClassificationInfo;
  foundationType: string;
  displayName: string;
  displayPitch: string;
  displayNumber: string;
  displayEmail: string;
  displayAddress: Address;
  irsAddress: Address;
  remittanceInfo: RemittanceInfoModel;
  displayUrl: string;
  tags: string;
  nonprofitIconImageUrl: string;
  nonprofitLogoImageUrl: string;
  nonprofitHeaderImageUrl: string;
  registrationAuthority: RegistrationAuthority;
  isProcessingOwnFunds: boolean;
  isClaimed: boolean;
  supportsSDGGive: boolean;
  supportsSDGVolunteer: boolean;
  sdGs: NonprofitSDG[];
  ofacCheck: string;
  subsectionCode: string;
  ycCharityID?: number;
  nonprofitGuid?: string;
  subsectionCodeDesc?: string;
  foundationCodeDesc?: string;
  donateWellTaxDeductibleStatusCode?: string;
  donateWellTaxDeductibleStatusChangedDate?: string;
  isActive: boolean;
  disbursementSuspended?: boolean;
}

export interface Nonprofit extends NonprofitAPI {
  addresses?: Addresses;
}

export interface DisplayPayload {
  name?: string;
  url?: string;
  pitch?: string;
  email?: string;
  number?: string;
  address?: AddressPayload;
  tags?: (string|{value: string})[];
  id?: number;
  adaptedTagsForForm?: string[];
}

export interface RemittancePayload {
  name?: string;
  address?: AddressPayload;
  requestId?: number;
}

export interface LogoPayload {
  image: Blob;
  type: string;
}

export interface NonprofitLogoAPIResponse {
  data: {
    id: number;
    resourceId: string;
    version: string;
    format: string;
    url: string;
    imageType: string;
  };
}

export interface CreateNonprofitApi {
  nonprofitId?: number;
  registrationAuthorityId?: number;
  registrationId: string;
  name: string;
  address: AddressPayload;
  classificationId: number;
  isChapterAffiliate: boolean;
  isManualAdd: boolean;
  isPublished: boolean;
  isRegistered: boolean;
  isProcessingOwnFunds: boolean;
  subsectionCode: string;
  category?: number;
  group?: number;
}

export interface ManuallyAddedNonprofit {
  affiliations: number[];
  classificationInfo: ClassificationInfo;
  id: number;
  registrationAuthorityId: string;
  registrationId: string;
  name: string;
  address: Address;
  displayAddress: Address;
  nonprofitIconImageUrl: string;
  nonprofitLogoImageUrl: string;
  subsectionCode: string;
  createDate: string;
  createdByUser?: User;
  hasAchInfo?: boolean;
  remittanceInfo?: RemittanceInfoModel;
  processesOwnFunds?: boolean;
  isActive?: boolean;
}

export interface ChapterNonprofit {
  classificationInfo: ClassificationInfo;
  id: number;
  registrationAuthority: RegistrationAuthority;
  registrationId: string;
  name: string;
  address: Address;
  displayAddress: Address;
  nonprofitIconImageUrl: string;
  nonprofitLogoImageUrl: string;
  subsectionCode: string;
  createDate: string;
}

export interface BasicMyNonprofit {
  id: number;
  registrationAuthorityId: number;
  registrationId: string;
  name: string;
  address: Address;
  nonprofitIconImageUrl: string;
  createDate: string;
  parentNonprofitId?: number;
  parentName?: string;
  parentRegistrationId?: string;
  isChapterAffiliate?: boolean;
  classificationInfo?: ClassificationInfo;
  displayAddress?: Address;
  isActive?: boolean;
  isParent?: boolean;
  chapterRequests?: NewChapterRequestDisplayModel[];
  deactivationReasonId?: number;
  disbursementSuspended?: boolean;
  remittanceInfo?: RemittanceInfoModel;
  eligibleForGivingStatusId?: number;
  status?: Statuses;
  isChapterRequest?: boolean;
  chapterRequestSummaryData?: ChapterRequestSummaryData;
  chapterRequestId?: number;
}

export class MyNonprofitRow {
  constructor (
    public id: number,
    public registrationId: string,
    public name: string,
    public address: Address,
    public nonprofitIconImage: string,
    public createDate: string,
    public claimRequest: NonprofitAdminRequestDisplayModel,
    public chaptersInvisible: boolean,
    public chapters: BasicMyNonprofit[],
    public parentId: number,
    public parentName: string,
    public parentRegistrationId: string,
    public isParent?: boolean,
    public isChapterAffiliate?: boolean,
    public isActive?: boolean,
    public deactivationReasonId?: number,
    public eligibleForGivingStatusId?: number,
    public chapterCount?: number,
    public chapterRequestCount?: number,
    public status?: string
  ) {}
}

export interface ChapterVisibilityMap {
  [rowId: number]: {
    chaptersVisible: boolean;
  };
}

export interface NonprofitSetIconResponse {
  id: number;
  resourceId: string;
  version: string;
  format: string;
  url: string;
  imageType: string;
}

export interface InviteAdminEmailPayload {
  emailInviting: string;
  nonprofitId: number;
}

export interface UpdateNPOClassificationPayload {
  classificationOneId: number;
  classificationTwoId: number;
  classificationThreeId?: number;
}

export interface SearchMoreDetails {
  eDvetted?: string[];
  deactivationComment?: string;
  deactivationReason?: string;
  notesCount: number;
  hasPub78: boolean;
  hasBMF: boolean;
  gS_vetted_non_BMF: boolean;
  deductibilityCode: string;
  deductibilityCodeDesc: string;
  exemptStatusCode: string;
  pubExemptStatusCode: string;
  pubExemptStatusCodeDesc: string;
  subSectionCode: string;
  foundationCode: string;
  foundationCodeDesc: string;
  activeUsers: {
    roleInfo: {
      roleName: string;
      roleId: number;
    };
    userInfo: {
      id: number;
      lastName: string;
      firstName: string;
      fullName: string;
      email: string;
      lastLoginDate: string;
    };
    lastLoginDate: string;
    jobTitle: string;
  }[];
  disbursementSuspended: boolean;
  disbursementSuspendedReasonId: number;
  disbursementSuspendedReasonComment: string;
  nonprofitClassification: string;
  activeVetting: string;
  activeCompliances: number;
  vettingExpirationDate?: string;
  revocationDate: string;
  isChapterAffiliate: boolean;
  hasAchInfo: boolean;
  remittanceInfo: RemittanceInfoModel;
  processingPartner: string;
  registrationAuthorityId: number;
  registrationAuthorityAttributes: RegistrationAuthorityAttribute[];
}

export interface RegistrationAuthorityAttribute {
  i18nKey: string;
  i18nDefaultValue: string;
  value: string;
}

// Basic Data needed for yc-nonprofit-block-table
export interface NonprofitBlockDisplayModel {
  registrationAuthorityId: number;
  nonprofitIconImageUrl: string;
  id: number;
  registrationAuthorityName: string;
  registrationId: string;
  name: string;
  ycCharityId: number;
  address: StandardAddressNppModel;
  eligibleForGivingStatusId: EligibilityStatus;
  isActive: boolean;
}

export interface NonprofitPublicProfileModel {
  address: StandardAddressNppModel;
  displayEmail?: string;
  displayName?: string;
  displayNumber?: string;
  displayPitch?: string;
  displayUrl?: string;
  eligibleForGivingStatusId: number;
  hasAdmin: boolean;
  id?: number;
  name: string;
  nonprofitIconImageUrl?: string;
  nonprofitStoryModel: NonprofitStoryDisplayModel[];
  ntee: string;
  registrationAuthorityId: number;
  registrationAuthorityName: string;
  registrationId: string;
  sdGs: NonprofitSDG[];
  subsectionCode: string;
  supportsSDGGive: boolean;
  supportsSDGVolunteer: boolean;
  tags: string[];
  justGivingDonateNowUrl: string;
}

export interface FacetModel {
  type: string;
  value: string;
  count: number;
}

export interface NonprofitSearchFacet {
  country: FacetModel[];
  registrationAuthority: FacetModel[];
  stateProvRegName: FacetModel[];
}

export interface NonprofitSearchResponseModelUI {
  data: {
    results: {
      document: SearchResultObj;
    }[];
    facets: NonprofitSearchFacet;
  };
}

export interface NonprofitSearchResponseModelAPI {
    results: SearchResult[];
    facets?: NonprofitSearchFacet;
}

export interface NonprofitSearchRecordsResponse {
  recordCount: number;
  records: SearchResultObj[];
}

export interface StandardGetSearchPayload {
  searchText?: string;
  pageNumber?: number;
  rowsPerPage?: number;
  searchFilters?: SearchFilter[];
  showAll?: boolean;
}

export interface NonprofitByCheckInfo {
  nonprofitId: number;
  nonprofitName: string;
  address: Address;
  registrationId: string;
}

export interface NpoForAdminSelectModel extends NonprofitByCheckInfo {
  nonprofitIconImageUrl: string;
}

export interface NonprofitRegAuthAttributes {
  changeDate: string;
  onPub78: boolean;
  pub78Designation: string;
  onBMF: boolean;
  foundationCode: string;
  subsectionCode: string;
  deductibilityCode: string;
  irsRevocationDate: string;
  id: number;
  eligibleForGiving: number;
  eligibleForGivingReason: string;
}

export interface NonprofitStatsSummaryModel {
  changeDate: string;
  changedBy: string;
  reason: string;
  comment: string;
  eligibleForGiving: number;
  eligibleForGivingReason: string;
}

export interface NonprofitNameAddressModel {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  stateProvRegCode: string;
  postalCode: string;
  countryCode: string;
}

export interface GetNonprofitAdditionalInformationResponseModel {
  hasACH: boolean;
  eligibleForGiving: number;
  registrationAuthorityName: string;
  registrationAuthorityId: number;
  registeredNameAndAddress: NonprofitNameAddressModel;
  disbursementNameAndAddress: NonprofitNameAddressModel;
  displayNameAndAddress: NonprofitNameAddressModel;
  isActive: boolean;
  disbursementSuspended: boolean;
  createdDate: string;
}

export interface NonprofitAdditionalInformationRow {
  attributeName: string;
  value: any;
}

export interface CreateNonprofitApiResponse {
  name: string;
  address: AddressPayload;
  registrationId: string;
  alternateRegistrationId: string;
  website: string;
  nonprofitId: number;
  nonprofitGuid: string;
}

export enum NonprofitAdditionalInformationAttributesText {
  hasACH = 'ACH',
  registrationAuthorityName = 'Registration authority',
  registeredNameAndAddress = 'Registered name and address',
  disbursementNameAndAddress = 'Disbursement name and address',
  displayNameAndAddress = 'Display name and address',
  eligibleForGiving = 'Eligible for giving',
  isActive = 'Is active',
  eligibleForDisbursement = 'Eligible for disbursement',
  createdDate = 'Date added to NPOconnect'
}

export enum RegAuthAttrDefaultValue {
  On_IRS_BMF = 'On IRS BMF',
  BMF_Deductibility_code = 'BMF Deductibility code',
  DeductibilityCodeDesc = 'DeductibilityCodeDesc',
  BMF_Subsection_code = 'BMF Subsection code',
  BMF_Foundation_code = 'BMF Foundation code',
  FoundationCodeDesc = 'FoundationCodeDesc',
  On_IRS_Pub_78 = 'On IRS Pub 78',
  Pub78_Designation_code = 'Pub78 Designation code',
  ExemptStatusCode = 'ExemptStatusCode',
  PubExemptStatusCodeDesc = 'PubExemptStatusCodeDesc',
  IRS_Revocation_Date = 'IRS Revocation Date',
  Reference_Code = 'CT Reference Code',
  FP_ID = 'FP ID',
  Active_FP = 'Active on FP',
  TS_ID = 'TS ID',
  Active_TS = 'Active on TS',
  US_State_Revocations = 'US State Revocations'
}

// FOR EXTENDED SEARCH DETAILS
// AS WE ADD OTHER REG AUTHS - INCLUDE THEIR IDS HERE
export const RegAuthIdsExcluded = [
  82, // Haus des Stiftens
  5 // UK Nonprofit
];
