
import { Injectable } from '@angular/core';
import { DonationsByCompany, DonationsByFundingSource, DonationsBySourcePayload } from '@core/models/dashboard.model';
import { HttpRestService } from '@core/services/http-rest.service';

@Injectable({ providedIn: 'root' })
export class DashboardResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getDonationsByCompany (
    payload: DonationsBySourcePayload
  ) {
    const response = await this.httpRestService.post<{ data: DonationsByCompany }>(
      '/api/NonprofitDashboard/DonationsBySource',
      payload
    );

    return response.data;
  }

  async getDonationsByFundingSource (
    payload: DonationsBySourcePayload
  ) {
    const response = await this.httpRestService.post<{ data: DonationsByFundingSource }>(
      'api/NonprofitDashboard/DonationsByFundingSource',
      payload
    );

    return response.data;
  }

}
