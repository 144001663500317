export interface BlackbaudSsoRouteResponse {
  signInUrl: string;
  signOutUrl: string;
}

export enum BlackbaudSsoError {
  Unknown = 1,
  NoPlatformAccount = 2
}

export interface TokenResponse {
  success: boolean;
  message: string;
  errorCode: string;
  data: {
    token: string;
    expiration: string;
    refreshToken: string;
    refreshTokenExpiration: string;
  };
}

export interface SSOToken {
  id_token: string;
  access_token: string;
  scope: string;
  session_state: string;
  state: string;
  code?: string;

  swapped_token: string;

  [x: string]: string;
}

