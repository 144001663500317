import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment';
import '@yourcause/common';
import { AppSharedModule } from './app/app.shared.module';


if (environment.production) {
  enableProdMode();
}

if (environment.addGoogleTagManagerScript) {
  addGoogleTagManager();
}

if (environment.addMetaFacebookPixel) {
  addMetaFacebookPixel();
}

function addGoogleTagManager () {
  const script = document.createElement('script');

  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-WQZTBFL9');`;

  document.head.appendChild(script);
}

function addMetaFacebookPixel () {
  const meta = document.createElement('meta');

  meta.name = 'facebook-domain-verification';

  meta.content = '9r4lv1wuaym7j7ukjc9a6m8t6iy1p5';

  document.head.appendChild(meta);

  const script = document.createElement('script');

  script.innerHTML = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '5373970592640304');
  fbq('track', 'PageView');`;

  document.head.appendChild(script);
}

export function main () {
  return platformBrowserDynamic().bootstrapModule(AppSharedModule);
}

main();
