import { StatusModel, StatusObjectUI } from '@core/models/status.model';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class StatusState extends BaseYcState {
  readonly allStatusesLookup: StatusModel[];
  readonly allStatusesForDisplayMap: StatusObjectUI[];
  readonly activeOnlyStatusesForDisplayMap: StatusObjectUI[];
  readonly bankInfoChangeRequestStatusSelect: StatusObjectUI[];
  readonly adHocEmailStatusSelect: StatusObjectUI[];
  readonly eligibleForGivingStatusSelect: StatusObjectUI[];
  readonly eligibleForGivingStatusDisplay: StatusObjectUI[];
  readonly sanctionsScreeningFlagStatusSelect: StatusObjectUI[];
  readonly chapterRequestStatusSelect: StatusObjectUI[];
  readonly adminRequestStatusSelect: StatusObjectUI[];
  readonly checkReissueStatusSelect: StatusObjectUI[];
  readonly jobStatusSelect: StatusObjectUI[];
  readonly remittanceAddressRequestStatusSelect: StatusObjectUI[];
  readonly nonprofitStoryStatusSelect: StatusObjectUI[];
  readonly activeVettingRequestStatusSelect: StatusObjectUI[];
  readonly activeOldVettingRequestStatusSelect: StatusObjectUI[];
  readonly inactiveVettingRequestStatusSelect: StatusObjectUI[];
  readonly inactiveOldVettingRequestStatusSelect: StatusObjectUI[];
  readonly activeDomesticVettingApplicationStatusSelect: StatusObjectUI[];//
  readonly completedDomesticVettingApplicationStatusSelect: StatusObjectUI[];//
  readonly activeVettingApplicationStatusSelect: StatusObjectUI[];
  readonly completedVettingApplicationStatusSelect: StatusObjectUI[];
  readonly vettingRequestReportStatusSelect: StatusObjectUI[];
  readonly partnerVettingStatusFilterSelect: StatusObjectUI[];
  readonly prospectiveAdminOutreachStatusSelect: StatusObjectUI[];
  readonly programStatusSelect: StatusObjectUI[];

}
