import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
export const acceptedFileTypesLink = 'https://webfiles.blackbaud.com/files/support/helpfiles/npoconnect/content/npo-guide-file-requirements.html';

@Injectable({ providedIn: 'root' })
export class LinkService {

  constructor (
    private authService: AuthService
  ) { }

  getAcceptedFileTypesLink () {
    return acceptedFileTypesLink;
  }

  getPrivacyPolicyLink () {
    return this.authService.termsOfService?.privacyPolicyUrl;
  }

  getTermsOfServiceLink () {
    return this.authService.termsOfService?.url;
  }
}
