import { Pipe, PipeTransform } from '@angular/core';
import { ACCOUNT_RESPONSE_CODE_MAP, CUSTOMER_RESPONSE_CODE_MAP, GiactCodeObjUI, GIACT_CODE_TYPE, VERIFICATION_RESPONSE_MAP } from '@core/typings/ui/giact-codes.typing';
import { InflectService } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'nppGiactCodePipe'
})
export class GiactCodePipe implements PipeTransform {

  GIACT_CODE_TYPE: GIACT_CODE_TYPE;
  verificationCodes: GiactCodeObjUI[] = VERIFICATION_RESPONSE_MAP;
  accountCodes: GiactCodeObjUI[] = ACCOUNT_RESPONSE_CODE_MAP;
  customerCodes: GiactCodeObjUI[] = CUSTOMER_RESPONSE_CODE_MAP;

  verificationCodeText = this.i18n.translate(
    'bankInfo:textVerificationResponseCode',
    {},
    'Verification response code'
  );
  accountCodeText = this.i18n.translate(
    'bankInfo:textAccountResponseCode',
    {},
    'Account response code'
  );
  customerCodeText = this.i18n.translate(
    'bankInfo:textCustomerResponseCode',
    {},
    'Customer response code'
  );
  constructor (
    private i18n: I18nService,
    public inflect: InflectService
  ) { }

  transform (id: number, type: GIACT_CODE_TYPE): string {
    let codeVal = '';
    let codeDescription = '';
    let codeLabel = '';
    let codeValTrans = '';
    let codeDescTrans = '';

    switch (type) {
      case GIACT_CODE_TYPE.VERIFICATION:
        codeVal = (this.verificationCodes.find((code) => code.id === id))?.value;
        codeDescription = (this.verificationCodes.find((code) => code.id === id))?.description;
        codeLabel = this.verificationCodeText;
        break;
      case GIACT_CODE_TYPE.ACCOUNT:
        codeVal = (this.accountCodes.find((code) => code.id === id))?.value;
        codeDescription = (this.accountCodes.find((code) => code.id === id))?.description;
        codeLabel = this.accountCodeText;
        break;
      case GIACT_CODE_TYPE.CUSTOMER:
        codeVal = (this.customerCodes.find((code) => code.id === id))?.value;
        codeDescription = (this.customerCodes.find((code) => code.id === id))?.description;
        codeLabel = this.customerCodeText;
        break;
    }
    codeValTrans = this.i18n.translate(
      `bankInfo:text${this.inflect.trimPuncAndPascalize(codeVal)}`,
      {},
      codeVal
    );
    codeDescTrans = this.i18n.translate(
      `bankInfo:text${this.inflect.trimPuncAndPascalize(codeDescription)}`,
      {},
      codeDescription
    );

    return codeLabel + ': ' + codeValTrans + ' - ' + codeDescTrans;
  }

}
