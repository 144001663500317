
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ClassificationService } from '@core/services/classification.service';
import { ClassificationMaps, DebounceFactory, EinService, SearchFilter, SearchResult } from '@yourcause/common';
import { findKey } from 'lodash';
import { from, map, Subscription } from 'rxjs';
import { NonprofitSearchService } from '../nonprofit-search/nonprofit-search.service';

@Component({
  selector: 'yc-search-npo-merge',
  templateUrl: './search-npo-merge.component.html',
  styleUrls: ['./search-npo-merge.component.scss']
})
export class SearchNpoMergeComponent implements OnDestroy {
  @Input() placeholder = '';
  @Input() searchFilters: SearchFilter[] = [];
  @Input() maxResults = 5;
  @Output() onOrgSelect = new EventEmitter<any>();
  formGroup: UntypedFormGroup;
  results: any[];
  counter = 0;
  debounce: DebounceFactory<any[]>;
  classificationsMap: ClassificationMaps;
  sub = new Subscription();

  constructor (
    private formBuilder: UntypedFormBuilder,
    public einService: EinService,
    private classificationService: ClassificationService,
    private nonprofitSearchService: NonprofitSearchService

  ) {
    this.sub.add(this.classificationService.changesTo$('subsections').subscribe(() => {
      this.classificationsMap = this.classificationService.classifications;
    }));

    this.formGroup = this.formBuilder.group({
      term: ''
    });

    this.debounce = DebounceFactory.create<any[]>((term: string) => this.doSearch(term), 500);
    this.getDisplay = this.getDisplay.bind(this);
  }

  getIcon (result: SearchResult) {
    const id = findKey(this.classificationsMap.flatMap, {
      name: result.document.classification
    });

    return `assets/img/nonprofit/icons/cat_${id || 10}.gif`;
  }

  doSearch (term: string) {
    return from(this.nonprofitSearchService.searchForMergeOrgs(
      term,
      this.searchFilters
    )).pipe(map(val => val.data ?
      val.data.results.map(result => SearchResult.construct(result)) :
      []
    ));
  }

  getDisplay (result: SearchResult) {
    return `
      ${result.document.name}:
      ${this.einService.formatEin(result.document.registrationId)}
    `;
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
