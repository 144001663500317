
import { Injectable } from '@angular/core';
import { Transaction } from '@core/models/donation.model';
import { PendingDisbursementSummary } from '@core/models/finance.model';
import { PaymentDetailsApi } from '@core/models/payment.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse, APIResult, PaginatedResponse, PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class FinanceResources {
  readonly transactionsDownloadEndpoint = 'api/Nonprofit/Finance/TransactionsDownload'; // Transactions page
  readonly paymentsDownloadEndpoint = 'api/Nonprofit/Finance/PaymentsDownload'; // Payments page
  readonly paymentTransactionsDownloadEndpoint = 'api/Nonprofit/Finance/PaymentTransactionsDownload'; // Payments page - "transactions for one or multiple payments"

  constructor (
    private http: HttpRestService
  ) { }

  getPendingDisbursements () {
    return this.http.get<APIResponse<PendingDisbursementSummary>>(
      '/api/Nonprofit/Finance/TransactionsPendingDisbursementSummary',
      {}
    );
  }

  getMatchRequests () {
    return this.http.post<{ data: PaginatedResponse }>(
      '/api/MatchRequest/MatchRequests',
      {
        paginationOptions: {
        rowsPerPage: 1,
        pageNumber: 1,
        returnAll: true,
        sortColumns: [{
          columnName: 'Date',
          sortAscending: false
        }]}
      });
  }

  getPaginatedTransactions (
    paginationOptions: PaginationOptions<Transaction>,
    nonprofitPaymentId: number
   ) {
    return this.http.post(
      'api/Nonprofit/Finance/Transactions',
      {
        paginationOptions,
        nonprofitPaymentId
      }
    );
  }

  getAllTransactions (
    paginationOptions: PaginationOptions<Transaction>,
    isPending: boolean
  ) {
    return this.http.post<APIResult<Transaction>>(
      'api/Nonprofit/Finance/TransactionsForPayments',
      {
        paginationOptions,
        isPending
      }
    );
  }

  getPayments (
    paginationOptions: PaginationOptions<PaymentDetailsApi>
  ) {
    return this.http.post<APIResult<PaymentDetailsApi>>(
      'api/Nonprofit/Finance/Payments',
      {
        paginationOptions
      }
    );
  }

  getPaymentDetailForTransactions (paymentId: number) {
    const endpoint = `api/Nonprofit/Finance/PaymentDetail?paymentId=${paymentId}`;

    return this.http.get<APIResponse<PaymentDetailsApi>>(endpoint);
  }

  async getReissueIneligibleReasons (paymentId: number) {
    const endpoint = `api/CheckReissue/GetPaymentIneligibleForReissueReason?nonprofitPaymentId=${paymentId}`;

    const response = await this.http.post<{ data: string[] }>(endpoint, {});

    return response.data;
  }
}
