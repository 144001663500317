import { Validator } from '@angular/forms';
import { TableAsset } from '@yourcause/common';
import { User } from './user.model';

export interface CustomCountryBankAttributesObject {
  id: number;
  countryCode: string;
  attributeOrder: number;
  attributeName: string;
  attributeRegex: string;
  attributeMinLength: number;
  attributeMaxLength: number;
  attributeDescription?: string;
  attributeDescriptionKey?: string;
  attributeExample: string;
  isRequired: boolean;
}

export interface CustomBankAttributes {
  attributeName: string;
  bankCustomAttributesId: number; // when setting Info - id provided in CustomCountryBankAttributesObject
  id?: number; // when getting Info - if id/value exists for custom field
  value: string;
}

export interface DisbursementInfoPayload extends DisbursementInfo {
  assetsToAdd: TableAsset[];
  bankAccountNumber: string;
  bankSharingOptIn?: boolean;
  customBankAttributes?: CustomBankAttributes[];
  intermediaryBankAccountNumber: string;
  bbgfComplianceAccepted: boolean;
  AssetIds: string[];
}

export interface DisbursementInfo {
  bankAccountNumberLast2?: string;
  bankAddress?: string;
  bankName: string;
  bankSharingOptInSetting: boolean;
  bbgfComplianceIsValid: boolean;
  accountName: string;
  benefiContactName?: string;
  benefiEmailAddress?: string;
  benefiPhoneNumber?: string;
  benefiWebsite?: string;
  benefiStreetAddress?: string;
  benefiCountryCode?: string;
  benefiStateProvinceRegion?: string|number;
  benefiPostalCode?: string;
  benefiCity?: string;
  comment?: string;
  countryCode?: string;
  createDate: string;
  createdByUser: User;
  currencyCode?: string;
  customBankAttributes?: CustomBankAttributes[];
  giactAccountResponseCode?: number;
  giactCustomerResponseCode?: number;
  giactVerificationResponse?: number;
  hasIntermediaryBankInfo: boolean;
  id: number;
  inheritedFromParent: boolean;
  intermediaryBankAccountNumberLast2?: string;
  intermediarySwiftCode?: string;
  isCheckingAccount: boolean;
  isInReview: boolean;
  isInternational: boolean;
  isRejected: boolean;
  lastModifiedDate?: string;
  modifiedByUser: User;
  notes?: number;
  rejectedComment?: string;
  rejectedReasonId?: number;
  routingNumber?: string;
  swiftCode?: string;
  isCurrentRecord: boolean;
}

export interface DisbursementInfoModalResponse {
  maximumAttemptsExceeded: boolean;
}

export interface BankInfoFormFieldMap {
  [x: string]: [any, [Validator]]|any;
}

export interface InternationBankingDetailsModel {
  id: number;
  attributeName: string;
  attributeValue: string;
}


export interface IBANInfo {
  ibaNexample: string;
  ibaNlength: number;
  ibaNsupported: boolean;
}

export interface DomesticDisbursementInfoFormGroup {
  bankName: string;
  routingNumber: string;
  accountName: string;
  isCheckingAccount: boolean;
  bankAccountNumber: string;
};

export interface InternationalDisbursementInfoFormGroup {
  bankName: string;
  bankAddress: string;
  swiftCode: string;
  accountName: string;
  isCheckingAccount: boolean;
  benefiStreetAddress: string;
  benefiCity: string;
  benefiCountryCode: string;
  benefiStateProvinceRegion: number;
  benefiPostalCode: string;
  iBAN?: string;
  bankAccountNumber?: string;
};

export interface IntermediaryAccountInfoGroup {
  hasIntermediaryBankInfo: boolean;
  intermediarySwiftCode: string;
  intermediaryBankAccountNumber: string;
}

export interface InternationalDisbursementBeneficiaryInfoGroup {
  benefiContactName: string;
  benefiPhoneNumber: string;
  benefiEmailAddress: string;
  benefiWebsite: string;
}
export enum YcProcessingPartnerName {
  CANADA_HELPS = 'CanadaHelps',
  GLOBAL_GIVING = 'GlobalGiving',
  GIVE_INDIA = 'GiveIndia',
  CHARITIES_TRUST = 'Charities Trust'
}
export enum FISPlacholder {
  infoOnFile = 'Information on file',
  maskedNumber = '**********'
}

export enum DisbursementInfoOverrideFields {
  IBAN = 'IBAN',
}

// SAVE FOR FUTURE REFERENCE - MB
export enum CustomCountryFieldRegex {
  // IFSC: alphaNumeric; require first 4 alpha; 5th number '0'; remaining can be alpha-numeric;
  // exact length 11
  IFSC = '/^[a-zA-Z]{4}[0][a-zA-Z0-9]{6}$/',
  // CLABE: numeric; exact length 18
  CLABE = '/^[0-9]{18}$/',
  // BSB: numeric; exact length 6
  BSB = '/^[0-9]{6}$/',
  // SWIFT: alphaNumeric; require first 6 alpha; remaining can be alpha-numeric;
  // exact length 8 OR 11
  SWIFT_CODE = '/^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/',
  // Routing: numeric; exact length 9
  ROUTING_NUMBER = '/^[0-9]{9}$/',
  // Other Routing China: alphanumeric; CN + 12 digits; exact length 14
  OTHER_ROUTING_CHINA = '/^[C][N][0-9]{12}$/',
  // Other Routing Canada: numeric; exact length 9
  OTHER_ROUTING_CANADA = '/^[0-9]{9}$/',
  // Example: CNPJ: 13.339.532/0001-09
  CNPJ = '/[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/'
  // IBAN: alphaNumeric; require first 2 chars to match countryCode;
  // exact length varies by country
  // IBAN = '^[${countryCode}]{2}([0-9a-zA-Z]{${ibanLength-2}})'
}
