import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { NonprofitAdminService } from '@core/services/nonprofit-admin.service';
import { TermsOfServiceService } from '@core/services/terms-of-service.service';
import { TokenService } from '@core/services/token/token.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor (
    private tokenService: TokenService,
    private router: Router,
    private authService: AuthService,
    private termsOfServiceService: TermsOfServiceService,
    private nonprofitAdminService: NonprofitAdminService
  ) { }

  async canActivate (activatedRouteSnapshot: ActivatedRouteSnapshot): Promise<boolean> {
    const queryParams = activatedRouteSnapshot.queryParams as {
      alreadyLoggedInWithBbid: boolean;
    };

    const isBbid = this.tokenService.isBbid;
    const alreadyLoggedInWithBbid = queryParams.alreadyLoggedInWithBbid ? true : false;

    // Don't login with BBID twice if we already signed in
    if ((isBbid) && !alreadyLoggedInWithBbid) {
      const passed = await this.authService.loginWithBbid(false, false, false, false);

      // Don't continue if BBID login was unsuccessful
      if (!passed) {
        return false;
      }
    }

    if (!this.tokenService.getIsLoggedIn()) {
      this.router.navigateByUrl('login');

      return false;
    }

    if (!this.tokenService.tokenInfo) {
      await this.tokenService.fetchUserClaims();
    }

    // Terms of service logic
    if (!await this.termsOfServiceService.getUserTermsOfServiceModal()){
      return false;
    }

    // Not internal admin but npo admin logic
    if (!this.authService.isInternalAdmin() && !await this.nonprofitAdminService.confirmNpoAdmin()){
      return false;
    }

    return true;

  }
}
