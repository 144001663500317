import { FeaturedPartner, FeaturedPartnerCollateral } from '@core/models/featured-partner.model';
import { CachedAttr, CACHE_TYPES } from '@yourcause/common/cache';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class FeaturedPartnerState extends BaseYcState {
  @CachedAttr(CACHE_TYPES.LOCALSTORAGE, [])
  readonly hiddenPartnerIds: number[];
  readonly currentFeaturedPartner: FeaturedPartner;
  readonly currentFeaturedPartnerCollateral: FeaturedPartnerCollateral;
}
