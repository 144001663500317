export enum LoginScenario {
  NEW_BBID_USER = 2,
  EXISTING_BBID_USER = 3
}

export interface LinkToBbidPayload {
  email: string;
  blackbaudIdToken: string;
  inviteEmail: string;
}

export interface DetermineSignInFlow {
  isBlackbaudIdEnabled: boolean;
  isBlackbaudIdLinked: boolean;
  hasExistingBlackbaudIdAccount: boolean;
  existing: boolean;
  requireSso: boolean;
}

export enum BbidLinkingResult {
  LINKED = 1,
  FAILED = 2
}

export enum BbidFailedReason {
  LINK_FAILED_UNKNOWN = 1,
  BBID_LINKED_TO_ANOTHER_ACCOUNT = 2,
  ACCOUNT_CREATE_FAILED = 3,
  BBID_LOGIN_FAILED = 4
}
