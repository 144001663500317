import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';
import { differenceInDays, differenceInMonths, startOfDay } from 'date-fns';

@Pipe({
  name: 'nppCalculateDaysAgo'
})
export class CalculateDaysAgoPipe implements PipeTransform {
  constructor (
    private i18n: I18nService
  ) { }

  transform (date: string): string {
    let returnVal = '';
    const beginningOfOriginalDate = startOfDay(new Date(date));
    const beginningOfToday = startOfDay(new Date());
    const daysDiff = differenceInDays(beginningOfToday, beginningOfOriginalDate);
    const monthsDiff = differenceInMonths(beginningOfToday, beginningOfOriginalDate);

    if (daysDiff >= 1 && daysDiff <= 60) {
      returnVal = this.i18n.translate(
        `common:textDaysAgo`,
        {
          number: daysDiff
        },
        '__number__ day(s) ago'
      );
    } else if (daysDiff > 60) {
      returnVal = this.i18n.translate(
        'common:textMonthsAgo',
        {
          number: monthsDiff
        },
        '__number__ months ago'
      );
    } else {
      returnVal = '';
    }

    return returnVal;
  }
}
