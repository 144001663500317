import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse } from '@yourcause/common';
import { TokenPayload, TokenResponse } from '../../typings/token.typing';
import { TokenAdapterService } from './token-adapter.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TokenRetrievalResources {

  constructor (
    private httpRestService: HttpClient,
    private adapter: TokenAdapterService
  ) { }

  private async fetchToken (data: TokenPayload) {
    const val = await lastValueFrom(this.httpRestService.post<APIResponse<TokenResponse>>('/api/token', data));

    return val.data;
  }

  async getToken (data: TokenPayload) {
    const returnVal: TokenResponse = await this.fetchToken(data);

    return this.adapter.handleTokenRequest(returnVal);
  }

  async getPlatformAdminToken (
    code: string,
    clientIdentifier: string
  ): Promise<TokenResponse> {
    const endpoint = 'api/admin/Sso/Token';

    const result = await lastValueFrom(this.httpRestService.post<APIResponse<TokenResponse>>(endpoint, {
      code,
      clientIdentifier
    }));

    return result.data;
  }
}

