import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NonprofitSearchService } from '@core/components/nonprofit-search/nonprofit-search.service';
import { NonprofitSearchFacet } from '@core/models/npo.model';
import { SpinnerService } from '@core/services/spinner.service';
import { ALL_SKIP_FILTER, DebounceFactory, SearchByType, SearchFilter, SearchResult, TypeToken } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { uniqBy } from 'lodash';
import { from } from 'rxjs';

@Component({
  selector: 'yc-public-nonprofit-search',
  templateUrl: './public-nonprofit-search.component.html',
  styleUrls: ['./public-nonprofit-search.component.scss']
})
export class PublicNonprofitSearchComponent implements OnInit {
  @Input() searchTerm: string;
  @Output() returnResults = new EventEmitter<SearchResult[]>();

  formGroupTypeToken = new TypeToken<UntypedFormGroup>();
  searchFiltersShowAll = false;
  searchFiltersShowChapters: boolean;
  searchFiltersRegAuth: string;
  searchFiltersCountry: string;
  searchFiltersState: string;
  searchType = SearchByType.TERM;
  SearchByType = SearchByType;
  loadingCountryOptions = true;
  loadingStateOptions = true;
  regAuthOptions: TypeaheadSelectOption[] = [];
  countryOptions: TypeaheadSelectOption[] = [];
  stateProvRegionOptions: TypeaheadSelectOption[] = [];
  searchFilters: SearchFilter[] = [];
  formArray: UntypedFormArray;
  searchForm: UntypedFormGroup;
  advancedFilterForm: UntypedFormGroup;
  facets: NonprofitSearchFacet;
  activeFacets: NonprofitSearchFacet;
  debounce: DebounceFactory<any> = DebounceFactory.create<any>(() => {
    return from(this.doSearch());
  }, 100);
  results: SearchResult[];
  placeholderText = this.i18n.translate(
    'search:textSearchByNonprofitNameRegIdLocation',
    {},
    'Search by Nonprofit Name, Registration ID or Location'
  );
  defaultRegAuthText = this.i18n.translate(
    'search:textAllRegAuths',
    {},
    '-- All registration authorities --'
  );
  defaultCountryText = this.i18n.translate(
    'search:textAllCountries',
    {},
    '-- All countries --'
  );
  defaultStateText = this.i18n.translate(
    'search:textAllStateProvRegs',
    {},
    '-- All states, provinces or regions --'
  );

  constructor (
    private formBuilder: UntypedFormBuilder,
    private nonprofitSearchService: NonprofitSearchService,
    private i18n: I18nService,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit () {
    this.searchForm = this.formBuilder.group({
      term: this.searchTerm || '',
      showChapters: false
    });
    this.advancedFilterForm = this.formBuilder.group({
      registrationAuthority: [this.searchFiltersRegAuth || ALL_SKIP_FILTER],
      country: [this.searchFiltersCountry || ALL_SKIP_FILTER],
      stateProvRegName: [this.searchFiltersState || ALL_SKIP_FILTER]
    });

    this.formArray = this.formBuilder.array([
      this.searchForm,
      this.advancedFilterForm
    ]);

    this._onTermChange();
  }

  async doSearch () {
    this.spinnerService.startSpinner();
    await this.nonprofitSearchService.searchByTerm();
    this.facets = this.nonprofitSearchService.allFacets;
    if (!this.searchFiltersRegAuth) {
      this.setRegAuthOptions(this.facets);
    }
    if (this.searchFiltersRegAuth && !this.searchFiltersCountry) {
      this.setCountryOptions(this.facets);
    }
    if (this.searchFiltersCountry && !this.searchFiltersState) {
      this.setStateOptions(this.facets);
    }
    this.spinnerService.stopSpinner();
  }

  _onTermChange = () => {
    this.searchTerm = this.searchForm.value.term;
    this.searchFiltersShowChapters = this.formArray.at(0).value.showChapters;
    this.nonprofitSearchService.setSearchType(this.searchType);
    this.nonprofitSearchService.setSearchTerm(this.searchTerm, this.searchType);
    this.nonprofitSearchService.setSearchFilters(
      this.searchFiltersShowAll,
      this.searchFiltersShowChapters,
      this.searchFiltersRegAuth,
      this.searchFiltersCountry,
      this.searchFiltersState
    );

    if (!!this.searchForm.value.term) {
      if (this.searchForm.value.term !== this.searchTerm) {
        this.clearAdvancedFilters();
      }
      this.debounce.exec(this.searchForm.value.term)
        .subscribe((results) => {
          if (results?.length <= 1
            &&
            !(this.searchFiltersRegAuth ||
              this.searchFiltersCountry ||
              this.searchFiltersState)
          ) {
            this.facets = null;
          }
          this.results = this.nonprofitSearchService.searchResults;
          this.returnResults.emit(this.nonprofitSearchService.searchResults);
      });
    } else if (!this.searchForm.value.term || this.searchForm.value.term === '') {
      this.clearAdvancedFilters();
      this.returnResults.emit([]);
    }
  };

  _changeType () {
    this.searchFiltersShowChapters = this.searchForm.value.showChapters;
    this.clearAdvancedFilters();
    this._onTermChange();
  }

  _changeFilter (
    event: string,
    type: 'regAuth'|'country'|'state'
  ) {
    if (event && type) {
      switch (type) {
        default:
        case 'regAuth':
          this.searchFiltersRegAuth = event;
          this.formArray.at(1).get('country').setValue(ALL_SKIP_FILTER);
          this.formArray.at(1).get('stateProvRegName').setValue(ALL_SKIP_FILTER);
          this.countryOptions = [];
          this.stateProvRegionOptions = [];
          this.searchFiltersCountry = '';
          this.searchFiltersState = '';
          this.loadingCountryOptions = true;
          break;
        case 'country':
          this.searchFiltersCountry = event;
          this.formArray.at(1).get('stateProvRegName').setValue(ALL_SKIP_FILTER);
          this.stateProvRegionOptions = [];
          this.searchFiltersState = '';
          this.loadingStateOptions = true;
          break;
        case 'state':
          this.searchFiltersState = event;
          break;
      }
      this._onTermChange();
    } else if (!event && type) {
      switch (type) {
        default:
        case 'regAuth':
          this.clearAdvancedFilters();
          break;
        case 'country':
          this.searchFiltersCountry = '';
          this.searchFiltersState = '';
          this.stateProvRegionOptions = [];
          this.formArray.at(1).get('country').setValue(ALL_SKIP_FILTER);
          this.formArray.at(1).get('stateProvRegName').setValue(ALL_SKIP_FILTER);
          this.loadingStateOptions = false;
          break;
        case 'state':
          this.searchFiltersState = '';
          this.formArray.at(1).get('stateProvRegName').setValue(ALL_SKIP_FILTER);
          break;
      }
      this._onTermChange();
    } else if (!event && !type) {
      this.returnResults.emit([]);
      this.clearAdvancedFilters();
    }
  }

  clearAdvancedFilters () {
    this.facets = null;
    this.regAuthOptions = [];
    this.countryOptions = [];
    this.stateProvRegionOptions = [];
    this.searchFiltersRegAuth = '';
    this.searchFiltersCountry = '';
    this.searchFiltersState = '';
    this.formArray.at(1).get('registrationAuthority').setValue(ALL_SKIP_FILTER);
    this.formArray.at(1).get('country').setValue(ALL_SKIP_FILTER);
    this.formArray.at(1).get('stateProvRegName').setValue(ALL_SKIP_FILTER);
  }

  setRegAuthOptions (facets: NonprofitSearchFacet) {
    this.regAuthOptions = [{
      label: this.defaultRegAuthText,
      value: ALL_SKIP_FILTER
    }];
    if (facets) {
      facets.registrationAuthority.forEach((facet) => {
        this.regAuthOptions.push({
          label: facet.value,
          value: facet.value
        });
      });
      this.regAuthOptions = uniqBy(this.regAuthOptions, (opt) => opt.label && opt.value);
    }
  }

  setCountryOptions (facets: NonprofitSearchFacet) {
    this.countryOptions = [{
      label: this.defaultCountryText,
      value: ALL_SKIP_FILTER
    }];
    if (facets) {
      facets.country.forEach((facet) => {
        this.countryOptions.push({
          label: facet.value,
          value: facet.value
        });
      });
      this.countryOptions = uniqBy(this.countryOptions, (opt) => opt.label && opt.value);
      this.loadingCountryOptions = false;
    }
  }

  setStateOptions (facets: NonprofitSearchFacet) {
    this.stateProvRegionOptions = [{
      label: this.defaultStateText,
      value: ALL_SKIP_FILTER
    }];
    if (facets) {
      facets.stateProvRegName.forEach((facet) => {
        this.stateProvRegionOptions.push({
          label: facet.value,
          value: facet.value
        });
      });
      this.stateProvRegionOptions = uniqBy(this.stateProvRegionOptions, (opt) => opt.label && opt.value);
      this.loadingStateOptions = false;
    }
  }

  get showClear () {
    return !!this.searchForm.value.term;
  }

  clearSearchAndResults = () => {
    this.searchForm.get('term').setValue('');
    this.returnResults.emit([]);
    this.clearAdvancedFilters();
  };
}
