import { Injectable } from '@angular/core';
import { DashboardChartAllDetail, DataArray, DonationChartEntry, DonationsByCompany, DonationsByFundingSource, DonationsBySource, DonationsBySourceAdaptedPayload, DonationsBySourcePayload, SelectedDates } from '@core/models/dashboard.model';
import { DateService } from '@yourcause/common/date';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { subDays } from 'date-fns';
import { DashboardResources } from './dashboard.resources';
import { DashboardState } from './dashboard.state';


@AttachYCState(DashboardState)
@Injectable({ providedIn: 'root' })
export class DashboardService extends BaseYCService<DashboardState> {

  constructor (
    private dashboardResources: DashboardResources,
    private dateService: DateService
  ) {
    super();
  }

  get donationsByCompany () {
    return this.get('donationsByCompany');
  }

  get donationsByFundingSource () {
    return this.get('donationsByFundingSource');
  }

  get donationsByFundingSourceDates () {
    return this.get('donationsByFundingSourceDates');
  }

  get chartStartDate () {
    return this.get('chartStartDate');
  }

  get chartEndDate () {
    return this.get('chartEndDate');
  }

  get defaultDonationsBySourcePayload () {
    return this.get('defaultDonationsBySourcePayload');
  }

  get dashboardDateRange () {
    return this.get('dashboardDateRange');
  }

  setDefaultDonationsBySourcePayload () {
    // this will typically never be neccessary
    // as we default to the date range select of
    // "This month" - from 1st of this month to today, or the Cached 'dashboardDateRange'
    const chartStartDate = this.dateService.formatDate(subDays(new Date(), 365));
    const chartEndDate = this.dateService.formatDate(new Date());
    const defaultPayload = {
      startDate: chartStartDate,
      endDate: chartEndDate,
      chapterTransactionsOnly: false,
      parentTransactionsOnly: false
    };
    this.set('defaultDonationsBySourcePayload', defaultPayload);
  }

  async setAllDonationsData (
    vals: Partial<DonationsBySourcePayload>,
    allTime = false
  ) {
    const payload: DonationsBySourcePayload = {
      startDate: this.dashboardDateRange?.startDate,
      endDate: this.dashboardDateRange?.endDate,
      chapterTransactionsOnly: vals.chapterTransactionsOnly,
      parentTransactionsOnly: vals.parentTransactionsOnly
    };
    await Promise.all([
      this.setDonationsByCompany(payload),
      this.setDonationsByFundingSource(
        payload,
        allTime
      )
    ]);
  }

  async setDonationsByCompany (
    payload?: DonationsBySourcePayload
  ) {
    if (!payload) {
      this.setDefaultDonationsBySourcePayload();
    }
    const response = await this.dashboardResources.getDonationsByCompany(
      payload || this.defaultDonationsBySourcePayload
    );
    const adaptedResponse = this.adaptDonationsTopTen(response);
    this.set('donationsByCompany', adaptedResponse);
  }

  async setDonationsByFundingSource (
    payload?: DonationsBySourcePayload,
    allTime = false
  ) {
    if (!payload) {
      this.setDefaultDonationsBySourcePayload();
    }
    const response = await this.dashboardResources.getDonationsByFundingSource(
      payload || this.defaultDonationsBySourcePayload
    );
    const adapted: DonationsBySourceAdaptedPayload = this.adaptDonationsBySource(
      response,
      allTime
    );
    this.set('donationsByFundingSource', adapted.data);
    this.set('donationsByFundingSourceDates', adapted.dates);
  }

  async setSelectedDates (dateRange: SelectedDates) {
    this.set('dashboardDateRange', dateRange);
  }

  adaptDonationsBySource (
    originalResponse: DonationsByFundingSource|DonationsBySource,
    allTime = false
  ): DonationsBySourceAdaptedPayload {
    const dataArray: DonationChartEntry[] = [];
    const datesArray: string[] = [];
    const labelArray: string[] = [];
    const existense: { [x: string]: DonationChartEntry } = {};

    if ('chartDetail' in originalResponse) {
      const validRecords = allTime ?
        originalResponse.chartDetail.filter(item => !!item.total) :
        originalResponse.chartDetail;
      validRecords.forEach((data) => {
        const date = allTime ?
          new Date(data.year, 0) :
          new Date(data.year, data.month - 1);
        const newDate = allTime ?
          this.dateService.formatDate(date, 'yyyy') :
          this.dateService.formatDate(date, 'MMM-yyyy');
        if (datesArray.indexOf(newDate) === -1) {
          datesArray.push(newDate);
        }
        if (!labelArray.includes(data.label) && data.label) {
          labelArray.push(data.label);
        }
        existense[`${newDate}${data.label}`] = {
          x: newDate,
          y: data.total,
          label: data.label
        };
      });

      datesArray.forEach((date) => {
        labelArray.forEach((label) => {
          dataArray.push(existense[`${date}${label}`] || {
            x: date,
            y: 0,
            label
          });
        });
      });
      const newObj: { [x: string]: DonationChartEntry[] } = {};
      dataArray.map(obj => {
        if (newObj[obj.label]) {
          newObj[obj.label].push({
            x: obj.x,
            y: obj.y
          });
        } else {
          newObj[obj.label] = [{
            x: obj.x,
            y: obj.y
          }];
        }

        return obj;
      });
      const formattedDataArray: DataArray[] = [];
      Object.keys(newObj).map((key) => {
        formattedDataArray.push({
          data: newObj[key],
          label: key
        });
      });

      return {
        data: formattedDataArray,
        dates: datesArray
      };
    } else {
      return {
        data: [],
        dates: []
      };
    }
  }

  adaptDonationsTopTen (
    originalResponse: DonationsByCompany
  ): DashboardChartAllDetail {
    if (originalResponse && originalResponse.chartDetail) {
      const topTenDetail = originalResponse.chartDetail.map((item) => {
        return {
          companyName: item.label,
          numberOfDonations: item.count,
          donationTotal: item.total
        };
      });
      const allDetail = originalResponse.allCompaniesChartDetail.map((item) => {
        return {
          companyName: item.label,
          numberOfDonations: item.count,
          donationTotal: item.total
        };
      });

      return {
        chartDetail: topTenDetail,
        allCompaniesChartDetail: allDetail
      };
    } else {
      return {
        chartDetail: [],
        allCompaniesChartDetail: []
      };
    }
  }

  clearDataOnNonprofitChange () {
    this.setSelectedDates(null);
    this.set('donationsByCompany', null);
    this.set('donationsByFundingSource', null);
  }
}
