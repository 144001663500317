
import { Injectable } from '@angular/core';
import { ManageReleaseNotePayload, ManageReleaseNotesModalResponse, ReleaseNoteAcknowledgement, ReleaseNotePayload, ReleaseNotesModalScenario } from '@core/models/release-notes.model';
import { PaginationOptions } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { ReleaseNotesResources } from './release-notes.resources';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReleaseNotesService {
  ReleaseNotesModalScenario = ReleaseNotesModalScenario;

  constructor (
    private notifier: NotifierService,
    private i18n: I18nService,
    private releaseNotesResources: ReleaseNotesResources
  ) { }

  getCurrentReleaseAcknowledgement (): Observable<ReleaseNoteAcknowledgement> {
    return this.releaseNotesResources.getCurrentReleaseAcknowledgement();
  }

  setReleaseAcknowledged (releaseID: number) {
    try {
      const response = this.releaseNotesResources.setReleaseAcknowledged(releaseID);

      return response;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async getReleaseList (
    paginationOptions: PaginationOptions<ReleaseNotePayload>
  ) {
    const response = await this.releaseNotesResources.getReleaseList(
      paginationOptions
    );

    return response;
  }

  async handleManageReleaseNoteModalResponse (
    payload: ManageReleaseNotesModalResponse,
    deps: {
      context: ReleaseNotesModalScenario;
      release: ManageReleaseNotePayload;
    }
  ) {
    const releaseNotePayload: ManageReleaseNotePayload = {
      version: payload.manageReleaseNotesPayload.version,
      releaseDate: payload.manageReleaseNotesPayload.releaseDate,
      startDate: payload.manageReleaseNotesPayload.startDate,
      endDate: payload.manageReleaseNotesPayload.endDate,
      description: payload.manageReleaseNotesPayload.description,
      userReleaseNoteUrl: payload.manageReleaseNotesPayload.userReleaseNoteUrl,
      adminReleaseNoteUrl: payload.manageReleaseNotesPayload.adminReleaseNoteUrl
    };
    if (deps.context === ReleaseNotesModalScenario.EDIT) {
      releaseNotePayload.id = deps.release.id;

      return this.updateRelease(releaseNotePayload);
    } else {
      return this.createRelease(releaseNotePayload);
    }
  }

  async createRelease (payload: ReleaseNotePayload) {
    try {
      await this.releaseNotesResources.createRelease(payload);
      this.notifier.success(this.i18n.translate(
        'platformAdmin:notificationSuccessCreatingReleaseNotes',
        {},
        'Successfully created release notes'
      ));
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        'platformAdmin:notificationErrorCreatingReleaseNotes',
        {},
        'There was an error creating release notes'
      ));
      throw e;
    }
  }

  async updateRelease (payload: ReleaseNotePayload) {
    try {
      await this.releaseNotesResources.updateRelease(payload);
      this.notifier.success(this.i18n.translate(
        'platformAdmin:notificationSuccessUpdatingReleaseNotes',
        {},
        'Successfully updated release notes'
      ));
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        'platformAdmin:notificationErrorUpdatingReleaseNotes',
        {},
        'There was an error updating release notes'
      ));
      throw e;
    }
  }

  async deleteRelease (releaseId: number) {
    try {
      await this.releaseNotesResources.deleteRelease(releaseId);
      this.notifier.success(this.i18n.translate(
        'platformAdmin:notificationSuccessDeletingReleaseNotes',
        {},
        'Successfully deleted release notes'
      ));
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        'platformAdmin:notificationErrorDeletingReleaseNotes',
        {},
        'There was an error deleting release notes'
      ));
      throw e;
    }
  }

  async getListOfReleasesForUser (
    paginationOptions: PaginationOptions<ReleaseNotePayload>
  ) {
    try {
      const response = await this.releaseNotesResources.getListOfReleasesForUser(
        paginationOptions
      );

      return response;
    } catch (e) {
      throw e;
    }
  }
}
