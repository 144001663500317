import { NonprofitAsset } from '@core/models/nonprofit-asset.model';
import { EligibilityStatus, VettingApplicationStatus } from '@core/models/status.model';
import { User } from '@core/models/user.model';
import { TableAsset } from '@yourcause/common';
import { VettingRequestAPI } from './api/vetting-request.typing';
import { AccordionStepperStep } from '@yourcause/common/accordion';

export interface ActiveVettingInformation {
  vettingId: number;
  vettingAuthority: string;
  vettingTypeId: number;
  vettingComment: string;
  vettingCreateDate: string;
  vettingExpirationDate: string;
}

export interface NonprofitVettingApplicationDetailsModel {
  applicationId: number;
  vettingOrganizationTypeId: OrganizationTypeForVetting;
  gcVettingApplicationId: number;
  submittedOn: string;
  submittedByUser: User;
  vettingApplicationStatusId: number;
  notes: string;
  modifiedByUser: User;
  lastModifiedDate: string;
  createDate: string;
  createdByUser?: User;
  nonprofitVettingApplicationStatusComment: string;
  nonprofitVettingApplicationStatusChangeDate: string;
  assets: NonprofitAsset[];
  reevaluationDate?: string;
}

export interface VettingInformation {
  activeVettingInformation: ActiveVettingInformation;
  eligibleForGiving: EligibilityStatus;
  isGlobalGivingAtlasVetted: boolean;
  isImplicitlyVetted: boolean;
  isIrsVetted: boolean;
  nonprofitId: number;
  optedOutOfVetting: boolean;
  vettingApplicationInformation: NonprofitVettingApplicationDetailsModel;
  vettingRequestInformation: VettingRequestAPI.NonprofitVettingRequestDetail[];
  nonprofitVettingApplicationRenewalCreationActive: boolean;
  enableVettingAppTile: boolean;
  vettingAppTileDescription: string;
}

export interface CreateOrUpdateNonprofitVettingApplicationRequestModel {
  vettingApplicationId?: number;
  gcVettingApplicationId?: number;
  nonprofitId?: number;
  vettingApplicationStatusId?: VettingApplicationStatus;
  notes?: string;
  statusComment?: string;
  isSubmission?: boolean;
  rejectionReasonId?: number;
  reevaluationDate?: string;
  vettingOrganizationTypeId: OrganizationTypeForVetting;
}

export interface VettingApplicationDataWithAssets extends CreateOrUpdateNonprofitVettingApplicationRequestModel {
  existingAssets?: NonprofitAsset[];
  assetsToAdd?: TableAsset[];
  createdByUser?: User;
  createdByName?: string;
  createDate?: string;
  submittedBy?: User;
  submittedByName?: string;
  submittedOn?: string;
  nonprofitVettingApplicationStatusChangeDate?: string;
  vettingExpirationDate?: string;
  optedOutOfVetting?: boolean;
  nonprofitVettingApplicationStatusComment?: string;
}

export interface VettingApplicationStepperStep extends AccordionStepperStep {
  openStepOnInit?: boolean;
}

export interface DocumentUploadModalResponse {
  applicationId: number;
  isSubmission: boolean;
}

export interface OptOutModalResponse {
  proceedToGC?: boolean;
  optInOut?: boolean;
}

export enum OrganizationTypeForVetting {
  STANDARD = 1,
  EXEMPT = 2,
  SUPPORTING = 3,
  UNKNOWN = 4,
  STAND_ALONE_CHURCH = 5,
  GOVERNMENT_ENTITY = 6
}

export enum OrganizationTypeForVettingText {
  STANDARD = 'Standard',
  EXEMPT = 'Group Exemption',
  SUPPORTING = 'Supporting',
  UNKNOWN = 'Unknown',
  STAND_ALONE_CHURCH = 'Stand Alone Church',
  GOVERNMENT_ENTITY = 'Government Entity'
}

export const OrganizationTypeMap = {
  1: OrganizationTypeForVettingText.STANDARD,
  2: OrganizationTypeForVettingText.EXEMPT,
  3: OrganizationTypeForVettingText.SUPPORTING,
  4: OrganizationTypeForVettingText.UNKNOWN,
  5: OrganizationTypeForVettingText.STAND_ALONE_CHURCH,
  6: OrganizationTypeForVettingText.GOVERNMENT_ENTITY
};

export enum VettingTypeId {
  ED = 1,
  Standard = 3,
  DomesticVetting = 4,
  Partner = 5
}

export enum VettingTypeName {
  DOMESTIC = 'Domestic Vetting',
  STANDARD = 'Standard',
  ED = 'ED',
  PARTNER = 'Partner'
}

export enum VettingOptInOutModalScenario {
  OPT_OUT,
  OPT_IN
}
