
import { Component, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Nonprofit } from '@core/models/npo.model';
import { AuthService } from '@core/services/auth.service';
import { BannerService } from '@core/services/banner.service';
import { FeatureFlagType } from '@core/typings/feature-flag.typing';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NonprofitService } from '../../../nonprofit/nonprofit.service';
import { NotesService } from '../../../notes/notes.service';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';

@Component({
  selector: 'yc-admin-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrls: ['./admin-navigation.component.scss']
})
export class AdminNavigationComponent implements OnDestroy {
  @Input() visibleRouteRegex = /^\/(search|login|yourcause-admin|my-account|platform-admin|help-support|compliance|nonprofit-management|implementation|reporting|custom-alerts|system-health-dashboard|submitted-support-requests).*$/;

  activeNpo: Nonprofit;
  programFlagValue = this.featureFlagService.getFeatureFlagEnabledValue(FeatureFlagType.Programs);
  tooltipText = this.i18n.translate(
    'admin:textCopyRegistrationId',
    {},
    'Copy registration ID'
  );
  notesCount$ = this.noteService.changesTo$('noteCount');
  visible: boolean;

  sub = new Subscription();
  originalLinks = [{
    href: 'account-setup',
    label: 'Administration',
    i18nKey: 'sidebar:linkAccountSetup'
  }, {
    href: 'user-management',
    label: 'User Management',
    i18nKey: 'sidebar:linkUserManagement'
  }, {
    href: 'dashboard',
    label: 'Dashboard',
    i18nKey: 'sidebar:linkDashboard'
  }, {
    href: 'giving',
    label: 'Giving',
    i18nKey: 'sidebar:linkGiving'
  }, {
    href: 'volunteering',
    label: 'Volunteering',
    i18nKey: 'sidebar:linkVolunteering'
  }, {
    href: 'my-workspace',
    label: 'Workspace',
    i18nKey: 'sidebar:linkWorkspace'
  }, {
    href: 'notes',
    label: 'Notes (__noteCount__)',
    context: this.notesCount$.pipe(map(noteCount => ({ noteCount }))),
    i18nKey: 'admin:textNotesDynamicLink'
  }].concat(
    !!this.programFlagValue ?
    [{
      href: 'programs',
      label: 'Programs',
      i18nKey: 'sidebar:linkPrograms'
    }] : []
  );
  chapterObj = {
    href: 'chapters',
    label: 'Chapters',
    i18nKey: 'nonprofits:textChapters'
  };
  @Input() links = this.originalLinks;

  constructor (
    public router: Router,
    private noteService: NotesService,
    private i18n: I18nService,
    private notifier: NotifierService,
    private nonprofitService: NonprofitService,
    private bannerService: BannerService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService
  ) {
    this.sub.add(this.nonprofitService.changesTo$('activeNpo')
      .subscribe((activeNpo) => {
        if (!!activeNpo) {
          this.activeNpo = activeNpo;
          if (!activeNpo.isChapterAffiliate) {
            this.addChapterLink();
          } else {
            this.removeChapterLink();
          }
        }
      })
    );
    this.sub.add(this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.visible = !this.visibleRouteRegex.test(this.router.url);
        if (
          (!!this.activeNpo && !this.activeNpo?.isActive) &&
          ((this.visible && this.authService.isInternalAdmin()) ||
          (this.authService.isNonprofitUser()))
        ) {
          this.bannerService.setShowInactiveNpoBanner(true);
        } else {
          this.bannerService.setShowInactiveNpoBanner(false);
        }
        this.bannerService.setPortalBanners();
      }
    }));
  }

  copyRegistrationId (regId: string) {
    navigator.clipboard.writeText(regId).then(() => {
      this.notifier.success(this.i18n.translate(
        'admin:textRegIdCopied',
        {},
        'Registration ID copied'
      ));
    });
  }

  addChapterLink () {
    this.links = [
      ...this.originalLinks.slice(0, 1),
      this.chapterObj,
      ...this.originalLinks.slice(1)
    ];
  }

  removeChapterLink () {
    this.links = this.originalLinks;
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
