import * as queryString from 'query-string';

export interface GenericStorage {
  getItem (key: string): string;
  setItem (key: string, value: string): void;
  removeItem (key: string): void;
}

export const urlStorage: GenericStorage = {
  getItem (key) {
    return queryString.parse(location.hash)[key];
  },
  setItem (key, value) {
    const tmp = queryString.parse(location.hash);
    tmp[key] = value;
    location.hash = queryString.stringify(tmp);
  },
  removeItem (key) {
    const tmp = queryString.parse(location.hash);
    delete tmp[key];
    location.hash = queryString.stringify(tmp);
  }
};
