import { Component, Input, OnInit } from '@angular/core';
import { GetNonprofitAdditionalInformationResponseModel, NonprofitAdditionalInformationAttributesText, NonprofitNameAddressModel } from '@core/models/npo.model';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';

@Component({
  selector: 'npp-nonprofit-block-table-info-modal',
  templateUrl: './nonprofit-block-table-info-modal.component.html',
  styleUrls: ['./nonprofit-block-table-info-modal.component.scss']
})

export class NppNonprofitBlockTableInfoModalComponent extends YCModalComponent<void> implements OnInit {
  @Input() nonprofitId: number;
  @Input() additionalInfo: GetNonprofitAdditionalInformationResponseModel;
  NonprofitAdditionalInformationAttributesText = NonprofitAdditionalInformationAttributesText;
  registeredNameAndAddress: NonprofitNameAddressModel;
  disbursementNameAndAddress: NonprofitNameAddressModel;
  displayNameAndAddress: NonprofitNameAddressModel;
  attributeHdr = this.i18n.translate(
    'nonprofit:hdrAttribute',
    {},
    'ATTRIBUTE'
  );
  valueHdr = this.i18n.translate(
    'nonprofit:hdrValue',
    {},
    'VALUE'
  );
  cancelButtonText = this.i18n.translate(
    'common:btnClose',
    {},
    'Close'
  );
  noNameOrAddressOnFiletext = this.i18n.translate(
    'nonprofit:textNoNameOrAddressOnFile',
    {},
    'No name or address on file'
  );
  noNameOnFiletext = this.i18n.translate(
    'nonprofit:textNoNameOnFile',
    {},
    'No name on file'
  );
  noAddressOnFiletext = this.i18n.translate(
    'nonprofit:textNoAddressOnFile',
    {},
    'No address on file'
  );

  constructor (
    private i18n: I18nService
  ) {
    super();
  }

  async ngOnInit () {
    this.disbursementNameAndAddress = this.additionalInfo.disbursementNameAndAddress;
    this.registeredNameAndAddress = this.additionalInfo.registeredNameAndAddress;
    this.displayNameAndAddress = this.additionalInfo.displayNameAndAddress;
  }

  formatAttributeBoolean (attrVal: boolean) {
    return attrVal ?
      this.i18n.translate(
        'common:textYes',
        {},
        'Yes'
      ) :
      this.i18n.translate(
        'common:textNo',
        {},
        'No'
      );
  }

  _onClose () {
    this.closeModal.emit();
  }
}
