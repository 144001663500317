import { Pipe, PipeTransform } from '@angular/core';
import { endOfDay, startOfDay } from 'date-fns';

@Pipe({
  name: 'nppCheckPastDate'
})
export class CheckPastDatePipe implements PipeTransform {
  // Returns true if given date is a past date
  transform (date: string): boolean {
    const currentDate = startOfDay(new Date());
    const eodDate = endOfDay(new Date(date));

    return currentDate > eodDate;
  }

}
