import { Injectable } from '@angular/core';
import { StatusModel } from '@core/models/status.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class StatusResources {
  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getAllStatuses () {
    return this.httpRestService.get<APIResponse<StatusModel[]>>(
      'api/Lookups/Statuses/GetStatuses'
    );
  }
}
