<div class="yc-layout">
  <yc-panel
    [title]="'common:hdrSystemHealthDashboard' | ycI18n: 'System Health Dashboard'">
  </yc-panel>

  <div class="yc-layout-table">
    <yc-auto-table *ngIf="tableDataFactory"
      key="SYSTEM_HEALTH_DASHBOARD"
      [tableDataFactory]="tableDataFactory"
      [simple]="true"
      [hideTableFilters]="true"
      [hideTableOptions]="true"
      [hidePagination]="true">

      <!-- Health Indicator Name -->
      <ng-container *ycTableColumn="'ADMIN:textHealthIndicatorName';
        let row;
        prop: 'healthIndicatorName'">
        {{ row.healthIndicatorName }}
      </ng-container>

      <!-- Health Indicator Error Count -->
      <ng-container *ycTableColumn="'ADMIN:textHealthIndicatorErrorCount';
        let row;
        prop: 'healthIndicatorErrorCount'">
        {{ row.healthIndicatorErrorCount }}
      </ng-container>

      <!-- Health Indicator Details -->
      <ng-container *ycTableColumn="'common:textActions'
        let row;
        prop: '',
        filterType: '',
        class: 'actions',
        locked: true">
        <yc-button *ngIf="row.healthIndicatorErrorCount > 0"
          [icon]="true"
          [value]="'common:btnOptions' | ycI18n"
          [langKey]="'ADMIN:textViewHealthIndicatorDetails'"
          [defaultText]="'Errors'"
          (onClick)="viewHealthIndicatorDetails(row)">
        </yc-button>
      </ng-container>

    </yc-auto-table>
  </div>
</div>