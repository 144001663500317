import { PaymentTypes } from './finance.model';

export interface PaymentMap {
  [id: number]: PaymentDetailsApi;
}

export interface PaymentDetailsApi {
  id: number;
  amount: number;
  checkFeeDetails: CheckFeeDetails;
  checkReissueRejectionReason?: string;
  checkReissueRejectionComment?: string;
  checkReissueRejectionReasonId?: number;
  checkReissueRequestedDate: string;
  checkReissueStatusDate?: string;
  checkReissueStatusId?: number;
  companies: string[];
  currency: string;
  dateCreated: string;
  externalKey: string;
  externalSystemTypeName: string;
  grossAmount?: number; // backend calculated value from amount + fees
  hasCheckReissueRequest: boolean;
  isAch: boolean;
  isEligibleForCheckReissueRequest: boolean;
  payeeAddress1: string;
  payeeAddress2: string;
  payeeCity: string;
  payeeCountryCode: string;
  payeeName: string;
  payeePostalCode: string;
  payeeStateProvRegCode: string;
  paymentNumber: number;
  paymentStatus: string;
  paymentStatusDate: string;
  paymentSubStatus: string;
  processingPartnerName?: string;
  reissuePaymentNumber?: number;
  disbursementAmount: number;
  disbursementGrossAmount: number;
  disbursementCurrencyCode: string;
  disbursementCheckFeeDetails: CheckFeeDetails;
  paymentType: {
    id: number;
    name: string;
    description: string;
  }
}

export interface PaymentDetailsUi extends PaymentDetailsApi {
  paymentTypeId: PaymentTypes;
}

export interface TransactionsPendingResponse<T = number> {
  amountPending: T;
  transactionsPending: number;
}

export interface NonprofitPaymentResponse {
  id: number;
  amount: number;
  currency: string;
  isAch: boolean;
  externalKey: string;
  dateCreated: string;
  paymentNumber: string;
  paymentStatus: string;
  paymentStatusDate: string;
}

export interface CheckFeeDetails {
  checkFee: number;
  percentWithheld: number;
  capApplied: number;
}

export enum ExternalSystemTypeName {
  GDG = 'GDG',
  CSRconnect = 'CSRconnect',
  GrantsConnect = 'GrantsConnect',
  // this is needed for filtering when we cannot use the ID.
  // DB stores string value 'Grantconnect'
  // TODO: remove when we update DB string value
  GrantsConnectForVettingRequestReport = 'Grantconnect'
}

export enum PaymentStatus {
  Outstanding = 'Outstanding',
  Voided = 'Voided',
  Cleared = 'Cleared',
  Cancelled = 'Cancelled'
}

export enum PaymentSubStatus {
  Reissued = 'Reissued',
  Refunded = 'Refunded',
  Returned = 'Returned',
  ToBeReissued = 'To be reissued',
  Cancelled = 'Cancelled',
  // for UI only
  AltCancelledText = 'Stale'
}

export class Payment {
  public id: number;
  public amount: number;
  public paymentStatus: string;
  public dateCreated: string;
  public externalSystemTypeName: string;

  static construct (paymentAttrs: Partial<Payment>) {
    const payment = new Payment();

    Object.assign(payment, paymentAttrs);

    return payment;
  }
}
