import { Pipe, PipeTransform } from '@angular/core';
import { InflectService } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ReasonService } from '../../reasons/reasons.service';


@Pipe({
  name: 'nppNpoInactiveMessage'
})
export class NpoInactiveMessage implements PipeTransform {

  reasonDisplayText: string;
  inactiveAlertReasonText: string;
  inactiveReasons = this.reasonService.allReasonsForNpoDisplay;
  otherText = this.i18n.translate(
    'common:textOther',
    {},
    'Other'
  );
  inactiveAlertMessage = this.i18n.translate(
    'nonprofits:textNoLongerActiveMessage',
    {},
    'This organization is no longer active. The reason is listed below. If you feel this change was made in error, contact us.'
  );
  inactiveWithChaptersAlertMessage = this.i18n.translate(
    'nonprofits:textNoLongerActiveWithChaptersMessage',
    {},
    `This organization, along with all of it’s chapter organizations shown below, are no longer active. The reason is listed below. If you feel this change was made in error, contact us.`
  );

  constructor (
    private i18n: I18nService,
    public inflect: InflectService,
    private reasonService: ReasonService
  ) {}

  transform (id: number, hasChapters: boolean): string {
    const inactiveReason = (this.inactiveReasons.find((reason) => reason.id === id))?.value;
    this.reasonDisplayText = !!inactiveReason ?
      this.i18n.translate(
        `reason:text${this.inflect.pascalize(inactiveReason)}`,
        {},
        inactiveReason
      ) :
      this.otherText;
    this.inactiveAlertReasonText = this.i18n.translate(
      'nonprofits:textNoLongerActiveReason',
      {
        deactivationReason: this.reasonDisplayText
      },
      'Reason for being marked as inactive: __deactivationReason__'
    );

    return `${hasChapters ?
        this.inactiveWithChaptersAlertMessage :
        this.inactiveAlertMessage
      }<br>${this.inactiveAlertReasonText}`;
  }
}
