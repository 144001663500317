import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHttpRestService } from '@yourcause/common/http';
import { filter, lastValueFrom, map, Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class HttpRestService implements IHttpRestService {
  constructor (
    private http: HttpClient
  ) { }

  get <T = any> (url: string, headers?: any): Promise <T> {
    return this.makeRequest(url, 'get', null, headers);
  }

  post <T = any, B = any> (url: string, data: B, headers?: any): Promise<T> {
    return this.makeRequest(url, 'post', data, headers);
  }

  postFile<T = any> (
    url: string,
    file: File|Blob|string,
    paramName = 'file',
    fileName?: string
  ) {
    const formData = new FormData();
    if (typeof file === 'string') {
      file = new File([new Blob([file])], fileName || 'upload');
    }
    if (!(file instanceof File) && fileName) {
      formData.append(paramName, file, fileName);
    } else {
      formData.append(paramName, file);
    }

    return this.postFormData<T>(url, formData);
  }

  postFormData<T = any> (url: string, formData: FormData): Promise<T> {
    const request = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      headers: new HttpHeaders({ 'ngsw-bypass': 'true' }), // service worker may be impacting file uploads...
      responseType: 'text'
    });

    return lastValueFrom(this.http.request(request)
      .pipe(filter(event => event instanceof HttpResponse))
      .pipe(map(event => (<HttpResponse<T>>event).body)));
  }

  put <T = any> (url: string, data: any, headers?: any): Promise<T> {
    return this.makeRequest(url, 'put', data, headers);
  }

  delete <T = any> (url: string, headers?: any): Promise<T> {
    return this.makeRequest(url, 'delete', null, headers);
  }

  putS3PublicRead<T = any> (url: string, file: any, contentType: string): Promise<T> {
    return this.putS3(url, file, contentType, true);
  }

  putS3NoRead<T = any> (url: string, file: any, contentType: string): Promise<T> {
    return this.putS3(url, file, contentType, false);
  }

  protected makeRequest <T, B> (
    url: string,
    method: 'get'|'post'|'put'|'delete',
    data: B,
    inputHeaders?: any
  ): Promise<T> {
    let observable: Observable<T>;
    switch (method) {
      case 'get':
        observable = this.http.get<T>(url, {
          headers: inputHeaders
        });
        break;
      case 'post':
        observable = this.http.post<T>(url, data, {
          headers: inputHeaders
        });
        break;
      case 'put':
        observable = this.http.put<T>(url, data, {
          headers: inputHeaders
        });
        break;
      case 'delete':
        observable = this.http.delete<T>(url, {
          headers: inputHeaders
        });
        break;
    }

    return lastValueFrom(observable);
  }

  private putS3<T> (
    url: string,
    file: File,
    contentType: string,
    isPublicRead: boolean
  ): Promise<T> {
    return new Promise((resolve, reject) => {

      const xhr = new XMLHttpRequest();

      xhr.open('PUT', url);
      xhr.setRequestHeader('Content-Type', contentType);
      if (isPublicRead) {
        xhr.setRequestHeader('x-amz-acl', 'public-read');
      }
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.send(file);
    });
  }

  /**
   * Constructs a `GET` request that interprets the body as JSON and returns
   * the response body in a given type.
   *
   * @param url     The endpoint URL.
   *
   * @return An `Observable` of the `HttpResponse`, with a response body in the requested type.
   */
  Get <T> (url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  /**
   * Constructs a DELETE request that interprets the body as JSON and returns
   * the response in a given type.
   *
   * @param url     The endpoint URL.
   *
   * @return An `Observable` of the `HttpResponse`, with response body in the requested type.
   */
  Delete <T> (url: string, data?: any): Observable<T> {
    const options = data ? { body: data } : {};

    return this.http.delete<T>(url, options);
  }

  /**
   * Constructs a `POST` request that interprets the body as JSON
   * and returns an observable of the response.
   *
   * @param url The endpoint URL.
   * @param body The content to replace with.
   *
   * @return  An `Observable` of the `HttpResponse` for the request, with a response body in the
   * requested type.
   */
  Post <T> (url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body);
  }
}
