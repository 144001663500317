import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountDetails, RemoveUserRolesPayload } from '@core/models/account.model';
import { AccountResources } from '@core/resources/account.resources';
import { AccountState } from '@core/state/account.state';
import { APIResponse } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';


@AttachYCState(AccountState)
@Injectable({ providedIn: 'root' })
export class AccountService extends BaseYCService<AccountState> {

  constructor (
    private accountResources: AccountResources,
    private router: Router,
    private notifier: NotifierService,
    private i18n: I18nService
  ) {
    super();
  }

  get accountDetails () {
    return this.get('accountDetails');
  }

  get accessRemovedModalClosed (): boolean {
    return this.get('accessRemovedModalClosed');
  }

  setAccountDetails (response: APIResponse<AccountDetails>) {
    this.set('accountDetails', response.data);
  }

  setAccessRemovedModalClosed (closed: boolean) {
    this.set('accessRemovedModalClosed', closed);
  }

  goToMyAccount () {
    this.router.navigateByUrl('/my-account');
  }

  resetAccountState () {
    this.set('accountDetails', null);
  }

  async fetchMyAccountDetails (force = false) {
    if (!this.accountDetails || force) {
      const response = await this.accountResources.fetchMyAccountDetails();
      this.set('accountDetails', response.data);
    }
  }

  async claimANonprofit (nonprofitId: number) {
    await this.accountResources.claimANonprofit(nonprofitId);
  }

  async updateMyAccountDetails (payload: AccountDetails) {
    try {
      await this.accountResources.updateMyAccountDetails(payload);
      this.notifier.success(this.i18n.translate(
        'account:notificationUpdateAccountSuccess',
        {},
        'Account details updated'
      ));
      this.set('accountDetails', payload);
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'account:notificationUpdateAccountError',
        {},
        'Error updating account details'
      ));
    }
  }

  async deactivateAccount (currentPassword: string) {
    try {
      await this.accountResources.deactivateAccount(currentPassword);
      this.notifier.success(
        this.i18n.translate(
          'account:notificationAccountDeactivatedSuccessfully',
          {},
          'Confirmation email sent'
      ));

      return true;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'account:notificationErrorDeactivatingAccount',
        {},
        'Error deactivating account'
      ));

      return false;
    }
  }

  async getUserDeactivationFlags (userId: number) {
    try {
      const response = await this.accountResources.getUsersDeactivationFlags(userId);

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'account:notificationErrorFetchingUserDeactivationFlags',
        {},
        'Error fetching user deactivation flags'
      ));

      return null;
    }
  }

  async removeUserRoles (payload: RemoveUserRolesPayload) {
    try {
      await this.accountResources.removeUserRoles(payload);
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'account:notificationErrorRemovingUserRoles',
        {},
        'Error removing user roles'
      ));
    }
  }

  async getAssociatedNonprofitsForUser (email: string) {
    try {
      const response = await this.accountResources.getAssociatedNonprofitsForUser(
        email
      );

      return response;
    } catch (e) {
      console.error(e);

      return null;
    }
  }
}
