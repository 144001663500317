import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'npp-payment-companies-popover',
  templateUrl: './payment-companies-popover.component.html',
  styleUrls: ['./payment-companies-popover.component.scss']
})
export class PaymentCompaniesPopoverComponent implements OnChanges {
  @Input() companies: string[];
  companiesForDisplay: string[] = [];

  ngOnChanges (changes: SimpleChanges) {
    if (changes.companies && !!this.companies) {
      this.companiesForDisplay = this.companies;
    }
  }
}
