import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { FeaturedPartnerService } from "../services/featured-partner.service";

export const featuredPartnerCollateralResolver: ResolveFn<void> = async () => {
  const featuredPartnerService = inject(FeaturedPartnerService);
  await featuredPartnerService.getCurrentFeaturedPartner();
  await featuredPartnerService.getFeaturedPartnerCollateral(
    featuredPartnerService.currentFeaturedPartner.id
  );
}