export namespace BannerInformationAPI {

  export interface BannerInformationDisplay {
    id: number;
    displayPageId: number;
    bannerText: string;
    bannerComment: string;
    displayStartDate: string;
    displayEndDate: string;
    createdByUserId: number;
    createdDate: string;
    createdByUser: string;
    lastModifiedDate: string;
  }
  export interface AddBannerPayload {
    displayPageId: number;
    bannerText: string;
    bannerComment?: string;
    displayStartDate: string;
    displayEndDate: string;
  }
  export interface EditBannerPayload {
    displayPageId: number;
    id: number;
    bannerText: string;
    bannerComment?: string;
    displayStartDate: string;
    displayEndDate: string;
  }
  export enum BannerDisplayPages {
    PAYMENTS_PAGE = 1,
    HELP_SUPPORT = 4,
    MY_WORKSPACE = 5,
    NPO_ADMINISTRATION = 6,
    DISBURSEMENT_INFO = 7
  }
}

