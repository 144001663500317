import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Classification, ClassificationMaps, InflectService } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';

@Component({
  selector: 'yc-classification',
  templateUrl: './classification.component.html',
  styleUrls: ['./classification.component.scss']
})
export class ClassificationComponent implements OnInit {
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() usNonprofitSelected: boolean;
  @Input() classificationMap: ClassificationMaps;
  @Input() colSize = 12;
  @Input() disableNtee = false;
  @Input() pleaseSelectDefault = false;
  @Output() changesMade = new EventEmitter();
  ntees: Classification[] = [];
  classifications: Classification[] = [];
  allClassifications: Classification[] = [];
  nteeOptions: TypeaheadSelectOption[] = [];
  classificationOptions: TypeaheadSelectOption[] = [];
  groupSelectOptions: TypeaheadSelectOption[] = [];

  constructor (
    private i18n: I18nService,
    private inflect: InflectService
  ) { }

  ngOnInit () {
    const hierarchicalMap = this.classificationMap.hierarchicalMap;
    const childMap = this.classificationMap.childMap;
    const flatMap = this.classificationMap.flatMap;

    this.classifications = Object.keys(hierarchicalMap)
      .map((key) => hierarchicalMap[key]);

    this.classificationOptions = this.classifications.map((classification) => {
      return {
        label: this.i18n.translate(
          `nonprofits:text${this.inflect.trimPuncAndPascalize(classification.display)}`
        ),
        value: classification.value
      };
    });

    this.ntees = Object.keys(childMap).map(key => childMap[key]);
    this.nteeOptions = Object.keys(childMap).map((key) => {
      const item = childMap[key];

      return {
        label: `${item.code}: ${this.localizeDescription(item.name)}`,
        value: childMap[key]
      };
    });
    this.allClassifications = Object.keys(flatMap)
      .map(key => flatMap[key]);

    this.getDisplay = this.getDisplay.bind(this);
    this.setGroupOptions();
  }

  classificationChanged () {
    const parent = this.parentFormGroup.value.category;
    const group = this.parentFormGroup.value.group;

    this.setGroupOptions();
    if (!group || (group.parentCode !== parent.code)) {
      if (parent.children.length > 0) {
        const child = this.parentFormGroup.get('group');
        child.setValue(null);
      }
    }
  }

  setGroupOptions () {
    this.groupSelectOptions = [{
      label: this.i18n.translate(
        `nonprofits:textNoneSelected`, {},
        'None selected'
      ),
      value: null
    }].concat(
      this.parentFormGroup.value.category ?
        this.parentFormGroup.value.category.children :
        []
      );
  }

  getDisplay (ntee: Classification) {
    return ntee.code.trim() +
      ': ' +
      this.localizeDescription(ntee.name);
  }

  localizeDescription (description: string) {
    return this.i18n.translate(
      `nonprofits:text${this.inflect.trimPuncAndPascalize(description)}`,
      {},
      description
    );
  }
}
