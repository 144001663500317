import { Pipe, PipeTransform } from '@angular/core';
import { EXTERNAL_SYSTEMS } from '@core/models/donation.model';
import { VettingRequestStatusId } from '@core/models/status.model';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'nppVettingRequestStatus'
})
export class VettingRequestStatus implements PipeTransform {
  EXTERNAL_SYSTEMS = EXTERNAL_SYSTEMS;
  constructor (
    private readonly i18n: I18nService
  ) {}

  transform (
    statusId: number,
    externalSystemTypeId: number,
    vettingStatus: string
  ): string {
    let returnStatus;
    switch (statusId) {
      case VettingRequestStatusId.REQUEST_SUBMITTED:
        if (externalSystemTypeId === EXTERNAL_SYSTEMS.CSRConnect) {
          returnStatus = this.i18n.translate('statusPipe:textCSRRequestSubmitted', {}, 'CSR request submitted');
        } else if (externalSystemTypeId === EXTERNAL_SYSTEMS.GrantsConnect) {
          returnStatus = this.i18n.translate('statusPipe:textGCRequestSubmitted', {}, 'GC request submitted');
        } else if (!externalSystemTypeId) {
          returnStatus = this.i18n.translate('vetting:textInternalRequestSubmitted', {}, 'Internal request submitted');
        }
        break;
      default:
      returnStatus = this.i18n.translate(`statusPipe:textStatusId${statusId}`, {}, vettingStatus);
    }

    return returnStatus;
  }
}
