  <!-- Popover with code && desc -->
  <ng-template #popoverCodeDesc
    let-code="code"
    let-desc="desc">
    {{ code }} - {{ desc }}
  </ng-template>

  <!-- Popover with desc only -->
  <ng-template #popoverDesc
    let-desc="desc">
    {{ desc }}
  </ng-template>

<div class="details-wrapper">

  <div *ngIf="!nonprofitDetails" class="row">
    <div class="col-lg-12 message">
      <img src="./assets/img/placeholders/no-data-or-tables-to-display.svg"
        [alt]="'common:lblNoResultsFound' | ycI18n: 'No results found'"/>
      <div>
        {{ noResultsFound }}
      </div>
    </div>
  </div>

  <div *ngIf="!!nonprofitDetails" class="row pb-3">
    <!-- DISBURSEMENT INFORMATION -->
    <div class="col-md-4">
      <div class="show-more-details">
        <div class="more-details-header">
          <yc-i18n
            key="accountSetup:hdrDisbursementInformation"
            defaultValue="Disbursement Information">
          </yc-i18n>
        </div>
        <!-- HAS PROCESSING PARTNER  -->
        <ng-container *ngIf="nonprofitDetails.processingPartner">
          <dl class="dl-horizontal">
            <dt class="left-col">
              <yc-i18n
                key="nonprofits:textProcessingPartner"
                defaultValue="Processing partner">
              </yc-i18n>
            </dt>
            <dd class="right-col">
              {{ nonprofitDetails.processingPartner }}
            </dd>
          </dl>
        </ng-container>
        <!-- HAS ACH  -->
        <ng-container>
          <dl class="dl-horizontal">
            <dt class="left-col">
              <yc-i18n
                key="nonprofits:textACH"
                defaultValue="ACH">
              </yc-i18n>
            </dt>
            <dd class="left-col">
              <yc-fa
                [icon]="nonprofitDetails.hasAchInfo ?
                  'check-circle' :
                  'times-circle'"
                [class.text-success]="nonprofitDetails.hasAchInfo"
                [class.text-danger]="!nonprofitDetails.hasAchInfo">
              </yc-fa>
              <yc-i18n
                [key]="nonprofitDetails.hasAchInfo ?
                  'common:textYes' :
                  'common:textNo'">
              </yc-i18n>
            </dd>
          </dl>
        </ng-container>
        <!-- ADDRESS  -->
        <ng-container *ngIf="nonprofitDetails.processingPartner === bbgfText">
          <dl class="dl-horizontal">
            <dt class="left-col">
              <yc-i18n
                key="common:textAddress"
                defaultValue="Address">
              </yc-i18n>
            </dt>
            <dd class="left-col">
              <div>
                {{ nonprofitDetails?.remittanceInfo?.name }}
              </div>
              <div
                [innerHTML]="(nonprofitDetails?.remittanceInfo?.address | address : false : true) | escapeHtml">
              </div>
              <div *ngIf="nonprofitDetails?.remittanceInfo?.addressIsRegisteredAddress"
                class="small">
                <yc-i18n
                  key="nonprofit:textAddressIsRegisteredAddress"
                  defaultValue="NOTE: This address is the nonprofit's registered address">
                </yc-i18n>
              </div>
            </dd>
          </dl>
        </ng-container>
      </div>
    </div>

    <!-- REGISTRATION AUTHORITY ATTRS -->
    <div class="col-md-4">
      <div class="show-more-details">
        <div class="more-details-header">
          <yc-i18n
            key="nonprofits:textRegistrationAuthorityAttributes"
            defaultValue="Registration Authority Attributes">
          </yc-i18n>
        </div>

        <!-- FOR SPECIAL CASES - HDS & CT  -->
        <ng-container *ngIf="regAuthIdsExcluded.includes(nonprofitDetails.registrationAuthorityId)">
          <!-- CT - Reference Code  -->
          <dl *ngIf="nonprofitDetails | nppRegAuthAttrs:
            RegAuthAttrDefaultValue.Reference_Code :
              false :
              false"
              class="dl-horizontal">
            <dt class="left-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Reference_Code :
                  false :
                  false
              }}
            </dt>
            <dd class="left-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Reference_Code :
                  false :
                  true
              }}
            </dd>
          </dl>
          <!-- TS ID  -->
          <dl *ngIf="nonprofitDetails | nppRegAuthAttrs:
            RegAuthAttrDefaultValue.TS_ID :
              false :
              false"
              class="dl-horizontal">
            <dt class="right-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.TS_ID :
                  false :
                  false :
                  false
              }}
            </dt>
            <dd class="right-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.TS_ID :
                  false :
                  true
              }}
            </dd>
          </dl>
          <!-- ACTIVE ON TS  -->
          <dl *ngIf="nonprofitDetails | nppRegAuthAttrs:
            RegAuthAttrDefaultValue.Active_TS :
              false :
              false"
              class="dl-horizontal">
            <dt class="right-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Active_TS :
                  false :
                  false
              }}
            </dt>
            <dd class="right-col">
              <yc-fa
              [icon]="(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Active_TS :
                  true :
                  false) ?
                  'check-circle' :
                  'times-circle'"
              [class.text-success]="(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Active_TS :
                  true :
                  false)"
              [class.text-danger]="!(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Active_TS :
                  true :
                  false)">
            </yc-fa>
            <yc-i18n
              [key]="(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Active_TS :
                  true :
                  false) ?
                  'common:textYes' :
                  'common:textNo'">
            </yc-i18n>
            </dd>
          </dl>
          <!-- FP ID  -->
          <dl *ngIf="nonprofitDetails | nppRegAuthAttrs:
            RegAuthAttrDefaultValue.FP_ID :
              false :
              false"
              class="dl-horizontal">
            <dt class="right-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.FP_ID :
                  false :
                  false :
                  false
              }}
            </dt>
            <dd class="right-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.FP_ID :
                  false :
                  true
              }}
            </dd>
          </dl>
          <!-- ACTIVE ON FP  -->
          <dl *ngIf="nonprofitDetails | nppRegAuthAttrs:
            RegAuthAttrDefaultValue.Active_FP :
              false :
              false"
              class="dl-horizontal">
            <dt class="right-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Active_FP :
                  false :
                  false
              }}
            </dt>
            <dd class="right-col">
              <yc-fa
              [icon]="(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Active_FP :
                  true :
                  false) ?
                  'check-circle' :
                  'times-circle'"
              [class.text-success]="(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Active_FP :
                  true :
                  false)"
              [class.text-danger]="!(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Active_FP :
                  true :
                  false)">
            </yc-fa>
            <yc-i18n
              [key]="(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Active_TS :
                  true :
                  false) ?
                  'common:textYes' :
                  'common:textNo'">
            </yc-i18n>
            </dd>
          </dl>
        </ng-container>

        <!-- ALL OTHER CASES  -->
        <ng-container *ngIf="!(regAuthIdsExcluded.includes(nonprofitDetails.registrationAuthorityId))">
          <!-- ON IRS BMF -->
          <dl *ngIf="nonprofitDetails | nppRegAuthAttrs:
            RegAuthAttrDefaultValue.On_IRS_BMF :
              false :
              false"
            class="dl-horizontal">
          <dt class="middle-col">
            {{ nonprofitDetails | nppRegAuthAttrs:
              RegAuthAttrDefaultValue.On_IRS_BMF :
                false :
                false
            }}
            <button *ngIf="nonprofitDetails | nppRegAuthAttrs:
              RegAuthAttrDefaultValue.On_IRS_BMF :
                false :
                false"
              class="popover-link"
              [attr.aria-label]="'common:btnInfo' | ycI18n"
              [popover]="popoverDesc"
              [popoverContext]="{
                desc: onBmfPopover
              }"
              [outsideClick]="true"
              [placement]="'right'">
              <yc-fa class="text-link ms-2 icon-popover"
                icon="info-circle"
                [light]="true">
              </yc-fa>
            </button>
          </dt>
          <dd class="middle-col">
            <yc-fa
              [icon]="(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.On_IRS_BMF :
                  true :
                  false) ?
                  'check-circle' :
                  'times-circle'"
              [class.text-success]="(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.On_IRS_BMF :
                  true :
                  false)"
              [class.text-danger]="!(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.On_IRS_BMF :
                  true :
                  false)">
            </yc-fa>
            <yc-i18n
              [key]="(nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.On_IRS_BMF :
                  true :
                  false) ?
                  'common:textYes' :
                  'common:textNo'">
            </yc-i18n>
          </dd>
        </dl>
        <!-- DEDUCTIBILITY CODE -->
        <dl *ngIf="nonprofitDetails | nppRegAuthAttrs:
          RegAuthAttrDefaultValue.BMF_Deductibility_code :
            false :
            false"
          class="dl-horizontal">
          <dt class="middle-col">
            {{ nonprofitDetails | nppRegAuthAttrs:
              RegAuthAttrDefaultValue.BMF_Deductibility_code :
                false :
                false
            }}
            <button class="popover-link"
              [attr.aria-label]="'common:btnInfo' | ycI18n"
              [popover]="popoverCodeDesc"
              [popoverContext]="{
                code: (nonprofitDetails | nppRegAuthAttrs:
                  RegAuthAttrDefaultValue.BMF_Deductibility_code :
                    false :
                    true),
                desc: (nonprofitDetails | nppRegAuthAttrs:
                  RegAuthAttrDefaultValue.DeductibilityCodeDesc :
                    false :
                    true)
              }"
              [outsideClick]="true"
              [placement]="'right'">
              <yc-fa class="text-link ms-2 icon-popover"
                icon="info-circle"
                [light]="true">
              </yc-fa>
            </button>
          </dt>
          <dd class="middle-col">
            {{ nonprofitDetails | nppRegAuthAttrs:
              RegAuthAttrDefaultValue.BMF_Deductibility_code :
                false:
                true
            }}
          </dd>
        </dl>
        <!-- BMF SUBSECTION -->
          <dl *ngIf="nonprofitDetails | nppRegAuthAttrs:
            RegAuthAttrDefaultValue.BMF_Subsection_code :
              false :
              false"
            class="dl-horizontal">
            <dt class="middle-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.BMF_Subsection_code :
                  false :
                  false
              }}
              <button class="popover-link"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                (click)="openIrsCodesModal(CodeType.BMFSubsection)">
                <yc-fa class="text-link ms-2 icon-popover"
                  icon="info-circle"
                  [light]="true">
                </yc-fa>
              </button>
            </dt>
            <dd class="middle-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.BMF_Subsection_code :
                  false:
                  true
              }}
            </dd>
          </dl>

          <!-- BMF FOUNDATION -->
          <dl *ngIf="nonprofitDetails | nppRegAuthAttrs:
              RegAuthAttrDefaultValue.BMF_Foundation_code :
                false :
                false"
            class="dl-horizontal">
             <dt class="middle-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.BMF_Foundation_code :
                  false :
                  false
              }}
              <button class="popover-link"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                (click)="openIrsCodesModal(CodeType.BMFFoundation)">
                <yc-fa class="text-link ms-2 icon-popover"
                  icon="info-circle"
                  [light]="true">
                </yc-fa>
              </button>
            </dt>
            <dd class="middle-col">
              <button class="popover-link"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                [popover]="popoverCodeDesc"
                [popoverContext]="{
                  code: (nonprofitDetails | nppRegAuthAttrs:
                    RegAuthAttrDefaultValue.BMF_Foundation_code :
                      false :
                      true),
                  desc: (nonprofitDetails | nppRegAuthAttrs:
                    RegAuthAttrDefaultValue.FoundationCodeDesc :
                      false :
                      true)
                }"
                [outsideClick]="true"
                [placement]="'right'">
                <span class="text-link">
                  {{ nonprofitDetails | nppRegAuthAttrs:
                    RegAuthAttrDefaultValue.BMF_Foundation_code :
                      false:
                      true
                  }}
                </span>
              </button>
            </dd>
          </dl>
          <!-- ON PUB 78 -->
          <dl *ngIf="nonprofitDetails | nppRegAuthAttrs:
            RegAuthAttrDefaultValue.On_IRS_Pub_78 :
              false :
              false"
            class="dl-horizontal">
            <dt class="middle-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.On_IRS_Pub_78 :
                  false :
                  false
              }}
              <button *ngIf="nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.On_IRS_Pub_78 :
                  false :
                  false"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                class="popover-link"
                [popover]="popoverDesc"
                [popoverContext]="{
                  desc: onPub78Popover
                }"
                [outsideClick]="true"
                [placement]="'right'">
                <yc-fa class="text-link ms-2 icon-popover"
                  icon="info-circle"
                  [light]="true">
                </yc-fa>
              </button>
            </dt>
            <dd class="middle-col">
              <yc-fa
                [icon]="(nonprofitDetails | nppRegAuthAttrs:
                  RegAuthAttrDefaultValue.On_IRS_Pub_78 :
                    true :
                    false) ?
                    'check-circle' :
                    'times-circle'"
                [class.text-success]="(nonprofitDetails | nppRegAuthAttrs:
                  RegAuthAttrDefaultValue.On_IRS_Pub_78 :
                    true :
                    false)"
                [class.text-danger]="!(nonprofitDetails | nppRegAuthAttrs:
                  RegAuthAttrDefaultValue.On_IRS_Pub_78 :
                    true :
                    false)">
              </yc-fa>
              <yc-i18n
                [key]="(nonprofitDetails | nppRegAuthAttrs:
                  RegAuthAttrDefaultValue.On_IRS_Pub_78 :
                    true :
                    false) ?
                    'common:textYes' :
                    'common:textNo'">
              </yc-i18n>
            </dd>
          </dl>
          <!-- PUB 78 DESIGNATION CODE -->
          <dl *ngIf="nonprofitDetails | nppRegAuthAttrs:
            RegAuthAttrDefaultValue.Pub78_Designation_code :
            false :
            false"
            class="dl-horizontal">
            <dt class="middle-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.Pub78_Designation_code :
                  false :
                  false
              }}
              <button class="popover-link"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                (click)="openIrsCodesModal(CodeType.Pub78Designation)">
                <yc-fa class="text-link ms-2 icon-popover"
                  icon="info-circle"
                  [light]="true">
                </yc-fa>
              </button>
            </dt>
            <dd class="middle-col">
              <button class="popover-link"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                [popover]="popoverCodeDesc"
                [popoverContext]="{
                  code: (nonprofitDetails | nppRegAuthAttrs:
                    RegAuthAttrDefaultValue.ExemptStatusCode :
                      false :
                      true),
                  desc: (nonprofitDetails | nppRegAuthAttrs:
                    RegAuthAttrDefaultValue.PubExemptStatusCodeDesc:
                      false :
                      true)
                }"
                [outsideClick]="true"
                [placement]="'right'">
                <span class="text-link">
                  {{ nonprofitDetails | nppRegAuthAttrs:
                    RegAuthAttrDefaultValue.Pub78_Designation_code :
                      false:
                      true
                  }}
                </span>
              </button>
            </dd>
          </dl>
          <!-- NTEE/CLASSIFICATION- KEEP FORMAT -->
          <dl *ngIf="!!nonprofitDetails.nonprofitClassification"
            class="dl-horizontal">
            <dt class="middle-col">
              <yc-i18n
                key="nonprofit:textNonprofitClassificationNTEE"
                defaultValue="Nonprofit classification (NTEE)">
              </yc-i18n>
            </dt>
            <dd class="middle-col">
              {{ nonprofitDetails.nonprofitClassification }}
            </dd>
          </dl>
          <!-- REVOCATION DATE  -->
          <dl *ngIf="showRevocationDate"
            class="dl-horizontal">
            <dt class="middle-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.IRS_Revocation_Date :
                  false :
                  false
              }}
              <button class="popover-link"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                [popover]="popoverDesc"
                [popoverContext]="{
                  desc: irsRevocationPopover
                }"
                [outsideClick]="true"
                [placement]="'auto'">
                <yc-fa class="text-link ms-2 icon-popover"
                  icon="info-circle"
                  [light]="true">
                </yc-fa>
              </button>
            </dt>
            <dd class="middle-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.IRS_Revocation_Date :
                  false:
                  true
              }}
            </dd>
          </dl>
          <!-- REVOCATION STATES  -->
          <dl *ngIf="showRevocationDate"
            class="dl-horizontal">
            <dt class="middle-col">
              {{ nonprofitDetails | nppRegAuthAttrs:
                RegAuthAttrDefaultValue.US_State_Revocations :
                  false :
                  false
              }}
              <button class="popover-link"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                [popover]="popoverDesc"
                [popoverContext]="{
                  desc: usStatRevocationsPopover
                }"
                [outsideClick]="true"
                [placement]="'auto'">
                <yc-fa class="text-link ms-2 icon-popover"
                  icon="info-circle"
                  [light]="true">
                </yc-fa>
              </button>
            </dt>
            <dd class="middle-col">
              <button class="popover-link"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                (click)="openRevocationDetailsByStateModal()">
                <span class="text-link">
                  {{ nonprofitDetails | nppRegAuthAttrs:
                    RegAuthAttrDefaultValue.US_State_Revocations :
                      false:
                      true
                  }}
                </span>
              </button>
            </dd>
          </dl>
          <!-- REG AUTH ATTR MODAL LINK  -->
          <dl *ngIf="showRevocationDate"
            class="dl-horizontal">
            <dt class="middle-col">
              <yc-i18n
                key="nonprofit:textAttributeHistory"
                defaultValue="Attribute History">
              </yc-i18n>
            </dt>
            <dd class="middle-col">
              <button class="popover-link"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                (click)="openIrsAttributionModal()">
                <span class="text-link">
                  <yc-i18n
                    key="nonprofit:linkViewHistory"
                    defaultValue="View history">
                  </yc-i18n>
                </span>
              </button>
            </dd>
          </dl>
        </ng-container>
      </div>
    </div>

    <!-- ADDITIONAL INFORMATION -->
    <div class="col-md-4">
      <div class="show-more-details">
        <div class="more-details-header">
          <yc-i18n
            key="common:hdrAdditionalInformation"
            defaultValue="Additional Information">
          </yc-i18n>
        </div>
        <ng-container>
          <!-- ACTIVE ADMINS  -->
          <dl class="dl-horizontal">
            <dt class="middle-col">
              <yc-i18n
                key="nonprofits:textActiveAdministratorsNumber"
                defaultValue="Active administrators">
              </yc-i18n>
            </dt>
            <dd class="middle-col">
              <a *ngIf="numOfAdmins > 0"
                href="javascript:void(0)"
                class="text-info"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                (click)="openActiveAdminsModal(activeUsers)">
                {{ numOfAdmins }}
              </a>
              <span *ngIf="numOfAdmins === 0">
                {{ numOfAdmins }}
              </span>
            </dd>
            <!-- ACTIVE VETTING  -->
            <dt class="middle-col">
              <yc-i18n
                key="nonprofits:textActiveVetting"
                defaultValue="Active vetting">
              </yc-i18n>
            </dt>
            <dd class="middle-col">
              {{ nonprofitDetails.activeVetting | nppActiveVetting : nonprofitDetails.vettingExpirationDate}}
            </dd>
            <!-- ACTIVE COMPLIANCE  -->
            <dt class="middle-col">
              <yc-i18n
                key="nonprofits:textActiveCompliance"
                defaultValue="Active compliance">
              </yc-i18n>
            </dt>
            <dd class="middle-col">
              <a *ngIf="canImpersonateNonprofit"
                href="javascript:void(0)"
                class="text-info"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                [tooltip]="goToCompliance"
                [tooltipPlacement]="'top'"
                (click)="routeToCompliance(nonprofitId)">
                {{ nonprofitDetails.activeCompliances }}
              </a>
              <span *ngIf="!canImpersonateNonprofit">
                {{ nonprofitDetails.activeCompliances }}
              </span>
            </dd>
            <!-- NOTES  -->
            <dt class="middle-col">
              <yc-i18n
                key="nonprofits:textNotesLink"
                defaultValue="Notes:">
              </yc-i18n>
            </dt>
            <dd class="middle-col">
              <a *ngIf="canImpersonateNonprofit &&
                notesCount > 0"
                href="javascript:void(0)"
                class="text-info"
                [attr.aria-label]="'common:btnInfo' | ycI18n"
                [tooltip]="goToNotes"
                [tooltipPlacement]="'top'"
                (click)="routeToNotes(nonprofitId)">
                {{ notesCount }}
              </a>
              <span *ngIf="!canImpersonateNonprofit ||
                notesCount < 1">
                {{ notesCount }}
              </span>
            </dd>
          </dl>
        </ng-container>
      </div>
    </div>
  </div>
</div>