import { Pipe, PipeTransform } from '@angular/core';
import { EligibilityStatus } from '@core/models/status.model';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'nppEligibleForGivingStatusIdPipe'
})
export class EligibleForGivingStatusIdPipe implements PipeTransform {
  constructor (
    private i18n: I18nService
  ) {}

  transform (eligibleForGivingStatusId: number): string {
    switch (eligibleForGivingStatusId) {
      case EligibilityStatus.AwaitingReview:
        return this.i18n.translate(
          'common:lblVettingInProgress',
          {},
          'Vetting in Progress'
        );
      case EligibilityStatus.YesEligible:
        return this.i18n.translate(
          'common:textYes',
          {},
          'Yes'
        );
      case EligibilityStatus.NeverEligible:
        return this.i18n.translate(
          'common:textNo',
          {},
          'No'
        );
      default:
        case EligibilityStatus.MaybeEligible:
          return this.i18n.translate(
            'common:textNeedsVetting',
            {},
            'Needs Vetting'
          );
    };
  }
}
