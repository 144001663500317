import { GenericStorage, urlStorage } from './UrlStorage';
type Fn = Function;
export enum CACHE_TYPES {
  URL,
  SESSION,
  LOCALSTORAGE
}

export function getStorage (cacheType: CACHE_TYPES) {
  let storage: GenericStorage;

  switch (cacheType) {
    case CACHE_TYPES.URL:
      storage = urlStorage;
      break;
    case CACHE_TYPES.LOCALSTORAGE:
      storage = localStorage;
      break;
    case CACHE_TYPES.SESSION:
      storage = sessionStorage;
      break;
  }

  return storage;
}

// so that we are passing by reference on page load
const internalCacheMap: { [x: string]: any } = {};
const counter: { [x: string]: number } = {};
export function CachedAttr<T = any> (cacheType: CACHE_TYPES, defaultValue?: T, postInit?: (t: T) => T, duration = Infinity): any {
  return (target: Fn, attribute: string, descriptor: PropertyDescriptor) => {
    let isInitted = false;
    if (!postInit) {
      postInit = t => t;
    }

    const storage = getStorage(cacheType);

    counter[target.constructor.name + attribute] = counter[target.constructor.name + attribute] || 0;
    const cacheKey = `yc-cache-${++counter[target.constructor.name + attribute]}-${target.constructor
      .name}-${attribute}`;
    const durationKey = `${cacheKey}-set-time`;

    const lastDate = +storage.getItem(durationKey);

    if ((Date.now() < (lastDate + duration)) || (duration === Infinity)) {
      internalCacheMap[cacheKey] = JSON.parse(storage.getItem(cacheKey));

      if (internalCacheMap[cacheKey] === null) {
        internalCacheMap[cacheKey] = undefined;
      }

      if (
        (typeof(defaultValue) !== 'undefined') &&
        (typeof(internalCacheMap[cacheKey]) === 'undefined')
      ) {
        internalCacheMap[cacheKey] = defaultValue;
        storage.setItem(cacheKey, JSON.stringify(defaultValue));
        if (duration !== Infinity) {
          storage.setItem(durationKey, `${Date.now()}`);
        }
      }
    }

    const initializer = (value: T) => {
      isInitted = true;

      internalCacheMap[cacheKey] = postInit(value);
    };

    Object.defineProperty(target, attribute, {
      get () {
        if (!isInitted) {
          initializer(internalCacheMap[cacheKey]);
        }

        return internalCacheMap[cacheKey];
      },
      set (value) {
        internalCacheMap[cacheKey] = value;
        if (duration !== Infinity) {
          storage.setItem(durationKey, `${Date.now()}`);
        }
        storage.setItem(cacheKey, JSON.stringify(value));
      }
    });
  };
}

