import { Injectable } from '@angular/core';
import { TokenService } from './token/token.service';

@Injectable({ providedIn: 'root' })
export class SessionKeepAliveService {

  constructor (
    private tokenService: TokenService
  ) { }

  private refreshing: boolean;
  private listener: () => void;

  // start watching for clicks and keypresses
  startWatching () {
    // store the listener so that we can remove it when "stopWatching" happens
    this.listener = async () => {
      // only refresh one time and if the token (not refresh token) has expired
      if (
        !this.refreshing &&
        !this.tokenService.hasCurrentValidToken()
      ) {
        // toggle refreshing back and forth to ensure one call
        this.refreshing = true;
        await this.tokenService.getLatestToken();
        this.refreshing = false;
      }
    };

    // watch for mouse clicks and keyboard keypresses
    document.addEventListener('click', this.listener);
    document.addEventListener('keypress', this.listener);
  }

  // unregister listeners if we are listening
  stopWatching () {
    if (this.listener) {
      document.removeEventListener('click', this.listener);
      document.removeEventListener('keypress', this.listener);

      // delete reference to old listener
      this.listener = null;
    }
  }
}
