import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeType } from '@core/models/nonprofit-irs-code.model';
import { RegAuthAttrDefaultValue, RegAuthIdsExcluded, SearchMoreDetails } from '@core/models/npo.model';
import { NonprofitUserApi } from '@core/models/users.model';
import { AuthService } from '@core/services/auth.service';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory } from '@yourcause/common/modals';
import { NonprofitService } from '../../../nonprofit/nonprofit.service';
import { NotesService } from '../../../notes/notes.service';
import { ReportingService } from '../../../reporting/services/reporting.service';
import { ActiveAdminModalComponent } from '../../../search/active-admin-modal/active-admin-modal.component';
import { NonprofitIRSCodesModalComponent } from '../nonprofit-irs-codes-modal/nonprofit-irs-codes-modal.component';
import { RegistrationAuthorityAttrModalComponent } from '../registration-authority-attr-modal/registration-authority-attr-modal.component';
import { RevocationDetailsByStateModalComponent } from '../revocation-details-by-state-modal/revocation-details-by-state-modal.component';

@Component({
  selector: 'yc-extended-search-details',
  templateUrl: './extended-search-details.component.html',
  styleUrls: ['./extended-search-details.component.scss']
})
export class ExtendedSearchDetailsComponent implements OnInit {

  @Input() nonprofitDetails: SearchMoreDetails;
  @Input() nonprofitName: string;
  @Input() nonprofitId: number;
  @Input() registrationAuthority: string;

  CodeType = CodeType;
  activeUsers: NonprofitUserApi[];
  notesCount: number;
  numOfAdmins: number;
  showRevocationDate: boolean;
  showUsRevocation: boolean;
  RegAuthAttrDefaultValue = RegAuthAttrDefaultValue;
  bbgfText = 'BBGF';
  regAuthIdsExcluded = RegAuthIdsExcluded;

  noResultsFound = this.i18n.translate(
    'common:lblNoResultsFound',
    {},
    'No results found'
  );
  allowsGivingPopover = this.i18n.translate(
    'nonprofits:popAllowsGivingDesc',
    {},
    'A setting made by the Nonprofit to indicate whether they allow donations to be made to them. (Not currently respected by CSRconnect).'
  );
  allowsVolunteerPopover = this.i18n.translate(
    'nonprofits:popAllowsVolunteerDesc',
    {},
    'A setting made by the Nonprofit to indicate whether they allow volunteer events at their organization. (Not currently respected by CSRconnect).'
  );
  onBmfPopover = this.i18n.translate(
    'nonprofits:popOnBmfDesc',
    {},
    'This organization is currently on the IRS Business Master File.'
  );
  onPub78Popover = this.i18n.translate(
    'nonprofits:popOnPub78Desc',
    {},
    'This organization is currently on the IRS Pub78 file.'
  );
  irsRevocationPopover = this.i18n.translate(
    'nonprofits:popIrsRevocationDate',
    {},
    'This is the date the IRS revoked the deductibility from this organization based on the organization not providing the required annual filings for 3 consecutive years.'
  );
  usStatRevocationsPopover = this.i18n.translate(
    'nonprofits:popUsStatRevocations',
    {},
    'This shows any States in which this organization is currently revoked and not eligible for giving to residents of that state.'
  );
  goToCompliance = this.i18n.translate(
    'nonprofits:textGoToCompliancePage',
    {},
    'Go to compliance page'
  );
  goToNotes = this.i18n.translate(
    'nonprofits:textGoToNotesPage',
    {},
    'Go to notes page'
  );
  notApplicableText = this.i18n.translate(
    'common:textNotApplicableNA',
    {},
    'N/A'
  );

  constructor (
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private i18n: I18nService,
    private modalFactory: ModalFactory,
    private nonprofitService: NonprofitService,
    private notesService: NotesService,
    private authService: AuthService,
    private reportingService: ReportingService
  ) { }

  ngOnInit () {
    this.activeUsers = this.nonprofitDetails?.activeUsers;
    this.notesCount = this.nonprofitDetails?.notesCount;
    this.numOfAdmins = this.activeUsers?.length > 0 ?
      this.activeUsers.length :
      0;
    this.showRevocationDate = [
      'US Nonprofit',
      'US Nonprofit (Client-based Community Foundation)'
    ].includes(this.registrationAuthority);
    const revocationsStatesExist = this.nonprofitDetails?.registrationAuthorityAttributes?.find((attr) => {
      if (attr?.i18nDefaultValue === RegAuthAttrDefaultValue.US_State_Revocations &&
        attr?.value !== ''
      ) {
          return true;
        } else {
          return false;
        }
    });
    this.showUsRevocation = !!revocationsStatesExist;
  }

  async openActiveAdminsModal (activeUsers: NonprofitUserApi[]) {
    const modalResponse = await this.modalFactory.open(
      ActiveAdminModalComponent,
      {
        activeUsers
      }
    );
    if (!!modalResponse) {
      this.reportingService.setAdminEmail(modalResponse);
        this.router.navigate(['/reporting/email-log-report'], {
        relativeTo: this.activatedRoute
      });
    }
  }

  get canImpersonateNonprofit () {
    return this.authService.canImpersonateNonprofit();
  }

  async openIrsCodesModal (
    codeType: CodeType
  ) {
    const codes = await this.nonprofitService.getNonprofitIrsCodes(codeType);
    await this.modalFactory.open(
      NonprofitIRSCodesModalComponent,
      {
        codes,
        codeType
      },
      {
        class: 'modal-xl'
      }
    );
  }

  async openRevocationDetailsByStateModal (stateCode?: string) {
    await this.modalFactory.open(
      RevocationDetailsByStateModalComponent,
      {
        nonprofitId: this.nonprofitId,
        nonprofitName: this.nonprofitName,
        stateCode
      },
      {
        class: 'modal-xl'
      }
    );
  }

  async openIrsAttributionModal () {
    await this.modalFactory.open(
      RegistrationAuthorityAttrModalComponent,
      {
        nonprofitId: this.nonprofitId,
        nonprofitName: this.nonprofitName
      },
      {
        class: 'modal-xl'
      }
    );
  }

  async routeToNotes (nonprofitId: number) {
    await this.nonprofitService.setSelectedNpo(nonprofitId);
    this.notesService.setNoteCount(this.notesCount);
    this.router.navigateByUrl('notes');
  }

  async routeToCompliance (nonprofitId: number) {
    await this.nonprofitService.setSelectedNpo(nonprofitId);
    this.router.navigateByUrl('account-setup/compliance');
  }
}
