import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { NotesAPI } from '@core/typings/api/notes.typing';
import { APIResult, PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class NotesResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  fetchNotesForTable (
    paginationOptions: PaginationOptions<NotesAPI.NoteDisplay>
  ) {
    return this.httpRestService.post<APIResult<NotesAPI.NoteDisplay>>(
      'api/NonprofitNotes/GetNonprofitNotes',
      {
        paginationOptions
      }
    );
  }

  addNoteByNonprofitId (
    payload: NotesAPI.NoteBase,
    nonprofitId?: number
  ) {
    return this.httpRestService.post(
      `api/NonprofitNotes/AddNoteByNonprofitId?nonprofitId=${nonprofitId}`,
      payload
    );
  }

  editNote (payload: NotesAPI.NoteDisplay) {
    return this.httpRestService.post(
      'api/NonprofitNotes/UpdateNote',
      payload
    );
  }

  deleteNote (id: number) {
    return this.httpRestService.post(
      `api/NonprofitNotes/DeleteNote?nonprofitNoteId=${id}`,
      {}
    );
  }
}
