import { Notification } from '@core/models/notifications.model';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class NotificationsState extends BaseYcState {
  readonly notifications: Notification[];
  readonly allNotifications: Notification[];
  readonly modalNotification: Notification;
  readonly modalNotificationClosed: boolean;
  readonly activeNonprofitId: number;
  readonly keyedNotifications: {
    instance: Notification[];
    key: string | number;
  }[];
}

