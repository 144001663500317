
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { ReasonModel, ReasonObjectUI } from '../_core/models/reason.model';

@RegisterYCState()
export class ReasonState extends BaseYcState {
  readonly allReasonsLookup: ReasonModel[];
  readonly allReasonsVisibleToNPO: ReasonModel[];
  readonly allReasonsVisibleToYCAdmin: ReasonModel[];
  readonly allNonSystemReasons: ReasonModel[];
  readonly allReasonsForNpoDisplayMap: ReasonObjectUI[];
  readonly allReasonsForYcAdminDisplayMap: ReasonObjectUI[];
  readonly deactivationReasonsSelect: ReasonObjectUI[];
  readonly checkReissueRequestReasonsSelect: ReasonObjectUI[];
  readonly vettingRequestRejectionReasonsSelect: ReasonObjectUI[];
  readonly disbursementSuspendedReasonsSelect: ReasonObjectUI[];
  readonly achInfoRejectionReasonsSelect: ReasonObjectUI[];
  readonly bbgfEligibleReasonSelect: ReasonObjectUI[];
  readonly bbgfIneligibleReasonSelect: ReasonObjectUI[];
  readonly markAsFalsePositiveSelect: ReasonObjectUI[];
  readonly markAsTrueMatchSelect: ReasonObjectUI[];
  readonly declineChapterRequestReasonsSelect: ReasonObjectUI[];
  readonly prospectiveAdminOutreachReasonSelect: ReasonObjectUI[];
  readonly prospectiveAdminOptOutReasonSelect: ReasonObjectUI[];
  readonly prospectiveAdminOutreachFilterReasons: ReasonObjectUI[];
}
