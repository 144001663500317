import { Injectable } from '@angular/core';
import { Country, CountryCurrencyMap, RegionsByCountry, StateProvReg } from '@core/models/location.model';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { LocationResources } from './location.resources';
import { LocationState } from './location.state';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { ArrayHelpersService } from '@yourcause/common/utils';

@AttachYCState(LocationState)
@Injectable({ providedIn: 'root' })
export class LocationService extends BaseYCService<LocationState> {
  constructor (
    private locationResources: LocationResources,
    private arrayHelper: ArrayHelpersService
  ) {
    super();
  }

  get countries (): Country[] {
    return this.get('countries');
  }

  get regions (): StateProvReg[] {
    return this.get('regions');
  }

  get countriesForSelect (): TypeaheadSelectOption[] {
    return this.get('countriesForSelect');
  }

  get regionsForSelect (): TypeaheadSelectOption[] {
    return this.get('regionsForSelect');
  }

  get regionsByCountry (): RegionsByCountry {
    return this.get('regionsByCountry');
  }

  get countryCurrencyMap (): CountryCurrencyMap {
    return this.get('countryCurrencyMap');
  }

  async setCountries () {
    const countries = await this.fetchCountries();
    this.set('countries', countries);
    this.setCountriesForSelect(countries);
  }

  async setRegions (countryCode: string) {
    const regions = await this.fetchRegions(countryCode);
    this.set('regions', regions);
    this.set('regionsByCountry', {
      countryCode,
      regions
    });
    this.setRegionsForSelect(regions);
  }

  setCountriesForSelect (countries: Country[]) {
    const formattedCountriesForSelect = this.formatCountriesForSelect(
      countries
    );
    this.set('countriesForSelect', formattedCountriesForSelect);
  }

  setRegionsForSelect (regions: StateProvReg[]) {
    const formattedRegionsForSelect = this.formatRegionsForSelect(
      regions
    );
    this.set('regionsForSelect', formattedRegionsForSelect);
  }

  async setCountryCurrencyMap (countryCode: string) {
    const currency = await this.fetchCurrencyByCountry(countryCode);
    const newCurrencyMap: { [x: string]: string } = {};
    currency?.countries?.forEach((country: Country) => {
      newCurrencyMap[country.countryCode] = currency.currencyCode;
    });
    this.set('countryCurrencyMap', newCurrencyMap);
  }

  formatCountriesForSelect (
    countries: Country[]
  ): TypeaheadSelectOption[] {
    return this.arrayHelper.sort(countries.map(country => ({
      label: country.countryName,
      value: country.countryCode
    })), 'label');
  }

  formatRegionsForSelect (
    regions: StateProvReg[]
  ): TypeaheadSelectOption[] {
    return this.arrayHelper.sort(regions.map(region => ({
      label: region.stateProvRegName,
      value: region.id
    })), 'label');
  }

  async fetchCountries () {
    const response = await this.locationResources.fetchCountries();

    return response;
  }

  async fetchRegions (countryCode: string) {
    const response = await this.locationResources.fetchRegionsByCountry(countryCode);

    return response;
  }

  async fetchCurrencyByCountry (countryCode: string) {
    const response = await this.locationResources.fetchCurrencyByCountry(countryCode);

    return response;
  }
}
