import { Directive, TemplateRef, ViewContainerRef, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[ycUnlessNonprofitRole]'
})
export class UnlessNonprofitRoleDirective implements OnInit {
  @Input() ycUnlessNonprofitRole: string;
  constructor (
    public templateRef: TemplateRef<any>,
    public viewRef: ViewContainerRef,
    public roleService: AuthService
  ) { }

  ngOnInit () {
    if (!this.roleService.hasNpoRole(this.ycUnlessNonprofitRole)) {
      this.viewRef.createEmbeddedView(this.templateRef, {}, 0);
    }
  }

}
