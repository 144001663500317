import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountDetails, DeactivationFlagsResponse, RemoveUserRolesPayload, UserLoginClaims } from '@core/models/account.model';
import { NonprofitBlockDisplayModel } from '@core/models/npo.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class AccountResources {

  constructor (
    private http: HttpClient,
    private httpRestService: HttpRestService
  ) {
    this.http;
  }

  fetchMyAccountDetails (): Promise<APIResponse<AccountDetails>> {
    const endpoint = '/api/MyAccount/GetAccountDetail';
    const response = this.httpRestService.get<APIResponse<AccountDetails>>(
      endpoint
    );

    return response;
  }

  claimANonprofit (nonprofitId: number):
  Promise<APIResponse<boolean>> {
    const endpoint = '/api/User/ClaimANonprofit';
    const response = this.httpRestService.post<APIResponse<boolean>>(
      endpoint,
      {
        nonprofitId
      }
    );

    return response;
  }

  updateMyAccountDetails (payload: AccountDetails):
  Promise<APIResponse<AccountDetails>> {
    const endpoint = '/api/MyAccount/UpdateAccountDetail';
    const response = this.httpRestService.post<APIResponse<AccountDetails>>(
      endpoint,
      payload
    );

    return response;
  }

  deactivateAccount (currentPassword: string) {
    const endpoint = 'api/Account/Manage/DeactivateAccount';
    const response =  this.httpRestService.post(
      endpoint,
      {
        clearPersonalData: true,
        currentPassword
      }
    );

    return response;
  }

  getUsersDeactivationFlags (userId: number) {
    const payload = {
      userId
    };
    const endpoint = 'api/User/GetUsersDeactivationFlags';
    const response = this.httpRestService.post<APIResponse<DeactivationFlagsResponse>>(
      endpoint,
      payload
    );

    return response;
  }

  removeUserRoles (payload: RemoveUserRolesPayload) {
    const endpoint = 'api/User/RemoveUserRoles';

    return this.httpRestService.post(
      endpoint,
      payload
    );
  }

  async getAssociatedNonprofitsForUser (email: string) {
    const endpoint = 'api/Account/Manage/GetAssociatedNonprofitsForUser';

    return this.httpRestService.post<APIResponse<NonprofitBlockDisplayModel[]>>(
      endpoint,
      { email }
    );
  }

  async getUserClaims () {
    const endpoint = 'api/User/GetUserClaims';
    const response = this.httpRestService.get<APIResponse<UserLoginClaims>>(
      endpoint
    );

    return response;
  }
}
