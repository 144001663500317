import { Injectable } from '@angular/core';
import { NonprofitAdminRequestDisplayModel } from '@core/models/claim.model';
import { BasicMyNonprofit } from '@core/models/npo.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse, PaginatedAPIResponse } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class MyNonprofitsResources {

  constructor (
    private http: HttpRestService
  ) { }

  async getNonprofitsForAdmin () {
    const response = await  this.http.get<APIResponse<BasicMyNonprofit[]>>(
      '/api/Nonprofit/Profile/GetNonprofitsForAdmin'
    );

    return response.data;
  }

  async getRequestsForUser () {
    const response = await this.http.post<PaginatedAPIResponse<NonprofitAdminRequestDisplayModel>>(
      '/api/NonprofitAdminRequest/GetRequestsForUser',
      {
        paginationOptions: {
          returnAll: true
        }
      }
    );

    return response.data.records;
  }

  async cancelChapterRequest (
    chapterRequestIds: number[]
  ) {
    return this.http.post<APIResponse>(
      'api/NonprofitChapter/CancelChapterRequests',
      {
        chapterRequestIds
      }
    );
  }
}
