
import { Injectable } from '@angular/core';
import { NonprofitSearchResponseModelAPI, StandardGetSearchPayload } from '@core/models/npo.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { AutocompleteSearchAPIResponse } from '@core/typings/nonprofit-search.typing';
import { APIResponse, PaginationOptions, SearchFilter, SearchResultObj } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class NonprofitSearchResources {
  constructor (
    private httpRestService: HttpRestService
  ) { }

  async searchByTerm (
    searchText: string,
    pageNumber: number,
    rowsPerPage: number,
    showAll: boolean,
    searchFilters: SearchFilter[]
  ) {
    const response = await this.httpRestService.post<APIResponse<NonprofitSearchResponseModelAPI>>(
      '/api/Search/GetSearch',
      {
        searchText,
        pageNumber,
        rowsPerPage,
        searchFilters,
        showAll
      }
    );

    return response.data;
  }

  async searchByAdminEmail (
    AdminEmail: string,
    showAll: boolean,
    paginationOptions: PaginationOptions<any>
  ) {
    const response = await this.httpRestService.post<APIResponse<{ records: SearchResultObj[] }>>(
      '/api/Nonprofit/SearchByAdmin',
      {
        AdminEmail,
        paginationOptions: {
          ...paginationOptions,
          showAll
        }
      }
    );

    return response.data;
  }

  async searchByPaymentNumber (
    paymentNumber: string,
    showAll = true,
    paginationOptions: PaginationOptions<any>
  ) {
    const response = await this.httpRestService.post<APIResponse<{ records: SearchResultObj[] }>>(
      '/api/Nonprofit/Finance/SearchByPaymentNumber',
      {
        paymentNumber,
        paginationOptions: {
          ...paginationOptions,
          showAll
        }
      }
    );

    return response.data;
  }

  getSearchForAutoComplete (
    payload: StandardGetSearchPayload
  ): Promise<AutocompleteSearchAPIResponse> {
    return this.httpRestService.post(
      '/api/Search/GetSearch',
      payload
    );
  }

  searchForMergeOrgs (
    payload: StandardGetSearchPayload
  ): Promise<AutocompleteSearchAPIResponse> {
    return this.httpRestService.post(
      '/api/Search/GetSearch',
      payload
    );
  }

  searchByRegIdOnly (
    payload: StandardGetSearchPayload
  ): Promise<AutocompleteSearchAPIResponse> {
    return this.httpRestService.post(
      '/api/Search/GetSearch',
      payload
    );
  }
}
