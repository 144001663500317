import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nppCarriageToBr'
})
export class CarriageToBrPipe implements PipeTransform {

  transform (input: string): string {
   return input.replace(/(\\r|\r)?(\\n|\n)/gm, '<br>');
  }
}
