/**
 * This should list out a sub-set of items from a larger list (say 5-10)
 * This should allow navigating to a page with the full list ("View all...")
 */

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Notification } from '@core/models/notifications.model';
import { ListItem, ListTypes } from '@yourcause/common';
import { NonprofitService } from '../../../nonprofit/nonprofit.service';

@Component({
  selector: 'yc-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnChanges {
  @Input() type: ListTypes;
  @Input() listItems: Notification[] = [];
  @Output() onDismiss = new EventEmitter();

  ListTypes = ListTypes;
  listItemArray: ListItem[] = [];
  listClass = 'links';

  constructor (
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private nonprofitService: NonprofitService
  ) { }

  bulletIcon (i: string) {
    switch (i) {
      case 'success': { return 'check-circle'; }
      case 'danger': { return 'exclamation-circle'; }
      case 'warning': { return 'exclamation-circle'; }
      case 'info': { return 'info-circle'; }
      case 'primary': { return 'circle'; }
      default: { return 'circle'; }
    }
  }

  async navTo (i: ListItem) {
    if (i.externalActionUrl) {
      window.open(i.externalActionUrl);
    } else {
      if (i.setActiveNpoOnNav) {
        const skipToastr = (i.nonprofitId === this.nonprofitService.activeNpo?.id);
        await this.nonprofitService.setSelectedNpo(
          i.nonprofitId,
          skipToastr
        );
        this.router.navigate([i.actionUrl], {
          relativeTo: this.activatedRoute
        });
      } else {
        this.router.navigate([i.actionUrl], {
          relativeTo: this.activatedRoute
        });
      }
    }
  }

  _onDismiss (id: number) {
    this.onDismiss.emit(id);
  }

  ngOnChanges () {
    if (this.type === ListTypes.Links) {
      this.listClass = 'links';
    }
    if (this.type === ListTypes.Notifications) {
      this.listClass = 'notifications';
      this.setNotifications();
    }
  }

  setNotifications () {
    this.listItems.forEach((item: Notification) => {
      this.listItemArray.push({
        id: +item.notificationId,
        title: item.title,
        subtitle: item.subtitle,
        dismissable: item.isDismissable,
        actionUrl: item.actionUrl,
        externalActionUrl: item.externalActionUrl,
        symbol: item.symbol,
        indicatorClass: item.indicatorClass,
        setActiveNpoOnNav: item.setActiveNpoOnNav,
        nonprofitId: item.nonprofit ? item.nonprofit.nonprofitId : 1,
        nonprofitName: item.nonprofit ? item.nonprofit.name : ''
      });
    });
  }
}
