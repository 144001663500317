<ng-container *ngIf="ready">
  <yc-modal
    [simple]="true"
    [modalHeader]="modalHeader"
    [modalSubHeader]="modalSubheader"
    [cancelButtonText]="'common:btnClose' | ycI18n:'Close'"
    (onCancel)="onCancel()">

    <yc-auto-table
      key="NONPROFIT_IRS_ATTRIBUTION"
      [hideTableFilters]="true"
      [hideTableOptions]="true"
      [skipAddToState]="true"
      [tableDataFactory]="tableDataFactory">
      <!-- CHANGE DATE -->
      <ng-container *ycTableColumn="'common:hdrChangeDate';
        let row;
        prop: 'changeDate',
        filterType: 'date',
        defaultSortDirection: 'desc'">
        {{ row.changeDate | ycFormatDate }}
      </ng-container>
      <!-- ON PUB 78  -->
      <ng-container *ycTableColumn="'nonprofits:textOnPub78';
        let row;
        prop: 'onPub78',
        filterType: 'boolean'">
        <yc-fa class="pe-1"
          [icon]="row.onPub78 ?
            'check-circle' :
            'times-circle'"
          [light]="true"
          [class.text-success]="row.onPub78"
          [class.text-danger]="!row.onPub78">
        </yc-fa>
        <yc-i18n
          [key]="row.onPub78 ?
            'common:textYes' :
            'common:textNo'">
        </yc-i18n>
      </ng-container>
      <!-- PUB 78 DES  -->
      <ng-container *ycTableColumn="'nonprofits:textPub78Designation';
        let row;
        prop: 'pub78Designation',
        filterType: 'text'">
        {{
          !!row.pub78Designation ?
          row.pub78Designation :
          ('common:textNotApplicableNA' | ycI18n: 'N/A')
        }}
      </ng-container>
      <!-- ON BMF  -->
      <ng-container *ycTableColumn="'nonprofits:textOnBMF';
        let row;
        prop: 'onBMF',
        filterType: 'boolean'">
        <yc-fa class="pe-1"
          [icon]="row.onBMF ?
            'check-circle' :
            'times-circle'"
          [light]="true"
          [class.text-success]="row.onBMF"
          [class.text-danger]="!row.onBMF">
        </yc-fa>
        <yc-i18n
          [key]="row.onBMF ?
            'common:textYes' :
            'common:textNo'">
        </yc-i18n>
      </ng-container>
      <!-- FOUNDATION CODE  -->
      <ng-container *ycTableColumn="'nonprofits:textFoundationCode';
        let row;
        prop: 'foundationCode',
        filterType: 'text'">
        {{
          !!row.foundationCode ?
          row.foundationCode :
          ('common:textNotApplicableNA' | ycI18n: 'N/A')
        }}
      </ng-container>
      <!-- SUBSECTION CODE  -->
      <ng-container *ycTableColumn="'nonprofits:textSubsectionCode';
        let row;
        prop: 'subsectionCode',
        filterType: 'text'">
        {{
          !!row.subsectionCode ?
          row.subsectionCode :
          ('common:textNotApplicableNA' | ycI18n: 'N/A')
        }}
      </ng-container>
      <!-- DEDUCT CODE  -->
      <ng-container *ycTableColumn="'nonprofits:textDeductibilityCode';
        let row;
        prop: 'deductibilityCode',
        filterType: 'text'">
        {{
          !!row.deductibilityCode ?
          row.deductibilityCode :
          ('common:textNotApplicableNA' | ycI18n: 'N/A')
        }}
      </ng-container>
      <!-- IRS REVOCATION DATE  -->
      <ng-container *ycTableColumn="'nonprofit:textIrsRevocationDate';
        let row;
        prop: 'irsRevocationDate',
        filterType: 'date'">
        {{
          !!row.irsRevocationDate ?
          (row.irsRevocationDate | ycFormatDate) :
          ('common:textNotApplicableNA' | ycI18n: 'N/A')
        }}
      </ng-container>
      <!-- EFG REASON  -->
      <ng-container *ycTableColumn="'nonprofit:textEFGReason';
        let row;
        prop: 'eligibleForGiving',
        filterType: 'text'">
        {{
          !!row.eligibleForGiving ?
          (row.eligibleForGivingReason) :
          ('common:textNotApplicableNA' | ycI18n: 'N/A')
        }}
      </ng-container>
    </yc-auto-table>
  </yc-modal>
</ng-container>