import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { BannerInformationAPI } from '@core/typings/api/custom-alerts.typing';
import { APIResult, PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class CustomAlertsResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  addBanner (payload: BannerInformationAPI.AddBannerPayload) {
    return this.httpRestService.post(
      'api/BannerInformation/AddBanner',
      payload
    );
  }

  updateBanner (payload: BannerInformationAPI.EditBannerPayload) {
    return this.httpRestService.post(
      'api/BannerInformation/UpdateBanner',
      payload
    );
  }

  fetchPaginatedBannerRecords (paginationOptions: PaginationOptions<BannerInformationAPI.BannerInformationDisplay>) {
    return this.httpRestService.post<APIResult<BannerInformationAPI.BannerInformationDisplay>>(
      '/api/bannerInformation/getAllBannerRecords',
      {
        paginationOptions
      }
    );
  }

  fetchAllBannerRecords () {
    return this.httpRestService.post<APIResult<BannerInformationAPI.BannerInformationDisplay>>(
      '/api/bannerInformation/getAllBannerRecords',
      {
        paginationOptions: {
          returnAll: true,
          sortColumns: [{
            columnName: 'displayPageId',
            sortAscending: false
          }]
        }
      }
    );
  }
}
