<yc-modal
  [modalHeader]="'nonprofit:hdrAdditionalInformation' | ycI18n: 'Additional Information'"
  [cancelButtonText]="cancelButtonText"
  (onCancel)="_onClose()">
  <ng-container>
    <dl class="dl-horizontal">
      <dt>
        {{ attributeHdr }}
      </dt>
      <dd>
        {{ valueHdr }}
      </dd>
      <div class="separator"></div>
    </dl>
    <!-- REGISTERED NAME/ADDRESS  -->
    <dl *ngIf="registeredNameAndAddress"
      class="dl-horizontal">
      <dt>
        {{ NonprofitAdditionalInformationAttributesText.registeredNameAndAddress }}
      </dt>
      <dd>
        <div>{{ registeredNameAndAddress?.name }}</div>
        <div [innerHTML]="registeredNameAndAddress | address : false : true"></div>
      </dd>
      <div class="separator"></div>
    </dl>
    <!-- DISBURSEMENT NAME/ADDRESS  -->
    <dl class="dl-horizontal">
      <dt>
        {{ NonprofitAdditionalInformationAttributesText.disbursementNameAndAddress }}
      </dt>
      <dd>
        <div *ngIf="!disbursementNameAndAddress">
          {{ noNameOrAddressOnFiletext }}
        </div>
        <div *ngIf="!!disbursementNameAndAddress">
          {{ disbursementNameAndAddress?.name  || noNameOnFiletext }}
          <div [innerHTML]="disbursementNameAndAddress?.address1 ?
            (disbursementNameAndAddress | address : false : true) :
            noAddressOnFiletext">
          </div>
        </div>

      </dd>
      <div class="separator"></div>
    </dl>
    <!-- DISPLAY NAME/ADDRESS  -->
    <dl class="dl-horizontal">
      <dt>
        {{ NonprofitAdditionalInformationAttributesText.displayNameAndAddress }}
      </dt>
      <dd>
        <div *ngIf="!displayNameAndAddress">
          {{ noNameOrAddressOnFiletext }}
        </div>
        <div *ngIf="!!displayNameAndAddress">
          {{ displayNameAndAddress?.name || noNameOnFiletext }}
          <div [innerHTML]="displayNameAndAddress?.address1 ?
            (displayNameAndAddress | address : false : true) :
            noAddressOnFiletext">
          </div>
        </div>
      </dd>
      <div class="separator"></div>
    </dl>
    <!-- EFG  -->
    <dl *ngIf="additionalInfo?.eligibleForGiving"
      class="dl-horizontal">
      <dt>
        {{ NonprofitAdditionalInformationAttributesText.eligibleForGiving }}
      </dt>
      <dd>
        {{ additionalInfo.eligibleForGiving | nppEligibleForGivingStatusIdPipe }}
      </dd>
      <div class="separator"></div>
    </dl>
    <!-- ACH  -->
    <dl class="dl-horizontal">
      <dt>
        {{ NonprofitAdditionalInformationAttributesText.hasACH }}
      </dt>
      <dd>
        <div [innerHTML]="formatAttributeBoolean(additionalInfo?.hasACH)"></div>
      </dd>
      <div class="separator"></div>
    </dl>
    <!-- REG AUTH  -->
    <dl *ngIf="additionalInfo?.registrationAuthorityName"
      class="dl-horizontal">
      <dt>
        {{ NonprofitAdditionalInformationAttributesText.registrationAuthorityName }}
      </dt>
      <dd>
        {{ additionalInfo.registrationAuthorityName }}
      </dd>
      <div class="separator"></div>
    </dl>
    <!-- IS ACTIVE  -->
    <dl *ngIf="additionalInfo?.isActive !== null"
      class="dl-horizontal">
      <dt>
        {{ NonprofitAdditionalInformationAttributesText.isActive }}
      </dt>
      <dd>
        <yc-i18n
          [key]="additionalInfo.isActive ?
            'common:textYes' :
            'common:textNo'">
        </yc-i18n>
      </dd>
      <div class="separator"></div>
    </dl>
    <!-- ELIGIBLE FOR DISBURSEMENT -->
    <dl *ngIf="additionalInfo?.registrationAuthorityName"
      class="dl-horizontal">
      <dt>
        {{ NonprofitAdditionalInformationAttributesText.eligibleForDisbursement }}
      </dt>
      <dd>
        <yc-i18n
          [key]="!additionalInfo.disbursementSuspended ?
            'common:textYes' :
            'common:textNo'">
        </yc-i18n>
      </dd>
      <div class="separator"></div>
    </dl>
    <!-- Created Date -->
    <dl *ngIf="additionalInfo?.createdDate"
      class="dl-horizontal">
      <dt>
        {{ NonprofitAdditionalInformationAttributesText.createdDate }}
      </dt>
      <dd>
        {{ additionalInfo.createdDate | ycFormatDate }}
      </dd>
    </dl>
  </ng-container>
</yc-modal>