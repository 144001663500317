import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { I18nService } from '@yourcause/common/i18n';

export const localeResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot
) => {
  return inject(I18nService).namespaceReady(route.data.namespace);
}

