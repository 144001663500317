import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { I18nService } from '@yourcause/common/i18n';
import { ReasonService } from './reasons.service';



@Pipe({
  name: 'nppReasonPipe'
})
export class ReasonPipe implements PipeTransform {

  displayText: string;
  isYcAdminOrSupport = this.authService.isYcAdminOrSupport();
  npoReasons = this.reasonService.allReasonsForNpoDisplay;
  ycAdminReasons = this.reasonService.allReasonsForYcAdminDisplay;
  otherText = this.i18n.translate(
    'common:textOther',
    {},
    'Other'
  );
  constructor (
    private i18n: I18nService,
    private reasonService: ReasonService,
    private authService: AuthService
  ) { }

  transform (inputId: string|number): string {
    const id = +inputId;
    const reasons = this.isYcAdminOrSupport ?
      this.ycAdminReasons :
      this.npoReasons;
    const reasonObj = (reasons.find((reason) => reason.id === id));
    if (!!reasonObj) {
      return this.i18n.translate(
        reasonObj.i18nKey,
        {},
        reasonObj.value
      );
    }

    return this.otherText;
  }
}
