import { Injectable } from '@angular/core';
import { FeaturedPartnerCollateral, FeaturedPartners } from '@core/models/featured-partner.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class FeaturedPartnerResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getFeaturedPartnerCollateral (
    featuredPartnerId: FeaturedPartners,
    nonprofitId: number
  ) {
    const response = await this.httpRestService.post<APIResponse<FeaturedPartnerCollateral>>(
      'api/NonprofitPartner/GetCollateral',
      {
        partnerId: featuredPartnerId,
        nonprofitId: nonprofitId
      }
    );

    return response.data;
  }

  async createUserImpression (
    partnerCampaignCollateralId: number,
    nonprofitId?: number
  ) {
    await this.httpRestService.post(
      'api/NonprofitPartner/UpdateCollateral',
      {
        partnerCampaignCollateralId,
        nonprofitId
      }
    );
  }
}
