<div class="d-flex flex-row"
  [formGroup]="formArray.at(0) | ycAs: formGroupTypeToken">


  <!-- SEARCH TERM INPUT  -->
  <div class="search-input flex-grow-1">
    <yc-input
      name="term"
      [placeholder]="placeholderText"
      [rounded]="true"
      [label]="'common:lblSearchTerm' | ycI18n : 'Search Term'"
      [srOnlyLabel]="true"
      (keyup.enter)="_onTermChange()"
      [rightIcons]="[
        {
          icon: showClear ? 'times' : '',
          iconClass: 'text-danger pe-1',
          onIconClick: clearSearchAndResults
        },
        {
          icon: 'search',
          onIconClick: _onTermChange
        }
      ]"
      [autofocus]="true">
    </yc-input>
  </div>

  <!-- SHOW CHAPTERS CHECKBOX  -->
  <div class="checkbox-filter">
    <yc-checkbox
      name="showChapters"
      [optionLabel]="'search:lblIncludeChapters' | ycI18n : 'Include chapters'"
      [centerLabel]="true"
      (onChange)="_changeType()">
    </yc-checkbox>
  </div>
</div>

<!-- ADVANCED FILTERS SECTION  -->
<div *ngIf="facets">
  <div [formGroup]="this.formArray.at(1) | ycAs: formGroupTypeToken"
    class="d-flex mt-2">

    <!-- REG AUTH SELECT  -->
    <yc-typeahead-select
      name="registrationAuthority"
      [label]="'common:lblRegistrationAuthority' | ycI18n"
      [srOnlyLabel]="true"
      class="me-3"
      [showClear]="false"
      [items]="regAuthOptions"
      (onChange)="_changeFilter($event, 'regAuth')">
    </yc-typeahead-select>

    <!-- COUNTRY SELECT  -->
    <yc-typeahead-select *ngIf="searchFiltersRegAuth &&
      countryOptions &&
      !loadingCountryOptions"
      class="me-3"
      name="country"
      [showClear]="false"
      [label]="'common:lblCountry' | ycI18n : 'Country'"
      [srOnlyLabel]="true"
      [items]="countryOptions"
      (onChange)="_changeFilter($event, 'country')">
    </yc-typeahead-select>

    <!-- STATE/PROV/REGION SELECT  -->
    <yc-typeahead-select *ngIf="searchFiltersCountry &&
      stateProvRegionOptions &&
      facets.stateProvRegName.length > 0 &&
      !loadingStateOptions"
      [showClear]="false"
      name="stateProvRegName"
      [label]="'accountSetup:lblState' | ycI18n"
      [srOnlyLabel]="true"
      [items]="stateProvRegionOptions"
      (onChange)="_changeFilter($event, 'state')">
    </yc-typeahead-select>
  </div>
</div>