import { BasicNonprofit, Nonprofit, NonprofitAPI } from '@core/models/npo.model';
import { CACHE_TYPES, CachedAttr } from '@yourcause/common/cache';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class NonprofitState extends BaseYcState {
  @CachedAttr(CACHE_TYPES.SESSION)
  readonly selectedNpo: number;
  readonly activeNpo: Nonprofit;

  readonly myNpos: BasicNonprofit[];
  readonly activeNpoStateData: Nonprofit;
  readonly nonprofitLogo: string;
  readonly placeholder: NonprofitAPI = {
    name: 'Placeholder',
    displayName: 'placeholder',
    classificationInfo: {
      classificationOneId: 1,
      classificationTwoId: 2,
      classificationThreeId: 3,
      classificationTypeId: 1,
      classificationOneCode: 'I'
    },
    registrationId: '1',
    supportsSDGGive: false,
    supportsSDGVolunteer: true,
    displayAddress: null,
    displayEmail: null,
    displayUrl: null,
    displayNumber: null,
    displayPitch: null,
    remittanceInfo: {
      name: null,
      address: {
        address1: null,
        address2: null,
        city: null,
        stateProvRegCode: null,
        stateProvRegId: null,
        postalCode: null,
        countryCode: null
      }
    },
    nonprofitIconImageUrl: null,
    nonprofitLogoImageUrl: null,
    nonprofitHeaderImageUrl: null,
    sdGs: [{
      sdgId: 1,
      givePercent: 0.6,
      volunteerPercent: 0.4,
      startDate: '',
      targets: [{
        sdgTargetId: 1,
        target: 'Some target',
        givePercent: 0.6,
        volunteerPercent: 0.4,
        startDate: '',
        endDate: ''
      }]
    }, {
      sdgId: 2,
      givePercent: 0.5,
      volunteerPercent: 0.5,
      startDate: '',
      targets: []
    }],
    subsectionCode: '3',
    isActive: true
  };

}
