import { Injectable } from '@angular/core';
import { Nonprofit } from '@core/models/npo.model';
import { SubsectionCode } from '@core/models/subsection-codes.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse, Classification } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class ClassificationResources {
  constructor (
    private httpRestService: HttpRestService
  ) {
  }

  getClassifications () {
    return this.httpRestService.get<Promise<APIResponse<Classification[]>>>(
      '/api/NonprofitClassification/GetAllClassificationsFlatList?classificationTypeId=1'
    );
  }

  async setClassification (payload: Partial<Nonprofit>) {
    return this.httpRestService.post(
      '/api/NonprofitClassification/UpdateNonprofitClassification',
      payload.classificationInfo
    );
  }

  getSubsections () {
    return this.httpRestService.get<Promise<{ data: SubsectionCode[] }>>(
        '/api/Nonprofit/GetSubsectionCodeList?AllowManualAddOnly=false'
      );
  }
}
