import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nppAddressObject'
})
export class AddressObjectPipe implements PipeTransform {
  transform (
    address1: string,
    city: string,
    state: string,
    postalCode: string,
    country: string
  ) {
    return {
      address1,
      city,
      state,
      postalCode,
      country
    };
  }
}
