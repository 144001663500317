import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Notification } from '@core/models/notifications.model';
import { Nonprofit } from '@core/models/npo.model';
import { AccountService } from '@core/services/account.service';
import { AppShellService } from '@core/services/app-shell.service';
import { AuthService } from '@core/services/auth.service';
import { BannerService } from '@core/services/banner.service';
import { LinkService } from '@core/services/link.service';
import { SessionKeepAliveService } from '@core/services/session-keep-alive.service';
import { TokenTimeoutService } from '@core/services/token/token-timeout.service';
import { TokenService } from '@core/services/token/token.service';
import { FooterState } from '@core/state/footer.state';
import { environment } from '@environment';
import { AddressFormatterService, HelpTextServer, HelpTextService, SidebarLink } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory } from '@yourcause/common/modals';
import { NotificationsService } from 'ClientApp/app/home/services/notifications.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NonprofitService } from '../../../nonprofit/nonprofit.service';
import { NonprofitNotificationModalComponent } from '../nonprofit-notification-modal/nonprofit-notification-modal.component';
import { ReleaseNotesComponent } from '../release-notes/release-notes.component';
import { BasicTourStep } from '@yourcause/common/tour';

@Component({
  selector: 'npp-auth-wrapper',
  templateUrl: './auth-wrapper.component.html',
  styleUrls: ['./auth-wrapper.component.scss']
})
export class AuthWrapperComponent implements OnInit, OnDestroy {
  isLoggedIn = true;
  copyrightText = '©2020 YourCause - NPOconnect Nonprofit Platform (' + environment.version + ')';
  headerVisible = true;
  accountName: string;
  initials: string;
  accountRole = '';
  primaryText: string;
  secondaryText: string;
  tertiaryText: string;
  logoUrl = 'assets/img/placeholders/charity-icon-placeholder.svg';
  nonprofitLogoUrl: string;
  themeClass: string;
  helpText: string;
  helpComponent: HelpTextServer;
  loadingProm = this.i18nService.namespaceReady();
  activeNpo: Nonprofit;
  selectedNpoId: number;
  nonprofitLoading: boolean;
  showNewAdminHelp = false;
  isYcAdminOrSupport: boolean;
  sidebarLinks$ = this.appShellService.changesTo$('sidebarLinks');
  sidebarClosed$ = this.appShellService.changesTo$('sidebarClosed');
  banners$ = this.bannerService.changesTo$('banners');
  timeRemaining$ = this.timeoutService.timeRemaining$.pipe(
    tap(time => {
      if (time <= 0) {
        this.onSignOutClick();
      }
    }));
  showTimeout$ = this.timeoutService.showTimeout$;
  footerState$ = this.footerState.changesTo$('footerState');
  footerActionLabel$ = this.footerState.changesTo$('footerActionLabel');
  footerCancelLabel$ = this.footerState.changesTo$('footerCancelLabel');
  footerSecondaryLabel$ = this.footerState.changesTo$('footerSecondaryLabel');
  footerSecondaryDisabled$ = this.footerState.changesTo$('footerSecondaryDisabled');
  footerTertiaryLabel$ = this.footerState.changesTo$('footerTertiaryLabel');
  footerActionDisabled$ = this.footerState.changesTo$('footerActionDisabled');
  footerAction$ = this.footerState.changesTo$('footerAction');
  footerCancelAction$ = this.footerState.changesTo$('footerCancelAction');
  footerSecondaryAction$ = this.footerState.changesTo$('footerSecondaryAction');
  footerTertiaryAction$ = this.footerState.changesTo$('footerTertiaryAction');
  footerInfoText$ = this.footerState.changesTo$('footerInfoText');
  footerInfoIcon$ = this.footerState.changesTo$('footerInfoIcon');
  footerInfoTooltip$ = this.footerState.changesTo$('footerInfoTooltip');
  FOOTER_STATES = this.footerState.FOOTER_STATES;

  headerLinks: SidebarLink[] = [{
    label: 'My account',
    icon: '',
    i18nKey: 'common:hdrMyAccount',
    action: () => {
      this.router.navigateByUrl('/my-account');
    },
    sequence: '1.1'
  }, {
    label: 'Communication preferences',
    icon: '',
    i18nKey: 'common:hdrCommunicationPreferences',
    action: () => {
      this.router.navigateByUrl('/my-account/communication-preferences');
    },
    sequence: '1.2'
  }, {
    label: 'Logout',
    icon: '',
    i18nKey: 'common:linkLogout',
    action: () => {
      this.onSignOutClick();
    },
    sequence: '1.3'
  }];
  tourSteps: BasicTourStep[] = [
    {
      stepNumber: 0,
      tourLabel: this.i18nService.translate(
        'tours:hdrNavigation',
        {},
        'Navigation'
      ),
      tourDescription: this.i18nService.translate(
        'tours:textNavigationHelp',
        {},
        'Access all areas of your nonprofit platform. Administer your nonprofit, access your giving and volunteering information, or return here, to My Workspace.'
      ),
      tourLocation: 'right',
      tourKey: 'NEW_USER'
    },
    {
      stepNumber: 1,
      tourLabel: this.i18nService.translate(
        'common:hdrMyAccount',
        {},
        'My account'
      ),
      tourDescription: this.i18nService.translate(
        'tours:textMyAccountHelp',
        {},
        'Edit your personal account settings, including your name, email and password, and language'
      ),
      tourLocation: 'right',
      tourKey: 'NEW_USER'
    }
  ];
  sub = new Subscription();
  notification: Notification;
  notificationModalDeps: Partial<NonprofitNotificationModalComponent>;
  modalClosed: boolean;
  skipEndpointCall = false;
  releaseNotesDeps: Partial<ReleaseNotesComponent>;
  termsAndConditionsLink = this.linkService.getTermsOfServiceLink();
  privacyPolicyLink = this.linkService.getPrivacyPolicyLink();

  constructor (
    private i18nService: I18nService,
    private linkService: LinkService,
    private helpService: HelpTextService,
    public router: Router,
    public route: ActivatedRoute,
    private authService: AuthService,
    private nonprofitService: NonprofitService,
    private accountService: AccountService,
    private bannerService: BannerService,
    private footerState: FooterState,
    private appShellService: AppShellService,
    private timeoutService: TokenTimeoutService,
    private addressFormatter: AddressFormatterService,
    private tokenService: TokenService,
    private sessionKeepAliveService: SessionKeepAliveService,
    private modalFactory: ModalFactory,
    private notificationsService: NotificationsService
  ) {
    this.sub.add(router.events.subscribe(() => {
      let child = route.snapshot.firstChild;

      while (child) {
        this.helpText = child.data.helpText;
        if (child.data.helpComponent) {
          const hts: HelpTextServer = child.data.helpComponent;
          this.helpText = this.helpService.buildHelpText(hts);
        }
        child = child.firstChild;
      }
    }));
    this.sub.add(this.nonprofitService.changesTo$('activeNpo')
      .subscribe((activeNpo) => {
        this.activeNpo = activeNpo;
        this.selectedNpoId = activeNpo?.id;
        this.setNpoOrAdminText();
      })
    );
    this.sub.add(this.nonprofitService.changesTo$('nonprofitLogo')
      .subscribe((url) => {
        this.logoUrl = url;
      })
    );
  }

  get tokenInfo () {
    return this.tokenService.tokenInfo;
  }

  get transistioningNpos () {
    return (this.activeNpo && (+this.selectedNpoId !== +this.activeNpo.id)) ||
      (
        this.tokenInfo.NonprofitIdArr &&
        this.tokenInfo.NonprofitIdArr.length
      );
  }

  get body () {
    return document.getElementsByTagName('html')[0];
  }

  async ngOnInit () {
    this.sub.add(this.tokenService.changesTo$('tokenInfo').subscribe(() => {
      if (this.tokenService.hasToken()) {
        this.timeoutService.start();
        this.sessionKeepAliveService.startWatching();
      } else {
        this.timeoutService.stop();
        this.sessionKeepAliveService.stopWatching();
      }
    }));
    this.isYcAdminOrSupport = this.authService.isYcAdminOrSupport();
    this.appShellService.setSidebarLinks();
    this.setNpoOrAdminText();
    this.timeoutService.start();
    //Nonprofit Notification
    this.sub.add(this.notificationsService.changesTo$('modalNotificationClosed')
      .subscribe((val) => {
        this.modalClosed = val;
    }));
    if (!this.authService.isInternalAdmin()) {
      const activeNonprofitId = this.nonprofitService.activeNpo?.id;
      await this.notificationsService.setActiveNonprofitId(
        activeNonprofitId
      );
      await this.notificationsService.getNotifications();
      const modalNotification: Notification = this.notificationsService.modalNotification;
      if (!!modalNotification && !this.modalClosed) {
        this.notificationModalDeps = {
          notification: modalNotification
        };
        await this.modalFactory.open(
          NonprofitNotificationModalComponent,
          this.notificationModalDeps,
          {
            class: 'modal-md'
          }
        );
      }
    }
    //Release Notes Guard
  }

  setNpoOrAdminText () {
    setTimeout(() => {
      const isLoggedIn = this.tokenService.getIsLoggedIn();
      this.headerVisible = isLoggedIn;
      if (isLoggedIn) {
        this.showNewAdminHelp = (this.tokenInfo?.role || []).length === 0;

        if (this.authService.isYcAdminOrSupport()) {
          this.setAdminText();
        } else if (
          !!this.nonprofitService.activeNpo &&
          this.activeNpo.id !== this.nonprofitService.activeNpo.id
        ) {
          this.activeNpo = this.nonprofitService.activeNpo;
          this.setNpoText();
        } else {
          this.setNpoText();
        }

        if (
          (!!this.activeNpo && !this.activeNpo?.isActive) &&
          this.authService.isNonprofitUser()
        ) {
          this.bannerService.setShowInactiveNpoBanner(true);
        } else {
          this.bannerService.setShowInactiveNpoBanner(false);
        }
        this.bannerService.setPortalBanners();

        if (this.tokenService.tokenInfo) {
          this.setAccountText();
        }
      }
    }, 500);
  }

  setAccountText () {
    const parsedJwt = this.tokenService.tokenInfo;
    this.accountName = this.accountService.accountDetails ?
      `${this.accountService.accountDetails.firstName}
        ${this.accountService.accountDetails.lastName}` :
        `${parsedJwt.firstName} ${parsedJwt.lastName}`;
    this.initials = this.accountService.accountDetails.firstName[0] +
      this.accountService.accountDetails.lastName[0];
  }

  setAdminText () {
    this.primaryText = 'YourCause';
    this.secondaryText = this.addressFormatter.format({
      address1: '6111 West Plano Parkway',
      city: 'Plano',
      stateProvRegCode: 'TX'
    }, true, true);

    this.logoUrl = 'assets/img/brand/yc-circle-logo.png';
  }

  setNpoText () {
    const npo = this.activeNpo;
    if (!!npo) {
      this.primaryText = npo?.displayName || npo?.name;
      this.secondaryText = this.addressFormatter.format(
        (npo?.displayAddress || npo?.address),
        true,
        true
      );
      this.tertiaryText = npo?.registrationId;
      const parentLogo = (npo?.parentNonprofit || {} as Nonprofit).nonprofitIconImageUrl;
      this.logoUrl = npo?.nonprofitIconImageUrl ||
        parentLogo ||
        this.getClassificationIcon();
    }
  }

  getClassificationIcon () {
    const npo = this.activeNpo;
    const classificationInfo = npo?.classificationInfo ||
    (npo?.parentNonprofit || {} as Nonprofit).classificationInfo;
    const code = classificationInfo ?
      classificationInfo.classificationOneCode :
      10;

    return `assets/img/nonprofit/icons/cat_${code}.gif`;
  }

  handleSidebarToggle () {
    this.appShellService.toggleSidebar();
  }

  onSignOutClick () {
    this.tokenService.logout();
  }

  keepMeSignedIn () {
    this.tokenService.getLatestToken();
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
