import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { I18nService } from '@yourcause/common/i18n';
import { CachedAttr, CACHE_TYPES } from '../../../../lib/Cache';


@Component({
  selector: 'yc-show-chapter-nonprofits',
  templateUrl: './show-chapter-nonprofits.component.html'
})
export class ShowChapterNonprofitsComponent implements OnInit {
  @CachedAttr(CACHE_TYPES.LOCALSTORAGE, true)
  showChapterNpos: boolean;

  @Input() parentFormGroup: UntypedFormGroup;
  @Output() changeType = new EventEmitter();
  showChaptersForm: UntypedFormGroup;
  showChaptersText = this.i18n.translate(
    'search:lblShowChaptersCheckbox',
    {},
    'Show chapters'
  );

  constructor (
    private i18n: I18nService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit () {
    this.showChaptersForm = this.formBuilder.group({
      showChapters: this.showChapterNpos
    });
    this.parentFormGroup.get('showChapters').setValue(this.showChapterNpos);
  }

  _changeMade () {
    this.showChapterNpos = this.showChaptersForm.get('showChapters').value;
    this.parentFormGroup.get('showChapters').setValue(this.showChapterNpos);
    this.changeType.emit();
  }

}
