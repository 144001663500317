import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nppAdaptUrl'
})
export class AdaptUrl implements PipeTransform {
  constructor (
  ) {}

  transform (url: string): string {
    if (url && !(url).includes('http')) {
      url = `http://${url}`;
    }

    return url;
  }
}
