import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '@yourcause/common/date';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'nppVettingApplicationDatePipe'
})
export class VettingApplicationDatePipe implements PipeTransform {
  constructor (
    private i18n: I18nService,
    private dateService: DateService
  ) { }

  transform (
    createDate: string,
    submittedOn?: string
  ): string {
    let returnVal: string;
    if (!!submittedOn) {
      returnVal = this.i18n.translate(
        'vetting:textApplicationSubmittedOn',
        {
          submitDate: this.dateService.formatDate(new Date(submittedOn))
        },
        'Submitted on __submitDate__'
      );
    } else {
      returnVal = this.i18n.translate(
        'vetting:textApplicationCreatedOn',
        {
          createDate: this.dateService.formatDate(new Date(createDate))
        },
        'Created on __createDate__'
      );
    }

    return returnVal;
  }

}
