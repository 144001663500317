import { Address, BaseAPIModel } from '@yourcause/common';
import { VerificationStatus, VerificationStatusReasonInfo } from '@core/models/status.model';

export class DonationModel extends BaseAPIModel {
  id: number;
  fundingSourceId: number;
  verificationStatusId: number;
  verificationStatusReasonId: number;
  externalDonorKey: string;
  externalDonorInfo: DonorInfo;
  fundingSourceInfo: FundingSourceInfo;
  verificationStatusInfo: VerificationStatus;
  verificationStatusReasonInfo: VerificationStatusReasonInfo;
  date: string;
  amount: number;
  companyId: number;
  nonprofitId: number;
  externalKey: string;
  externalReceivedDate: string;
  projectedMatchAmount: number;
  designation: string;
  currency: string;
  taxDeductibleAmount: number;
  notes: string;
  verifiedByUser: {
    id: number;
    lastName: string;
    firstName: string;
    email: string;
  };
  verificationDate: string;

  get changedBy () {
    return this.verifiedByUser.firstName + this.verifiedByUser.lastName;
  }
}

export interface DonorInfo {
  id: number;
  externalSystemTypeId?: number;
  firstName: string;
  lastName: string;
  email: string;
  fullName: string;
  isIndividualDonor?: boolean;
  primaryAddress?: string;
  secondaryAddress?: string;
  companyName?: string;
}

export interface FundingSourceInfo {
  id: number;
  description: string;
}

export interface UpdateDonationModalReturn {
  taxDeductibleAmount: number;
  verificationStatusReasonId: number;
  notes: string;
}

export interface UpdateDonationStatusPayload {
  id: number;
  taxDeductibleAmount: number;
  verificationStatusId: number;
  verificationStatusReasonId: any;
  notes: string;
}

export interface ExternalDonorInfo {
  id: number;
  isIndividualDonor: boolean;
  fullName: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  externalSystemTypeId: number;
  primaryAddress?: Address;
  secondaryAddress?: Address;
}

export interface Transaction {
  id: number;
  donationDate: string;
  donationAmount: number;
  receiptAmount: number;
  externalDonorInfo: ExternalDonorInfo;
  matchedExternalDonorInfo: ExternalDonorInfo;
  donationTypeId: number;
  donationTypeName: string;
  company: string;
  designation: string;
  notes: string;
  companyId: number;
  currency: string;
  isDisbursed: boolean;
  externalKey: string;
  externalSystemTypeId: number;
  nonprofitId: number;
  nonprofitName: string;
  donorName: string;
  anonymous: boolean;
  dedicateGift: boolean;
  dedicationType: number;
  dedicationText: string;
  paymentId: number;
  paymentNumber: string;
  paymentCreateDate: string;
  paymentStatusDate: string;
  paymentStatus: string;
  paymentType: string;
  previousPayments: PreviousPaymentForTransaction[];
  disbursementStatus: string;
  fees: TransactionFees;
  disbursementCurrencyCode: string;
  disbursementDonationAmount: number;
  disbursementNetAmount: number;
  disbursementFees: TransactionFees;
}

export interface PreviousPaymentForTransaction {
  paymentId: number;
  paymentNumber: string;
  paymentCreateDate: string;
  paymentStatusDate: string;
  paymentStatus: string;
  paymentType: string;
}

export interface TransactionFees {
  totalFees: TransactionFeeModel;
  processorFees: TransactionFeeModel;
  charitySupportFees: TransactionFeeModel;
}

export interface TransactionFeeModel {
  amount: number;
  coveredAmount: number;
  partiallyCovered: boolean;
  fullyCovered: boolean;
}

export const DEDICATION_MAP = {
  1: 'In honor of',
  2: 'In memory of'
};

export enum TransactionStatus {
  Disbursed = 'Disbursed',
  Pending = 'Pending',
  Refunded = 'Refunded'
}

export enum DonationVerificationStatus {
  NOT_REVIEWED = 'Not Reviewed',
  VERIFIED = 'Verified',
  REJECTED = 'Rejected',
  EXPIRED = 'Expired'
}

export enum EXTERNAL_SYSTEMS {
  GDG = 1,
  CSRConnect = 2,
  GrantsConnect = 3
}

export const EXTERNAL_SYSTEM_MAP_BY_ID = {
  1: 'GDG',
  2: 'CSRconnect',
  3: 'GrantsConnect'
};

