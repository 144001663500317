import { AssetType, BaseAPIModel } from '@yourcause/common';
import { User } from './user.model';
import { YcFile } from '@yourcause/common/files';

export class NonprofitAsset extends BaseAPIModel {
  static assetTypes: AssetType[] = [];
  assetId: string;
  assetType?: AssetType;
  fileName: string;
  fileUrl: string;
  isPublic: boolean;
  isRegistrationAsset: boolean;
  uploadedBy: User;
  approvedBy: User;
  uploadDate: string;
  approvedDate: string;
  assetTypeId: number;
  npoAdminCanRemove: boolean;
  comment: string;
  file?: YcFile;

  get uploader () {
    return this.uploadedBy.firstName + this.uploadedBy.lastName;
  }

  get assetTypeName () {
    return NonprofitAsset.assetTypes
      .find((type) => {
        return type.assetTypeId === this.assetTypeId;
      }).name;
  }
}
