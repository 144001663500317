import { Injectable } from '@angular/core';
import { SpinnerState } from '@core/state/spinner.state';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';


@AttachYCState(SpinnerState)
@Injectable({ providedIn: 'root' })
export class SpinnerService extends BaseYCService<SpinnerState> {

  startSpinner () {
    this.set('loading', true);
  }

  stopSpinner () {
    this.set('loading', false);
  }

  setLoadingMessage (message: string) {
    this.set('loadingMessage', message);
  }
}
