import { Injectable } from '@angular/core';
import { ActiveApplicationStatusSelectExclusionList, CompletedApplicationStatusSelectExclusionList, InactiveVettingStatusSelectExclusionList, OldActiveVettingStatusSelectExclusionList, OldInactiveVettingRequestStatusSelectExclusionList, PartnerVettingStatuses, StatusModel, StatusObjectUI, StatusSelectExclusionList, StatusType } from '@core/models/status.model';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { StatusResources } from './status.resources';
import { StatusState } from './status.state';

@AttachYCState(StatusState)
@Injectable({ providedIn: 'root' })
export class StatusService extends BaseYCService<StatusState> {
  constructor (
    private statusesResources: StatusResources
  ) {
    super();
}
  get allStatusesLookup () {
    return this.get('allStatusesLookup');
  }

  get allStatusesForDisplayMap () {
    return this.get('allStatusesForDisplayMap');
  }

  get activeOnlyStatusesForDisplayMap () {
    return this.get('activeOnlyStatusesForDisplayMap');
  }

  get bankInfoChangeRequestStatusSelect () {
    return this.get('bankInfoChangeRequestStatusSelect');
  }

  get eligibleForGivingStatusSelect () {
    return this.get('eligibleForGivingStatusSelect');
  }

  get eligibleForGivingStatusDisplay () {
    return this.get('eligibleForGivingStatusDisplay');
  }

  get sanctionsScreeningFlagStatusSelect () {
    return this.get('sanctionsScreeningFlagStatusSelect');
  }

  get chapterRequestStatusSelect () {
    return this.get('chapterRequestStatusSelect');
  }

  get adminRequestStatusSelect () {
    return this.get('adminRequestStatusSelect');
  }

  get checkReissueStatusSelect () {
    return this.get('checkReissueStatusSelect');
  }

  get remittanceAddressRequestStatusSelect () {
    return this.get('remittanceAddressRequestStatusSelect');
  }

  get nonprofitStoryStatusSelect () {
    return this.get('nonprofitStoryStatusSelect');
  }

  get activeVettingApplicationStatusSelect () {
    return this.get ('activeVettingApplicationStatusSelect');
  }

  get completedVettingApplicationStatusSelect () {
    return this.get('completedVettingApplicationStatusSelect');
  }

  get partnerVettingStatusFilterSelect () {
    return this.get('partnerVettingStatusFilterSelect');
  }

  get prospectiveAdminOutreachStatusSelect () {
    return this.get('prospectiveAdminOutreachStatusSelect');
  }

  get programStatusSelect () {
    return this.get('programStatusSelect');
  }

  get statusSelectExclusionList () {
    const statusExclusionList: number[] = [];
    for (const n in StatusSelectExclusionList) {
      if (typeof StatusSelectExclusionList[n] === 'number') {
        statusExclusionList.push(<any>StatusSelectExclusionList[n]);
      }
    }

    return statusExclusionList;
  }

  setBankInfoChangeRequestStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.BankInfoChangeRequest
    );
    this.set('bankInfoChangeRequestStatusSelect', statuses);
  }

  setEligibleForGivingStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.EligibleForGiving
    );
    this.set('eligibleForGivingStatusSelect', statuses);
  }

  setEligibleForGivingStatusDisplay () {
    const statuses = this.allStatusesForDisplayMap.filter((status) => {
      return status.typeId === StatusType.EligibleForGiving;
    });
    this.set('eligibleForGivingStatusDisplay', statuses);
  }

  setSanctionsScreeningFlagStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.SanctionsScreeningFlag
    );
    this.set('sanctionsScreeningFlagStatusSelect', statuses);
  }

  setChapterRequestStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.ChapterRequest
    );
    this.set('chapterRequestStatusSelect', statuses);
  }

  setAdminRequestStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.AdminRequest
    );
    this.set('adminRequestStatusSelect', statuses);
  }

  setCheckReissueStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.CheckReissue
    );
    this.set('checkReissueStatusSelect', statuses);
  }

  setRemittanceAddressRequestStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.RemittanceAddressRequest
    );
    this.set('remittanceAddressRequestStatusSelect', statuses);
  }

  setNonprofitStoryStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.NonprofitStory
    );
    this.set('nonprofitStoryStatusSelect', statuses);
  }

  setInactiveVettingRequestStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.NonprofitVettingRequest
    );
    const statusExclusionList = this.enumToNumberArray(
      InactiveVettingStatusSelectExclusionList
    );
    const filteredStatuses = statuses.filter((status) => {
      return !(statusExclusionList.includes(status.id));
    });
    this.set('inactiveVettingRequestStatusSelect', filteredStatuses);
  }

  setActiveOldVettingRequestStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.NonprofitVettingRequest
    );
    const statusExclusionList = this.enumToNumberArray(
      OldActiveVettingStatusSelectExclusionList
    );
    const filteredStatuses = statuses.filter((status) => {
      return !(statusExclusionList.includes(status.id));
    });
    this.set('activeOldVettingRequestStatusSelect', filteredStatuses);
  }

  setInactiveOldVettingRequestStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.NonprofitVettingRequest
    );
    const statusExclusionList = this.enumToNumberArray(
      OldInactiveVettingRequestStatusSelectExclusionList
    );
    const filteredStatuses = statuses.filter((status) => {
      return !(statusExclusionList.includes(status.id));
    });
    this.set('inactiveOldVettingRequestStatusSelect', filteredStatuses);
  }

  setActiveVettingApplicationStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.NonprofitVettingApplication
    );
    const statusExclusionList = this.enumToNumberArray(
      ActiveApplicationStatusSelectExclusionList
    );
    const filteredStatuses = statuses.filter((status) => {
      return !(statusExclusionList.includes(status.id));
    });
    this.set('activeVettingApplicationStatusSelect', filteredStatuses);
  }

  setCompletedVettingApplicationStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.NonprofitVettingApplication
    );
    const statusExclusionList = this.enumToNumberArray(
      CompletedApplicationStatusSelectExclusionList
    );
    const filteredStatuses = statuses.filter((status) => {
      return !(statusExclusionList.includes(status.id));
    });
    this.set('completedVettingApplicationStatusSelect', filteredStatuses);
  }

  setPartnerVettingStatusFilterSelect () {
    const statusInclusionList = this.enumToNumberArray(
      PartnerVettingStatuses
    );
    const filteredStatuses = this.allStatusesForDisplayMap.filter((status) => {
      return statusInclusionList.includes(status.id);
    });
    this.set('partnerVettingStatusFilterSelect', filteredStatuses);
  }

  setProspectiveAdminOutreachStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.ProspectiveAdminOutreach
    );
    this.set('prospectiveAdminOutreachStatusSelect', statuses);
  }

  setProgramStatusSelect () {
    const statuses = this.setStatusesForSelectMap(
      StatusType.NonprofitProgram
    );
    this.set('programStatusSelect', statuses);
  }

  // create number array from enum values
  enumToNumberArray (anyEnum: any): number[] {
    const numberArray: number[] = [];
    for (const n in anyEnum) {
      if (typeof anyEnum[n] === 'number') {
        numberArray.push(<any>anyEnum[n]);
      }
    }

    return numberArray;
  }

  // get statuses by type, isActive, NOT systemStatus and map for select
  setStatusesForSelectMap (
    typeId: number
  ): StatusObjectUI[] {
    const statusMapById = this.allStatusesLookup.filter((status) => {
      return status.statusTypeId === typeId &&
        status.isActive &&
        !status.systemStatus;
    })
    .map((status) => {
      return {
        id: status.id,
        typeId: status.statusTypeId,
        value: status.status,
        i18nKey: status.i18nKey
      };
    });

    return statusMapById;
  }

  setStatusesForDisplayMap () {
    const statuses = this.allStatusesLookup.map((status) => {
      return {
        id: status.id,
        typeId: status.statusTypeId,
        value: status.status,
        i18nKey: status.i18nKey
      };
    });
    this.set('allStatusesForDisplayMap', statuses);
  }

  setActiveOnlyStatusesForDisplayMap () {
    const statuses = this.allStatusesLookup.filter((item) => {
      return item.isActive;
    }).map((status) => {
      return {
        id: status.id,
        typeId: status.statusTypeId,
        value: status.status,
        i18nKey: status.i18nKey
      };
    });
    this.set('activeOnlyStatusesForDisplayMap', statuses);
  }

  setAllStatuses (statuses: StatusModel[]) {
    return Promise.all([
      this.set('allStatusesLookup', statuses),
      this.setStatusesForDisplayMap(),
      this.setActiveOnlyStatusesForDisplayMap(),
      this.setBankInfoChangeRequestStatusSelect(),
      this.setEligibleForGivingStatusSelect(),
      this.setEligibleForGivingStatusDisplay(),
      this.setSanctionsScreeningFlagStatusSelect(),
      this.setChapterRequestStatusSelect(),
      this.setAdminRequestStatusSelect(),
      this.setCheckReissueStatusSelect(),
      this.setRemittanceAddressRequestStatusSelect(),
      this.setNonprofitStoryStatusSelect(),
      this.setActiveVettingApplicationStatusSelect(),
      this.setCompletedVettingApplicationStatusSelect(),
      this.setInactiveVettingRequestStatusSelect(),
      this.setInactiveOldVettingRequestStatusSelect(),
      this.setActiveOldVettingRequestStatusSelect(),
      this.setPartnerVettingStatusFilterSelect(),
      this.setProspectiveAdminOutreachStatusSelect(),
      this.setProgramStatusSelect()
    ]);
  }

  async fetchAllStatusesLookup () {
    if (!this.allStatusesLookup) {
      const allStatuses = await this.statusesResources.getAllStatuses();
      this.setAllStatuses(allStatuses.data);
    }
  }
}
