  <div class="d-flex flex-column align-items-start justify-content-start">
    <div>
      <yc-nonprofit-block-table
        [id]="id"
        [name]="name"
        [address]="address"
        [addressString]="addressString"
        [registrationId]="registrationId"
        [icon]="icon"
        [skipTitleize]="skipTitleize"
        [registrationId]="registrationId"
        [ycCharityId]="ycCharityId"
        [eligibleForGivingStatusId]="eligibleForGivingStatusId"
        [chapterRegistrationId]="chapterRegistrationId"
        [npoSelect]="npoSelect"
        [npoParentSelect]="npoParentSelect"
        [parentName]="parentName"
        [parentId]="parentId"
        (onNpoSelect)="selectNonprofitAndNavigate(id)"
        (onNpoParentSelect)="selectNonprofitAndNavigate(parentId)">
      </yc-nonprofit-block-table>
    </div>
    <div *ngIf="showAdditionalInfo"
      [class.info-link-with-icon]="!!icon"
      [class.info-link-no-icon]="!icon">
      <yc-button
        [link]="true"
        [fontSize]="'12px'"
        [value]="'common:hdrAdditionalInformation' | ycI18n : 'Additional Information'"
        [langKey]="'common:hdrAdditionalInformation'"
        [defaultText]="'Additional Information'"
        (onClick)="openAdditionalInfoModal()">
      </yc-button>
    </div>
  </div>