import { Injectable } from '@angular/core';
import { TermsOfServiceModelResponse } from '@core/models/auth.model';
import { DetermineSignInFlow, LoginScenario } from '@core/typings/bbid.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
@Injectable({ providedIn: 'root' })
export class AuthState extends BaseYcState {
  readonly email: string;
  readonly password: string;
  readonly termsOfService: TermsOfServiceModelResponse;
  readonly loginScenario: LoginScenario;
  readonly signInFlow: DetermineSignInFlow;
}
