import { Pipe, PipeTransform } from '@angular/core';
import { UserRolePaginationModel, YOURCAUSE_USER_ROLE_MAP_BY_ID } from '@core/models/yourcause-user.model';
@Pipe({
  name: 'nppUserRolesList'
})
export class UserRolesPipe implements PipeTransform {

  constructor () {}

  transform (user: UserRolePaginationModel): string {
    const roleArray: string[] = [];
    let html = '';
    if (user) {
      const roleIds = user.roles.map((role) => {
        return role.roleId;
      });
      roleIds.forEach((role) => {
        const roleMapId = role as keyof typeof YOURCAUSE_USER_ROLE_MAP_BY_ID;
        const roleString = YOURCAUSE_USER_ROLE_MAP_BY_ID[roleMapId];
        roleArray.push(roleString);
      });
      const sortedRoles = roleArray.sort();
      sortedRoles.forEach((role) => {
        html += '' + role + '<br>';
      });
    }

    return html;
  }
}
