import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'nppAlternateNaText'
})
export class AlternateNaText implements PipeTransform {
  notApplicableText = this.i18n.translate(
    'nonprofits:textNA',
  {},
  'N/A'
);

constructor (
  private i18n: I18nService
) { }

  transform (value: any): any {
    return !!value ? value : this.notApplicableText;
  }
}

