import { Injectable } from '@angular/core';
import { AdaptedPaginationOptions } from '@core/models/extract-filter-columns.model';
import { PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class ExtractFilterColumnsService {

  paramsMap: {
    [x: string]: any;
  } = {};

  // We pass in original PaginationOptions and
  // an array of columnNames that we want to
  // extract from the PaginationOptions.
  // Return modified PaginationOptions and the
  // specified columnNames/values (to be used as
  // endpoint body or url parameters).

  extractFilterColumnsFromPaginationOptions (
    paginationOptions: PaginationOptions<any>,
    columnNames: string[],
    buildArrayOfValues = false
  ): AdaptedPaginationOptions {
    this.paramsMap = {};
    if (columnNames.length) {
      columnNames.forEach((name) => {
        paginationOptions.filterColumns = paginationOptions.filterColumns
          .filter((column) => {
            if (column.columnName === name) {
              if (column.filters.length === 1) {
                if (buildArrayOfValues) {
                  this.paramsMap[name] = [column.filters[0].filterValue] as any;
                } else {
                  this.paramsMap[name] = column.filters[0].filterValue as any;
                }
              } else if (column.filters.length > 1 &&
                buildArrayOfValues
              ) {
                const array = column.filters.map((filter) => filter.filterValue);
                this.paramsMap[name] = array;
              }

              return false;
            } else {
              return true;
            }
        });
      });
    }

    return {
      paginationOptions,
      params: this.paramsMap
    };
  }

  // Pass in original PaginationOptions and
  // additional columnName and values to add
  // a new filter
  adaptPaginationOptions (
    paginationOptions: PaginationOptions<any>,
    columnName: string,
    filterType: string,
    filterValue: string|number
  ) {
    return {
      ...paginationOptions,
      filterColumns: [
        ...paginationOptions.filterColumns,
        {
          columnName,
          filters: [
            {
              filterType,
              filterValue
            }
          ]
        }
      ]
    };
  }

  adaptPaginationOptionsWithYcCharityId (
    paginationOptions: PaginationOptions<any>,
    charityIdColumnName: string,
    searchTypeColumnName: string
  ) {
    let charityId: number;
    let filteredPaginationOptions: PaginationOptions<any>;

    paginationOptions.filterColumns = paginationOptions.filterColumns
    .filter((column) => {
      // remove ycCharityId and convert to number
      if (column.columnName === charityIdColumnName) {
        charityId = +(column.filters[0].filterValue);

        return false;
      } else {
        return true;
      }
    });
    const adaptedParams = this.extractFilterColumnsFromPaginationOptions(
        paginationOptions,
        [searchTypeColumnName]
      );
    // re-add ycCharityId to filterColumns
    if (!!charityId) {
      filteredPaginationOptions = this.adaptPaginationOptions(
          adaptedParams.paginationOptions,
          charityIdColumnName,
          'eq',
          charityId
        );

      return filteredPaginationOptions;
    } else {
      return adaptedParams.paginationOptions;
    }
  }
}
