import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse, RegistrationAuthority } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class RegistrationAuthoritiesResources {
  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getRegistrationAuthorities () {
    const response = await this.httpRestService.get<APIResponse<RegistrationAuthority[]>>(
      'api/RegistrationAuthority/GetAll'
    );

    return response.data;
  }
}
