<!-- Emails -->
<div *ngFor="let email of group.group;
  let index = index;
  let last = last;"
  [formGroup]="formArray.at(index) | ycAs: formGroupTypeToken">
  <!-- Subject / Description -->
  <div class="row">
    <div class="col-md-10">
      <div class="comm-header">
        {{ email.name }}
      </div>
      <div class="comm-desc">
        {{ email.commsPrefsDescription }}
      </div>
    </div>
    <div class="col-md-2">
      <yc-toggle
        [name]="email.name"
        (onChange)="onToggleChange($event, email.id)">
      </yc-toggle>
    </div>
  </div>
  <div *ngIf="!last || !isYcAdminModal"
    class="separator">
  </div>
</div>