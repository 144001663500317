import { Pipe, PipeTransform } from '@angular/core';
import { BannerInformationAPI } from '@core/typings/api/custom-alerts.typing';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'nppAlertPageIdMap'
})
export class AlertPageIdMapPipe implements PipeTransform {
  constructor (
    private readonly i18n: I18nService
  ) {}

  transform (displayPageId: number): string {
    switch (displayPageId) {
      case BannerInformationAPI.BannerDisplayPages.HELP_SUPPORT:
        return this.i18n.translate('admin:textHelpAndSupport', {}, 'Help/Support');
      case BannerInformationAPI.BannerDisplayPages.MY_WORKSPACE:
        return this.i18n.translate('admin:textMyWorkspace', {}, 'My Workspace');
      case BannerInformationAPI.BannerDisplayPages.NPO_ADMINISTRATION:
        return this.i18n.translate('admin:textAdminHomePage', {}, 'Administration Home Page');
      case BannerInformationAPI.BannerDisplayPages.DISBURSEMENT_INFO:
        return this.i18n.translate('nonprofits:textDisbursementInformation', {}, 'Disbursement Information');
      case BannerInformationAPI.BannerDisplayPages.PAYMENTS_PAGE:
        return this.i18n.translate('common:textPayments', {}, 'Payments');
      default:
        return this.i18n.translate('common:textNotApplicableNA', {}, 'N/A');
    }
  }
}
