import { Pipe, PipeTransform } from '@angular/core';
import { NotesAPI } from '@core/typings/api/notes.typing';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'nppNoteType'
})
export class NoteType implements PipeTransform {
  constructor (
    private readonly i18n: I18nService
  ) {}

  transform (value: number): any {
    let type;
    switch (value) {
      case NotesAPI.NoteType.GENERAL:
        type = this.i18n.translate(
          'admin:textGeneral',
          {},
          'General'
        );
        break;
      case NotesAPI.NoteType.OUTREACH_EMAIL:
          type = this.i18n.translate(
            'admin:textOutreachEmail',
            {},
            'Outreach email'
          );
          break;
      case NotesAPI.NoteType.VETTING:
          type = this.i18n.translate(
            'common:textVetting',
            {},
            'Vetting'
          );
          break;
      default:
      type = this.i18n.translate(
        'admin:textGeneral',
        {},
        'General'
      );
    }

    return type;
  }
}
