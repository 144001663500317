import { Injectable } from '@angular/core';
import { BlackbaudSsoRouteResponse, TermsOfServiceModelResponse } from '@core/models/auth.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { DetermineSignInFlow, LinkToBbidPayload } from '@core/typings/bbid.typing';
import { APIResponse } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class AuthResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  acknowledgeTermsOfService (termsOfServiceId: number) {
    const endpoint = `/api/TermsOfService/AcknowledgeTermsOfService?termsOfServiceId=${termsOfServiceId}`;

    return this.httpRestService.post(endpoint, {});
  }

  getBlackbaudLoginRoute (): Promise<APIResponse<BlackbaudSsoRouteResponse>> {
    const endpoint = 'api/admin/Sso/SignInUrl';

    return this.httpRestService.get(endpoint);
  }

  getLatestEffectiveTermsOfService (): Promise<APIResponse<TermsOfServiceModelResponse>> {
    const endpoint = 'api/TermsOfService/TermsOfService';

    return this.httpRestService.get(endpoint);
  }

  getUserTermsOfService (): Promise<APIResponse<TermsOfServiceModelResponse>> {
    const endpoint = 'api/TermsOfService/UserTermsOfService';

    return this.httpRestService.get(endpoint);
  }

  getDetermineSignInFlow (email: string): Promise<DetermineSignInFlow> {
    const encodedEmail = encodeURIComponent(email);
    const endpoint = `api/token/DetermineUserSigninFlow?email=${encodedEmail}`;

    return this.httpRestService.get(endpoint);
  }

  verifyBbidLink () {
    const endpoint = 'api/user/VerifyBlackbaudIdLink';

    return this.httpRestService.get(endpoint);
  }

  trackBbidLogin () {
    const endpoint = 'api/user/BlackbaudIdLogin';

    return this.httpRestService.post(endpoint, {});
  }

  linkToBbidAccount (payload: LinkToBbidPayload) {
    const endpoint = 'api/token/LinkToBlackbaudIdAccount';

    return this.httpRestService.post(endpoint, payload);
  }

  async createNPOAccountForBbid (
    firstName: string,
    lastName: string,
    email: string,
    languageCulture: string
  ) {
    const endpoint = 'api/user/CreateUserForBlackbaudId';

    return this.httpRestService.post(endpoint, {
      email,
      firstName,
      lastName,
      languageCulture
    });
  }
}
