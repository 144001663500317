import { Injectable } from '@angular/core';
import { CommunicationPreferences, CommunicationPreferencesPayload } from '@core/models/communication.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse } from '@yourcause/common';

@Injectable({
  providedIn: 'root'
})
export class CommunicationResources {
  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getCommunicationPreferences (userEmailAddress?: string) {
    const response = await this.httpRestService.post<APIResponse<CommunicationPreferences>>(
      'api/CommunicationPreference/GetUserCommunicationPreferences',
      {
        userEmailAddress
      }
    )
    ;

    return response.data;
  }

  async updateCommunicationPreferences (
    payload: CommunicationPreferencesPayload
  ) {
    const endpoint = 'api/CommunicationPreference/UpdateUserEmailPreference';

    return this.httpRestService.post<APIResponse>(endpoint, payload)
    ;
  }
}
