import { Injectable } from '@angular/core';

import { ModalFactory } from '@yourcause/common/modals';
import { of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ReleaseNotesService } from '../../platform-admin/services/release-notes.service';
import { ReleaseNotesComponent } from '../components/release-notes/release-notes.component';
import { ReleaseNoteAcknowledgement } from '../models/release-notes.model';
import { TokenService } from '@core/services/token/token.service';

/**
 * This guard is used to display the release notes modal to the user when they first log in.
 */
@Injectable({ providedIn: 'root' })
export class ReleaseNotesGuard  {
  releaseNoteData: ReleaseNoteAcknowledgement;
  skipEndpointCall = false;
  releaseNotesDeps: Partial<ReleaseNotesComponent>;

  constructor (
    private modalFactory: ModalFactory,
    private releaseNotesService: ReleaseNotesService,
    private tokenService: TokenService
  ) { }

  canActivateChild () {
    if (this.skipEndpointCall) {
      return of(true);
    }

    return this.releaseNotesService.getCurrentReleaseAcknowledgement()
    .pipe(
      take(1),
      map((response) => {
        this.releaseNotesDeps = {
          releaseNotes: response ?? null
        };

        return this.releaseNotesDeps.releaseNotes;
      }),
      mergeMap((response) => {
        if (!response?.releaseRequiresAcknowledgement) {
          this.skipEndpointCall = true;

          return of(true);
        } else {
          const releaseIdNumber = parseInt(response?.releaseId, 10);
          this.releaseNotesService.setReleaseAcknowledged(releaseIdNumber).subscribe();
          const modal = this.modalFactory.createModal(
            ReleaseNotesComponent,
            this.releaseNotesDeps,
            {
              class: 'modal-lg'
            }
          );

          return modal.content.completed.pipe(
            map(() => {
              modal.hide();
              this.skipEndpointCall = true;

              return true;
            }));
          }
        }),
      catchError((error) => {
        console.log(error);
        // if the token has expired, log the user out
        if (error?.status === 401) {
          this.tokenService.logout();
        }
        this.skipEndpointCall = true;

        return of(true);
      })
    );
  }
}
