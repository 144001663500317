import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';
import { StatusService } from './status.service';



@Pipe({
  name: 'nppStatusPipe'
})
export class StatusPipe implements PipeTransform {
  statusesForDisplay = this.statusService.allStatusesForDisplayMap;
  otherText = this.i18n.translate(
    'common:textOther',
    {},
    'Other'
  );
  noApplicationText= 'No application';
  noApplicationTranslation = this.i18n.translate(
    'vetting:textNoApplication',
    {},
    'No application'
  );

  constructor (
    private i18n: I18nService,
    private statusService: StatusService
  ) { }

  transform (
    inputId: string|number,
    returnTranslation = true,
    specialCase?: string
  ): string {
    const id = +inputId;
    const statusForDisplay = this.statusesForDisplay.find((status) => status.id === id);
    if (!!statusForDisplay) {
      if (returnTranslation) {
        return this.i18n.translate(
          statusForDisplay.i18nKey,
          {},
          statusForDisplay.value
        );
      } else {
        return statusForDisplay.value;
      }
    } else {
      if (specialCase === 'noVettingApplication') {
        if (returnTranslation) {
          return this.noApplicationTranslation;
        } else {
          return this.noApplicationText;
        }
      } else {
        return this.otherText;
      }
    }
  }
}
