import { Injectable } from '@angular/core';
import { Notification } from '@core/models/notifications.model';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { NotificationsResources } from './notifications.resources';
import { NotificationsState } from './notifications.state';


@AttachYCState(NotificationsState)
@Injectable({ providedIn: 'root' })
export class NotificationsService extends BaseYCService<NotificationsState> {
  notificationMap: {
    [nonprofitId: number]: Notification[];
  };

  constructor (
    private notificationsResources: NotificationsResources
  ) {
    super();
  }

  get allNotifications () {
    return this.get('allNotifications');
  }

  get keyedNotifications () {
    return this.get('keyedNotifications');
  }

  get modalNotification () {
    return this.get('modalNotification');
  }

  get modalNotificationClosed (): boolean {
    return this.get('modalNotificationClosed');
  }

  get activeNonprofitId () {
    return this.get('activeNonprofitId');
  }

  async setActiveNonprofitId (id: number) {
    this.set('activeNonprofitId', id);
  }

  async setKeyedNotifications () {
    this.notificationMap = {};

    const sideBarNotifications = this.allNotifications
      .filter((notification) => {
        return notification.isSideBar;
    });

    if (sideBarNotifications.length) {
      sideBarNotifications.forEach((notification) => {
        const nonprofitId = notification.nonprofit ?
          notification.nonprofit.nonprofitId :
          1;
        (this.notificationMap[nonprofitId] = this.notificationMap[nonprofitId] || [])
          .push(notification);
      });

      const selectedNpo = this.activeNonprofitId;
      const notifications: {
        instance: Notification[];
        key: string | number;
      }[] = [];

      if (this.notificationMap[+selectedNpo]) {
        notifications.push({
          instance: this.notificationMap[+selectedNpo],
          key: selectedNpo
        });
      }
      Object.keys(this.notificationMap).forEach((key) => {
        if (+key !== +selectedNpo) {
          notifications.push({
            instance: this.notificationMap[+key],
            key
          });
        }
      });
      this.set('keyedNotifications', notifications);
    }
  }

  async setModalNotification () {
    const modalTypeNotifications = this.allNotifications.filter((notification) => {
      return notification.isModal;
    });
    this.set('modalNotification', modalTypeNotifications[0]);
  }

  setModalNotificationClosed (val: boolean) {
    this.set('modalNotificationClosed', val);
  }

  async dismissNotification (notificationId: number) {
    await this.notificationsResources.dismissNotification(
      notificationId
    );

    return this.removeNotification(notificationId);
  }

  removeNotification (id: number) {
    const allNotifications = this.allNotifications;
    const index = allNotifications.findIndex((n) => {
      return +n.notificationId === id;
    });
    if (index !== -1) {
      this.setAllNotifications(
        [
          ...this.allNotifications.slice(0, index),
          ...this.allNotifications.slice(index + 1)
        ]
      );
    }
  }

  async setAllNotifications (notifications: Notification[]) {
    return Promise.all([
      this.set('allNotifications', notifications),
      this.setModalNotification(),
      this.setKeyedNotifications()
    ]);
  }

  async getNotifications () {
    const response = await this.notificationsResources.getNotifications();
    await this.setAllNotifications(response);
  }
}
