
import { Injectable } from '@angular/core';
import { ReasonModel } from '@core/models/reason.model';
import { APIResult } from '@yourcause/common';
import { HttpRestService } from '@core/services/http-rest.service';

@Injectable({ providedIn: 'root' })
export class ReasonsResources {
  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getAllReasons () {
    const endpoint = 'api/Lookups/Reasons/GetReasons';
    const payload = {
      paginationOptions: {
        returnAll: true,
        sortColumns: [{
          columnName: 'id',
          sortAscending: true
        }]}
    };

    const response = await this.httpRestService.post<APIResult<ReasonModel>>(
      endpoint,
      payload
    );

    return response.data.records;
  }
}
