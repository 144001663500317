import { EventCategorySkillType, EventSkillType, Participant, VolunteerCommunityPartner, VolunteerEvent, VolunteerEventDocument, VolunteerEventPhoto, VolunteerShift, VolunteerTimeZone } from '@core/models/volunteer-event.model';
import { SelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class VolunteerEventState extends BaseYcState {
  readonly activeEventId: number;
  readonly activeVolunteerEvent: VolunteerEvent;
  readonly activeEventParticipants: Participant[];
  readonly activeEventPhotos: VolunteerEventPhoto[];
  readonly activeEventShifts: VolunteerShift[];
  readonly activeEventDocuments: VolunteerEventDocument[];
  readonly volunteerTimeZones: VolunteerTimeZone[];
  readonly eventForCopy: VolunteerEvent;
  readonly communityPartners: VolunteerCommunityPartner[];
  readonly eventAffiliateId: number;
  readonly eventTimeLineColumnValue: number;
  readonly eventSkills: SelectOption[];
  readonly eventAffiliateSkills: SelectOption[];
  readonly allSkillsMetaData: EventSkillType[];
  readonly skillsCategoriesMetaData: EventCategorySkillType[];
}
