import { PaginationOptions } from '@yourcause/common';
import { DonationModel, Transaction } from './donation.model';
import { TableDataDownloadFormat } from '@yourcause/common/files';

export interface PendingDisbursementSummary {
  amountPending: number;
  transactionsPending: number;
}

export interface MatchRequestSummary {
  records: DonationModel[];
  recordCount: number;
}

export interface MultiplePaymentsPayload {
  paymentIds: number[];
  exportReportType?: TableDataDownloadFormat;
}

export interface AllTransactionsPayload {
  paginationOptions: PaginationOptions<Transaction>;
}

export const PAYMENT_TYPES_MAP = {
  Check: 1,
  Wire: 2,
  ACH: 3,
  Other: 4
};

export enum PaymentTypes {
  CHECK = 1,
  WIRE = 2,
  ACH = 3,
  OTHER = 4
}

export enum DonationType {
  Individual = 'Individual',
  EmployerMatch = 'Employer Match'
}
