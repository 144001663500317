<div [formGroup]="formGroup">
  <yc-typeahead
    name="term"
    [label]="'common:lblOrganization' | ycI18n : 'Organization'"
    [srOnlyLabel]="true"
    [getDisplay]="getDisplay"
    [fetchData]="debounce.exec"
    [placeholder]="placeholder"
    [showNoResults]="true"
    [maxResults]="maxResults"
    (onChange)="onOrgSelect.emit($event)">
    <ng-container *ycTypeaheadTemplate="let item = item;">
      <div class="d-flex flex-row" #row>
        <div class="p-2">
          <img [src]="getIcon(item)"
            [alt]="'common:textNonprofitIcon' | ycI18n">
        </div>
        <div class="mr-auto ps-2 pt-2">
          <ng-container>
            <div class="name-wrap">
              {{ item.document.displayName || item.document.name }}
            </div>
          </ng-container>
          <small class="d-block">
            {{ item.document.registrationId | ein }}
          </small>
          <small *ngIf="item.addressObj"
            class="d-block"
            [innerHtml]="item.addressObj | address : false : true">
          </small>
        </div>
      </div>
    </ng-container>
  </yc-typeahead>
</div>

