import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StandardAddressNppModel } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';

@Component({
  selector: 'yc-chapter-address-match-parent-modal',
  templateUrl: './chapter-address-match-parent-modal.component.html',
  styleUrls: ['./chapter-address-match-parent-modal.component.scss']
})

export class ChapterAddressMatchParentModalComponent extends YCModalComponent<string> implements OnInit {
  @Input() parentNonprofitName: string;
  @Input() chapterAddress: StandardAddressNppModel;
  @Input() reasonForMatch: string;
  @Input() parentRegisteredAddress: StandardAddressNppModel;
  @Input() parentDisplayAddress: StandardAddressNppModel;
  addressReasonForm: UntypedFormGroup;
  modalBody: string;
  primaryButtonText: string;
  cancelButtonText: string;

  modalHeader = this.i18n.translate(
    'common:hdrChapterAddressMatchesParent',
    {},
    'Chapter Address Matches Parent'
  );
  reasonLabel = this.i18n.translate(
    'common:lblReasonForMatchingAddresses',
    {},
    'Reason for matching addresses'
  );

  constructor (
    private i18n: I18nService,
    public formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit () {
    this.primaryButtonText = !this.reasonForMatch ?
      this.i18n.translate(
        'common:btnContinue',
        {},
        'Continue'
      ) : '';
    this.cancelButtonText = this.i18n.translate(
        'common:btnClose',
        {},
        'Close'
      );
    this.modalBody = this.i18n.translate(
      'common:textDisplayAddressMatchesParentAddressDesc',
      {
        parentName: this.parentNonprofitName
      },
      'The display address provided matches one or more addresses we have on record for the parent organization __parentName__. If you intend to have the chapter display address match the parent please provide a reason for the match.'
    );
    this.addressReasonForm = this.formBuilder.group({
      reasonForAddressMatch: [this.reasonForMatch || '', Validators.required]
    });
  }

  _onCancel () {
    this.closeModal.emit();
  }

  _onSubmit () {
    this.closeModal.emit(this.addressReasonForm.value.reasonForAddressMatch);
  }
}
