import { APIResponse } from '@yourcause/common';

export interface LoginPayload {
  username: string;
  password: string;
  rememberMe?: boolean;
  multifactorAuthenticationToken?: string;
  mfaLogin?: boolean;
}

export interface LoginAction {
  type: string;
  payload: LoginPayload;
}

export interface ForgotPasswordPayload {
  username: string;
}

export interface ResetPasswordPayload {
  userName: string;
  token: string;
  password: string;
  fromInvite?: boolean;
}

export interface LoginResponse {
  access_token: string;
  RefreshToken: string;
  RefreshTokenExpiration: string;
  ParsedRefreshTokenExpiration: Date;
  expires_in: number;
}

export interface PasswordStrengthDataModel {
  strengthIsValid: boolean;
  passwordStrength: number;
  passwordStrengthRequired: number;
}

export interface CreateAccountApiPayload {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  nonprofitId?: number;
  acknowledgedTermsOfService: boolean;
  adminInvite?: boolean;
}

export interface ConfirmChangeEmailPayload {
  email: string;
  token: string;
  requestGuid: string;
}

export interface MFAVerificationGroup {
  securityCode: string;
  rememberMe: boolean;
}

export enum LoginError {
  Email_Not_Confirmed = 'Email_Not_Confirmed',
  Token_Is_Not_Valid = 'Token_Is_Not_Valid',
  Incorrect_Password = 'Incorrect_Password',
  User_Does_Not_Exist = 'User_Does_Not_Exist',
  Password_Expired = 'Password_Expired',
  Account_Locked = 'Account_Locked',
  Account_Not_Valid = 'Account_Not_Valid',
  Security_Code_Required = 'Security_Code_Required',
  Security_Code_Invalid = 'Security_Code_Invalid',
  Security_Code_Expired = 'Security_Code_Expired',
  Account_Requires_SSO = 'Account_Requires_SSO',
  Unknown = 'Unknown'
}

export enum ResetPasswordCase {
  EXPIRED = 'expired',
  REQUEST_RESET = 'requestReset'
}

export type PasswordStrengthModel = APIResponse<PasswordStrengthDataModel>;

export interface BlackbaudSsoRouteResponse {
  signInUrl: string;
  signOutUrl: string;
}

export interface TermsOfServiceModelResponse {
  id?: number;
  privacyPolicyUrl?: string;
  url?: string;
}

