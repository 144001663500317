import { ClaimStateDataWithAssets, ContactForm, NonprofitAdminRequestDetailModel } from '@core/models/claim.model';
import { AssetType, SearchResult, TableAsset } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { CachedAttr, CACHE_TYPES } from '../../../lib/Cache';

@RegisterYCState()
export class ClaimState extends BaseYcState {

  @CachedAttr<ClaimStateDataWithAssets>(
    CACHE_TYPES.SESSION,
    ClaimStateDataWithAssets.default,
    val => {
      val.myOrg = val.myOrg ?
        SearchResult.construct(val.myOrg) :
        val.myOrg;
      val.parentOrg = val.parentOrg ?
        SearchResult.construct(val.parentOrg) :
        val.parentOrg;

      return val;
    }
  )
  // New claim currently in progress
  readonly storedClaimData: ClaimStateDataWithAssets;

  // Claims set on state that can include claims user is currently working on or claims that are saved but in draft
  readonly existingDraftClaims: ClaimStateDataWithAssets[] = [];

  // Completed claims that are already saved to DB - Pending, Approved, Declined
  readonly existingCompletedClaims: ClaimStateDataWithAssets[] = [];
  readonly isNpoUser: boolean;
  readonly activeExistingClaimId: number;
  readonly claimIdForReview: number;
  readonly claimDetailsForReview: NonprofitAdminRequestDetailModel;
  readonly activeExistingStatusId: number;
  readonly currentStep = 0 as number;
  readonly path: string;
  readonly storedClaimFiles: TableAsset[];
  readonly filesToRemove: TableAsset[];
  readonly claimStateDataWithAssets: ClaimStateDataWithAssets;
  readonly claimData: ClaimStateDataWithAssets;
  readonly claimFiles: TableAsset[];
  readonly existingFilesForCurrentClaim: TableAsset[];
  readonly nonprofitIdForChapters: number;
  readonly chapterRegionId: number;
  readonly formsDisabled: boolean;
  readonly assetsDisabled: boolean;
  readonly defaultRegAuthForAssets = 1 as number; // US Nonprofit
  readonly existingContactInfo: ContactForm;
  readonly claimsAssetTypes: AssetType[];
}
