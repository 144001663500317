import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'nppLocalizeBankField'
})
export class LocalizeBankField implements PipeTransform {
  constructor (
    private readonly i18n: I18nService
  ) {}

  transform (bankField: string, country: string): string {
    let localizedBankField;
    const fieldName = bankField ? bankField.toLowerCase() : '';
    const countryCode = country ? country.toLowerCase() : '';
    switch (fieldName) {
      case 'benefitaxpayerid':
        switch (countryCode) {
          case 'ar':
            localizedBankField = this.i18n.translate('bankInfo:CUILArgentina', {}, 'CUIL (Workers Identification Number)');

            return localizedBankField;
          case 'br':
            localizedBankField = this.i18n.translate('bankInfo:TaxPayerIdBrazil', {}, 'CNPJ (taxpayer ID)');

            return localizedBankField;
            default:
              localizedBankField = this.i18n.translate('bankInfo:lblBenefiTaxPayerId', {}, 'Beneficiary\'s tax payer id');

              return localizedBankField;
        }
      case 'otherroutingcode':
        switch (countryCode) {
          case 'cn':
            localizedBankField = this.i18n.translate('bankInfo:otherRoutingCodeChina', {}, 'CNAPS');

            return localizedBankField;
            default:
              localizedBankField = this.i18n.translate('bankInfo:lblOtherRoutingCode', {}, 'Other routing code');

              return localizedBankField;
        }
      case 'bankaccountnumber':
        switch (countryCode) {
          case 'cr':
            localizedBankField = this.i18n.translate('bankInfo:cuentaClienteCostaRica', {}, 'Cuenta Cliente');

            return localizedBankField;
          default:
          localizedBankField = this.i18n.translate('bankInfo:lblAccountNumber', {}, 'Account number');

          return localizedBankField;
        }
      case 'bankbranch':
        switch (countryCode) {
          case 'br':
            localizedBankField = this.i18n.translate('bankInfo:BankBranchBrazil', {}, 'Agencia number (bank branch number)');

            return localizedBankField;
          default:
            localizedBankField = this.i18n.translate('bankInfo:BankBranch', {}, 'Bank branch');

            return localizedBankField;
        }
      case 'cedulajuridica':
        localizedBankField = 'Cedula Juridica';

        return localizedBankField;
      case 'bsb':
        localizedBankField = 'BSB';

        return localizedBankField;
      case 'clabe':
        localizedBankField = 'Clabe';

        return localizedBankField;
      case 'nit':
        localizedBankField = 'NIT';

        return localizedBankField;
      case 'rut':
        localizedBankField = 'RUT';

        return localizedBankField;
      case 'ifsc code':
        localizedBankField = 'IFSC Code';

        return localizedBankField;
      case 'iban':
        localizedBankField = 'IBAN';

        return localizedBankField;
      default:
        return '';
      }
    }
  }
