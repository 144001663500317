import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EmailCommGroup } from '@core/models/communication.model';
import { TypeToken } from '@yourcause/common';
import { uniq } from 'lodash';

@Component({
  selector: 'npp-email-communication-group',
  templateUrl: './email-communication-group.component.html',
  styleUrls: ['./email-communication-group.component.scss']
})

export class EmailCommunicationGroupComponent implements OnInit {
  @Input() group: EmailCommGroup;
  @Input() currentUnsubscribedEmailClassIds: number[];
  @Input() isYcAdminModal = false;
  @Output() onCommPreferenceChange = new EventEmitter<number[]>();
  newUnsubscribedEmailClassIds: number[] = [];
  newUnsubscribed: number[] = [];
  formArray: UntypedFormArray;
  formGroupTypeToken = new TypeToken<UntypedFormGroup>();

  constructor (
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit () {
    this.newUnsubscribed = [
      ...this.currentUnsubscribedEmailClassIds
    ];

    this.formArray = this.formBuilder.array(
      this.group.group.map((email) => {
        return this.formBuilder.group({
          [email.name]: this.currentUnsubscribedEmailClassIds?.includes(email.id) ? false : true
        });
      })
    );
  }

  onToggleChange (
    response: boolean,
    emailId: number
  ) {
    if (response === false && !!emailId) {
      this.newUnsubscribed = [
        ...this.newUnsubscribed,
        emailId
      ];
    } else {
      if (this.newUnsubscribed.includes(emailId)) {
        const index = this.newUnsubscribed.indexOf(emailId);
        if (index > -1) {
          this.newUnsubscribed.splice(index, 1);
        }
      }
    }
    this.newUnsubscribedEmailClassIds = uniq(this.newUnsubscribed);
    this.onCommPreferenceChange.emit(this.newUnsubscribedEmailClassIds);
  }
}
