import { Injectable } from '@angular/core';
import { FooterStates } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
@Injectable({ providedIn: 'root' })
export class FooterState extends BaseYcState {
  readonly FOOTER_STATES = FooterStates;
  readonly footerState: FooterStates = FooterStates.STANDARD;

  readonly footerActionDisabled: boolean;
  readonly footerAction: () => any;
  readonly footerActionLabel: string;

  readonly footerCancelLabel: string;
  readonly footerCancelAction: () => any;

  readonly footerSecondaryLabel: string;
  readonly footerSecondaryDisabled: boolean;
  readonly footerSecondaryAction: () => any;

  readonly footerTertiaryLabel: string;
  readonly footerTertiaryAction: () => any;

  readonly footerInfoText: string;
  readonly footerInfoIcon: string;
  readonly footerInfoTooltip: string;

  setActionLabel (label: string) {
    this.set('footerActionLabel', label);
  }

  setCancelLabel (label: string) {
    this.set('footerCancelLabel', label);
  }

  setSecondaryLabel (label: string) {
    this.set('footerSecondaryLabel', label);
  }

  setTertiaryLabel (label: string) {
    this.set('footerTertiaryLabel', label);
  }

  setFooterInfoText (text: string) {
    this.set('footerInfoText', text);
  }

  setFooterInfoIcon (icon: string) {
    this.set('footerInfoIcon', icon);
  }

  setFooterInfoTooltip (tooltip: string) {
    this.set('footerInfoTooltip', tooltip);
  }

  setPrimaryAction (action: () => any) {
    this.set('footerAction', action);
  }

  setCancelAction (action: () => any) {
    this.set('footerCancelAction', action);
  }

  setSecondaryAction (action: () => any) {
    this.set('footerSecondaryAction', action);
  }

  setTertiaryAction (action: () => any) {
    this.set('footerTertiaryAction', action);
  }

  setPrimaryDisabled (disabled: boolean) {
    this.set('footerActionDisabled', disabled);
  }

  setSecondaryDisabled (disabled: boolean) {
    this.set('footerSecondaryDisabled', disabled);
  }

  setFooterInfo (
    infoText: string,
    infoIcon?: string,
    infoTooltip?: string
  ) {
    this.setFooterInfoText(infoText);
    this.setFooterInfoIcon(infoIcon);
    this.setFooterInfoTooltip(infoTooltip);
  }

  clearAll () {
    this.setActionLabel(null);
    this.setCancelLabel(null);
    this.setSecondaryLabel(null);
    this.setTertiaryLabel(null);
    this.setFooterInfo(null, null, null);
    this.setPrimaryAction(null);
    this.setCancelAction(null);
    this.setSecondaryAction(null);
    this.setTertiaryAction(null);
    this.setPrimaryDisabled(false);
    this.setSecondaryDisabled(false);
    this.set('footerState', this.FOOTER_STATES.STANDARD);
  }
}
