import { Pipe, PipeTransform } from '@angular/core';
import { VolunteerHours } from '@core/models/volunteer-hours.model';

@Pipe({
  name: 'nppParticipantCount'
})
export class VolunteerParticipantCountPipe implements PipeTransform {

  transform (obj: VolunteerHours): number {
    let totalHours = 0;
    if (!!obj) {
      totalHours = (obj.events || []).reduce((acc, value) => {
        return acc + +value.totalParticipants;
      }, 0);
    }

    return totalHours;
  }
}
