import { Injectable } from '@angular/core';
import { NonprofitAsset } from '@core/models/nonprofit-asset.model';
import { NotesAPI } from '@core/typings/api/notes.typing';
import { APIResponse, PaginationOptions } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { AssetManagementService } from '../asset-management/services/asset-management.service';
import { NotesResources } from './notes.resources';
import { NotesState } from './notes.state';

@AttachYCState(NotesState)
@Injectable({ providedIn: 'root' })
export class NotesService extends BaseYCService<NotesState> {

  constructor (
    private notesResources: NotesResources,
    private i18n: I18nService,
    private notifier: NotifierService,
    private assetManagementService: AssetManagementService
  ) {
    super();
  }

  get noteCount () {
    return this.get('noteCount');
  }

  get noteAssetMap () {
    return this.get('noteAssetMap');
  }

  setNoteCount (noteCount: number) {
    this.set('noteCount', noteCount);
  }

  setNoteAssetMap (map: NotesAPI.NoteAssetMap) {
    this.set('noteAssetMap', map);
  }

  async fetchNotesForTable (
    paginationOptions: PaginationOptions<NotesAPI.NoteDisplay>
  ) {
    try {
      const response = this.notesResources.fetchNotesForTable(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'admin:notificationErrorRetreivingNotes',
        {},
        'There was an error retrieving notes'
      ));

      return null;
    }
  }

  async editNote (payload: NotesAPI.NoteModalPayload) {
    try {
      const adaptedPayload: NotesAPI.NoteDisplay = {
        id: payload.id,
        noteType: payload.noteType,
        note: payload.note,
        assetId: payload.assetId,
        emailAddresses: payload.emailAddresses
      };
      await this.notesResources.editNote(adaptedPayload);
      this.notifier.success(
        this.i18n.translate(
          'admin:textSuccessUpdatingNote',
          {},
          'Successfully updated note'
        ));
    } catch (e) {
      console.error(e);
      this.notifier.error(
        this.i18n.translate(
          'admin:textErrorUpdatingNote',
          {},
          'There was an error updating the note'
        ));
    }
  }

  async addNote (
    payload: NotesAPI.NoteModalPayload,
    skipToastr?: boolean
  ) {
    try {
      const adaptedPayload: NotesAPI.NoteBase = {
        noteType: payload.noteType,
        note: payload.note,
        assetId: payload.assetId ? payload.assetId : null,
        emailAddresses: payload.emailAddresses
      };
      await this.notesResources.addNoteByNonprofitId(adaptedPayload, payload.nonprofitId);
      if (!skipToastr) {
        this.notifier.success(
          this.i18n.translate(
            'admin:textSuccessAddingNote',
            {},
            'Successfully added note'
        ));
      }
      this.setNoteCount(this.noteCount + 1);
    } catch (e) {
      console.error(e);
      if (!skipToastr) {
        this.notifier.error(
          this.i18n.translate(
            'admin:textErrorAddingNote',
            {},
            'There was an error adding the note'
        ));
      }
    }
  }

  async deleteNote (id: number) {
    try {
      await this.notesResources.deleteNote(id);
      this.notifier.success(
        this.i18n.translate(
          'admin:textSuccessfullyDeletedNote',
          {},
          'Successfully deleted note'
        ));
        this.setNoteCount(this.noteCount - 1);
    } catch (e) {
      console.error(e);
      this.notifier.error(
        this.i18n.translate(
          'admin:textErrorDeletingNote',
          {},
          'There was an error deleting the note'
        ));
    }
  }

  /**
   * Get note asset metadata.
   * @param assetId asset identity.
   * @returns
   */
  async fetchNoteAsset (assetId: string) {
    try {
      const response = await this.assetManagementService.getAssetById(assetId) as APIResponse<NonprofitAsset>;

      return response.data;
    } catch (e) {
      console.error(e);

      return this.notifier.error(
        this.i18n.translate(
          'admin:textErrorFetchingAssetForNote',
          {},
          'There was an error fetching the asset for this note'
        ));
    }
  }

  async getAssetFromMap (assetId: string) {
    const map = this.noteAssetMap;
    if (!map[assetId]) {
      const response = await this.assetManagementService.getAssetById(assetId) as APIResponse;
      this.setNoteAssetMap({
        ...map,
        [assetId]: response.data
      });

      return response.data;
    } else {
      return map[assetId];
    }
  }
}
