import { inject } from '@angular/core';
import { NonprofitService } from '../../nonprofit/nonprofit.service';
import { MyWorkspaceFinanceService } from '../services/my-workspace-finance.service';
import { ResolveFn } from '@angular/router';

export const myWorkspaceFinanceResolver: ResolveFn<void> = async () => {
  const activeNpo = inject(NonprofitService).activeNpo;
  if (!!activeNpo) {
    await inject(MyWorkspaceFinanceService).setMyWorkspaceStats();
  }
}

