<div class="btn-toolbar">
  <div *ngIf="visible"
    class="btn-group yc-button"
    dropdown>
    <button class="btn btn-link dropdown-toggle"
      type="button"
      dropdownToggle>
      {{ activeNpo?.displayName || activeNpo?.name }}
      <span *ngIf="activeNpo?.registrationId">
        &nbsp;({{activeNpo?.registrationId}})
      </span>
    </button>
    <ul *dropdownMenu
      class="dropdown-menu"
      role="menu">
      <li *ngFor="let link of links" role="menuitem">
        <a
          [routerLinkActive]="'active'"
          [routerLink]="[link.href]"
          class="dropdown-item">
          <yc-i18n
            [key]="link.i18nKey"
            [defaultValue]="link.label"
            [context]="link.context | ycEnsureObservable:{} | async">
          </yc-i18n>
        </a>
      </li>
    </ul>
    <button class="btn btn-link copy-reg"
      [tooltip]="tooltipText"
      (click)="copyRegistrationId(activeNpo?.registrationId)">
      <yc-fa class="text-info"
        icon="copy"
        [light]="true">
      </yc-fa>
    </button>
  </div>
</div>